import { palette } from "../palette";

export const MuiButton = {
    styleOverrides: {
        // Name of the slot
        contained: {
            padding: "18px 35px ",
            width: "100% ",
            height: 49,
            boxShadow: "0px 10px 14px rgba(64, 66, 215, 0.22)",
            borderRadius: "10px",
            color: palette.white,
            backgroundColor: palette.primary.main,
            fontFamily: "InterRegular",
            fontSize: 18,
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "Bold",
            letterSpacing: 0.36,
            textTransform: "none",
            outline: "none",
            "&:hover": {
                backgroundColor: palette.primary.main,
            },
            "&.Mui-disabled": {
                backgroundColor: " rgba(36, 34, 32, 0.05) !important",
                color: "#000",
                opacity: 1,
            },
            "@media (max-width: 500px)": {
                fontSize: 12,
                height: 45,
            },
        },
        outlined: {
            padding: "18px 35px ",
            width: "100% ",
            height: 49,
            // boxShadow: "0px 10px 14px rgba(64, 66, 215, 0.22)",
            borderRadius: "10px",
            color: palette.black,
            // backgroundColor: palette.primary.main,
            fontFamily: "InterRegular",
            fontSize: 18,
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "Bold",
            letterSpacing: 0.36,
            textTransform: "none",
            outline: "none",

            "&:hover": {
                backgroundColor: palette.primary.light,
                border: "2px solid palette.primary.main",
            },
            "&.Mui-disabled": {
                backgroundColor: "#D5D4DC !important",
                color: "#8B849B",
                opacity: 1,
            },
            "@media (max-width: 500px)": {
                fontSize: 12,
                height: 45,
            },
        },

        text: {
            textDecoration: "none",
            fontFamily: "InterRegular",
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "17px",
            letterSpacing: "normal",
            textAlign: "left",
            color: palette.primary.dark,
            textTransform: "capitalize",
            "&:hover": {
                backgroundColor: "rgba(36, 34, 32, 0.05)",
            },
            "@media (max-width: 500px)": {
                fontSize: 12,
            },
        },
        white: {
            padding: "18px 35px ",
            width: "100% ",
            height: 49,
            boxShadow: "0px 10px 14px rgba(64, 66, 215, 0.22)",
            borderRadius: "10px",
            color: palette.white,
            backgroundColor: "#fff",
            fontFamily: "InterRegular",
            fontSize: 18,
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "Bold",
            letterSpacing: 0.36,
            textTransform: "capitalize",
            "&:hover": {
                backgroundColor: "#fff",
            },
            "&.Mui-disabled": {
                backgroundColor: "#D5D4DC !important",
                color: "#8B849B",
                opacity: 1,
            },
            "@media (max-width: 500px)": {
                fontSize: 12,
            },
        },
    },
};
