import { Box, Button, Chip, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
const bip39 = require("bip39");

const topCover = {};

const titleWrapper = {};
const banner = {};

const boxContainer = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#EAF0F7",
    width: "113px",
    height: "37px",
    marginBottom: "12px",
    borderRadius: "4px",
    cursor: "pointer",
};
const phaseBoxContent = {
    textAlign: "left",
    marginRight: "6px",
    color: "#000000",
};
const wordsText = {
    color: "#000000",
};
const phaseBox = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: { xs: "0 6px", sm: "0 6px", md: "0 6px", lg: "0 16px" },
    minWidth: "108px",
};
const widthContainer = {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: "0 auto",
    width: "100%",
    maxWidth: "100%",
    background: "#fff",
    minHeight: 160,
    padding: "16px 0px 4px",
};
const chipSet = {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "100%",
    justifyContent: "center",
    marginTop: "20px !important",
    margin: "0 auto",
};
const chip = {
    color: "#000000",
    fontFamily: "InterRegular",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#EAF0F7",
    width: "150px",
    height: "37px",
    margin: "0px 14px 14px 0px",
    borderRadius: "4px",
    zIndex: 2,
};
const BtnBoxStyle = {
    display: "flex",
    justifyContent: "flex-end",
    mt: "1.5rem",
};

const SelectPharse = ({ setScreen }) => {
    const mnemonic = useSelector((state) => state.node.getNewMnemonics);

    const words = mnemonic.split(" ");

    const [chipData, setChipData] = React.useState([]);
    const [mnemonicError, setMnemonicError] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectedPhrase, setSelectedPhrase] = React.useState([]);
    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip !== chipToDelete));
        setSelectedPhrase((prevState) => [...prevState, chipToDelete]);
        setMnemonicError(false);
    };
    const handlePhraseRevert = (chipToDelete) => () => {
        setSelectedPhrase((chips) => chips.filter((chip) => chip !== chipToDelete));
        setChipData((prevState) => [...prevState, chipToDelete]);
        setMnemonicError(false);
    };

    const handleSubmit = (mnemonicData) => {
        const mnemonicString = mnemonicData.join(" ");
        const validate = bip39?.validateMnemonic(mnemonicString);
        if (validate) {
            setMnemonicError(false);
            setScreen(4);
        } else {
            setMnemonicError(true);
        }
    };

    React.useEffect(() => {
        if (mnemonic) {
            words &&
                words.sort(function () {
                    return 0.5 - Math.random();
                });
        }
        setChipData(words);
    }, []);

    const handleBackScreen = () => {
        setScreen(2);
    };

    return (
        <Box sx={topCover}>
            <Box sx={titleWrapper}>
                <Typography
                    variant="h1"
                    sx={{
                        mb: "1.3rem",
                        mt: { xs: "1rem", md: "0" },
                        lineHeight: { xs: "30px", md: "38px" },
                    }}>
                    Confirm your Backup Phrase
                </Typography>
                <Typography variant="subtitle2" mb={3}>
                    Select the words below in an appropriate order to proceed further.
                </Typography>
            </Box>

            <Box sx={banner}>
                <Box>
                    <Box sx={widthContainer}>
                        {selectedPhrase &&
                            selectedPhrase.map((item, index) => (
                                <Box sx={phaseBox} onClick={handlePhraseRevert(item)} key={index}>
                                    <Box sx={boxContainer}>
                                        <Typography sx={phaseBoxContent} variant="subtitle2">
                                            {index + 1}.
                                        </Typography>
                                        <Typography variant="subtitle2" sx={wordsText}>
                                            {item}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}
                    </Box>
                </Box>
                {mnemonicError && (
                    <Typography sx={{ color: "red" }}>
                        Phrase are not matched. Please try again.
                    </Typography>
                )}
                {/* <Chip chipData={chipData} handleDelete={handleDelete} /> */}
                <Box sx={chipSet}>
                    {chipData.map((item, index) => (
                        <Chip label={item} onClick={handleDelete(item)} sx={chip} key={index} />
                    ))}
                </Box>
            </Box>
            <Box sx={BtnBoxStyle}>
                <Button
                    // onClick={handleBack}
                    onClick={handleBackScreen}
                    data-cy="Back4"
                    sx={{
                        borderRadius: "5px",
                        maxWidth: "7.2rem",
                        width: "100%",
                    }}
                    variant="text">
                    Back
                </Button>
                <Button
                    onClick={() => handleSubmit(selectedPhrase)}
                    data-cy="Finish"
                    disabled={mnemonicError}
                    sx={{
                        borderRadius: "5px",
                        maxWidth: "7.2rem",
                        width: "100%",
                        ml: ".5rem",
                    }}
                    variant="contained">
                    Next
                </Button>
            </Box>
        </Box>
    );
};

export default SelectPharse;
