import { SET_ACTIVE_STEP,SET_BUTTON_ACTIVE } from "./actionTypes";


export const getActiveStep = (item) => ({
    type: SET_ACTIVE_STEP,
    payload: item,
});
export const getButtonActive = (item) => ({
    type: SET_BUTTON_ACTIVE,
    payload: item,
});
