import { Box } from "@mui/material";
import React from "react";
import HeaderName from "../headerName/HeaderName";
// import Scrollbars from "react-custom-scrollbars-2";

const Card = ({ children }) => {
  return (
    <React.Fragment>
      {/* Card code starts, */}
      <HeaderName />
      <Box
        width="100%"
        sx={{
          background: "rgba(255, 255, 255, 0.56)",
          border: "0.356px solid rgba(245, 239, 235, 0.4)",
          // height: { xs: 550, sm: 626 },
          p: "16px 0px",
        }}
      >
        {/* <Scrollbars hideTracksWhenNotNeeded> */}
        <Box
          sx={{
            padding: { xs: ".5rem .5rem", sm: "1.5rem 1rem", md: "1.5rem" },
          }}
        >
          {children}
        </Box>
        {/* </Scrollbars> */}
      </Box>
    </React.Fragment>
  );
};

export default Card;
