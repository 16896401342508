import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React, { useState } from "react";
import {
    CheckIcon,
    PlusICon,
    UnCheckIcon,
    addAccount,
    genrateAcc,
} from "../../../../assets/images";
import { getActiveStep, getAllMnemonics } from "../../../../redux/actions";
import { useDispatch } from "react-redux";
const bip39 = require("bip39");

const AddBtnStyle = {
    display: "flex",
    m: "1.7rem 0 .2rem 0",
    maxWidth: "10rem",
};
const TypoStyle = {
    color: "#013EB7",
    ml: ".5rem",
    cursor: "pointer",
};
const BtnBoxStyle = {
    display: "flex",
    justifyContent: "flex-end",
    mt: "1.5rem",
};

const PreFunf = (props) => {
    const {
        formik,
        handleRemoveField,
        handleInputChange,
        // handleAddField,
        inputValue,
        // handleGenerateField,
        hasError,
        hasAddError,
        envValue,
        setScreen,
        selectedValue,
        setSelectedValue,
        SetGetMnemonic,
        getMnemonic,
        handleChange,
    } = props;
    const dispatch = useDispatch();


    const handleBackScreen = () => {
        setScreen(0);
        dispatch(getActiveStep(2));
    };
    const handleNextScreen = () => {
        setScreen(2);
    };
    return (
        <Box>
            <Box mb={5}>
                <Typography variant="h1" sx={{ m: { xs: "1rem 0", sm: " .3rem 0rem 1.8rem" } }}>
                    Manage Account
                </Typography>
                <Typography variant="subtitle2">
                    CTA in your chain will be private, and cannot be exchanged with CTA in the
                    Zenith Mainnet.
                </Typography>
            </Box>

            <RadioGroup
                sx={{
                    width: "100%",
                    //   background:"red",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "space-between",
                    "& .MuiFormControlLabel-root": {
                        m: 0,
                        mb: { xs: 1.5, sm: 0, md: 0 }
                    },
                }}
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={selectedValue}
                onChange={(event) => handleChange(event)}>
                <FormControlLabel
                    sx={{
                        width: { xs: "100%", sm: "48%", md: "48%" },

                        minHeight: "70px",
                        background: selectedValue === "addAccount" ? "#fff" : "#EAF0F7",
                        borderRadius: "6px",
                        display: "flex",
                        justifyContent: "space-between",
                        border: selectedValue === "addAccount" ? "1px solid #013EB7" : "",
                        p: "0px 10px  ",
                    }}
                    value="addAccount"
                    control={
                        <Radio
                            icon={<img src={UnCheckIcon} alt="unchecked" />}
                            checkedIcon={<img src={CheckIcon} alt="checked" />}
                        />
                    }
                    label={
                        <Box>
                            <img src={addAccount} alt="add Account" width={14} /> &nbsp; Add Account
                        </Box>
                    }
                    labelPlacement="start"
                />
                <FormControlLabel
                    sx={{
                        width: { xs: "100%", sm: "48%", md: "48%" },
                        minHeight: "70px",
                        background: selectedValue === "generateAccount" ? "#fff" : "#EAF0F7",
                        borderRadius: "6px",
                        display: "flex",
                        justifyContent: "space-between",
                        border: selectedValue === "generateAccount" ? "1px solid #013EB7" : "",
                        p: "0px 10px ",
                    }}
                    value="generateAccount"
                    control={
                        <Radio
                            icon={<img src={UnCheckIcon} alt="unchecked" />}
                            checkedIcon={<img src={CheckIcon} alt="checked" />}
                        />
                    }
                    label={
                        <Box>
                            <img src={genrateAcc} alt="generate Account" width={14} /> &nbsp;
                            Generate Account
                        </Box>
                    }
                    labelPlacement="start"
                />
            </RadioGroup>
            <Box sx={BtnBoxStyle}>
                <Button
                    // onClick={handleBack}
                    onClick={handleBackScreen}
                    data-cy="Back4"
                    sx={{
                        borderRadius: "5px",
                        maxWidth: "7.2rem",
                        width: "100%",
                    }}
                    variant="text">
                    Back
                </Button>
                <Button
                    onClick={handleNextScreen}
                    data-cy="Finish"
                    disabled={
                        !selectedValue ? true : false
                        // formik?.values?.inputFields[0]?.mnemonics &&
                        // formik?.values?.inputFields[0]?.mnemonics
                        //   ? !hasError
                        //   : null || loading
                        //   ? true
                        //   : false

                        // !hasError ? true : loading ? true : false
                        // false
                    }
                    sx={{
                        borderRadius: "5px",
                        maxWidth: "7.2rem",
                        width: "100%",
                        ml: ".5rem",
                    }}
                    variant="contained">
                    Next
                </Button>
            </Box>
        </Box>
    );
};

export default PreFunf;
