export const SET_LOGIN = "SET_LOGIN";
export const SET_LOGIN_LOADING = "SET_LOGIN_LOADING";
export const SET_SIGNUP = "SET_SIGNUP";
export const SET_SIGNUP_LOADING = "SET_SIGNUP_LOADING";
export const SET_ACTIVATION_ACCOUNT_LOADING = "SET_ACTIVATION_ACCOUNT_LOADING";
export const SET_ACTIVATION_ACCOUNT = "SET_ACTIVATION_ACCOUNT";
export const SET_RESET = "SET_RESET";
export const SET_RESET_LOADING = "SET_RESET_LOADING";
export const SET_FORGET = "SET_FORGET";
export const SET_FORGET_LOADING = "SET_FORGET_LOADING";
export const SET_DETAIL = "SET_DETAIL";
export const SET_DETAIL_LOADING = "SET_DETAIL_LOADING";

