import axios from "axios";
import {
  SET_LOGIN,
  SET_LOGIN_LOADING,
  SET_SIGNUP,
  SET_SIGNUP_LOADING,
  SET_ACTIVATION_ACCOUNT,
  SET_ACTIVATION_ACCOUNT_LOADING,
  SET_FORGET_LOADING,
  SET_FORGET,
  SET_RESET,
  SET_RESET_LOADING,
  SET_DETAIL,
  SET_DETAIL_LOADING,
  SET_ADD_NETWORK_LOADING,
  SET_ADD_NETWORK,
} from "./actionTypes";
import { toast } from "react-hot-toast";

export const getLoginLoading = (payload) => ({
  type: SET_LOGIN_LOADING,
  payload: payload,
});
export const getLogin = (data, Navigation) => (dispatch) => {
  dispatch(getLoginLoading(true));
  axios
    .post(`${process.env.REACT_APP_CHAIN_LAUNCH_REST_URL}/api/v1/user`, data)
    .then((res) => {
      toast.success(res?.data?.message);
      localStorage.setItem("token", res.data.result?.token);
      dispatch(getLoginLoading(false));
      dispatch({
        type: SET_LOGIN,
        payload: res.data.result,
      });
      if (res.data.result.token) {
        Navigation("/");
      }
    })
    .catch((error) => {
      dispatch(getLoginLoading(false));
      toast.error(error?.response?.data?.message);
      dispatch({
        type: SET_LOGIN,
        payload: null,
      });
    });
};
export const getSignUpLoading = (payload) => ({
  type: SET_SIGNUP_LOADING,
  payload: payload,
});
export const getSignUp = (data, reset) => (dispatch) => {
  dispatch(getSignUpLoading(true));
  axios
    .post(
      `${process.env.REACT_APP_CHAIN_LAUNCH_REST_URL}/api/v1/user/signup`,
      data
    )
    .then((res) => {
      toast.success(res?.data?.message, {
        duration: 6000,
      });
      reset();
      dispatch(getSignUpLoading(false));
      dispatch({
        type: SET_SIGNUP,
        payload: res.data.result,
      });
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message, {
        duration: 6000,
      });
      dispatch(getSignUpLoading(false));
      dispatch({
        type: SET_SIGNUP,
        payload: null,
      });
    });
};

export const getActivationAccountLoading = (payload) => ({
  type: SET_ACTIVATION_ACCOUNT_LOADING,
  payload: payload,
});
export const getActivationAccount = (token) => (dispatch) => {
  dispatch(getActivationAccountLoading(true));
  axios
    .get(
      `${process.env.REACT_APP_CHAIN_LAUNCH_REST_URL}/api/v1/user/activateAccount/${token}`
    )
    .then((res) => {
      dispatch(getActivationAccountLoading(false));
      toast.success(res?.data?.message);
      dispatch({
        type: SET_ACTIVATION_ACCOUNT,
        payload: res?.data,
      });
    })
    .catch((error) => {
      // dispatch({
      //   type: SET_ACTIVATION_ACCOUNT,
      //   payload: error?.res?.data,
      // });
      dispatch(getActivationAccountLoading(false));
      if (error?.response?.data?.status === "fail") {
        toast.error(error?.response?.data?.message);
      }
    });
};

// export const getActivationAccount = (token) => (dispatch) => {
//   dispatch(getActivationAccountLoading(true));
//   axios
//     .get(
//       `${process.env.REACT_APP_CHAIN_LAUNCH_REST_URL }/api/v1/user/activateAccount/${token}`
//     )
//     .then((res) => {
//       dispatch(getActivationAccountLoading(false));
//       toast.success(res?.data?.message);
//       dispatch({
//         type: SET_ACTIVATION_ACCOUNT,
//         payload: res.data,
//       });
//     })
//     .catch((error) => {
//       dispatch(getActivationAccountLoading(false));
//       if (error?.response?.data?.status === "fail") {
//         toast.error(error?.response?.data?.message);
//       }
//     });
// };

export const getResendEmail = (data) => (dispatch) => {
  dispatch(getActivationAccountLoading(true));
  axios
    .post(
      `${process.env.REACT_APP_CHAIN_LAUNCH_REST_URL}/api/v1/user/resendEmail`,
      data
    )
    .then((res) => {
      dispatch(getActivationAccountLoading(false));
      toast.success(res?.data?.message);
      // dispatch({
      //   type: SET_ACTIVATION_ACCOUNT,
      //   payload: res.data.result,
      // });
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      dispatch(getActivationAccountLoading(false));
    });
};

//------------------------------

export const getResetLoading = (payload) => ({
  type: SET_RESET_LOADING,
  payload: payload,
});
export const getReset = (data, token, Navigation) => (dispatch) => {
  dispatch(getResetLoading(true));
  axios
    .post(
      `${process.env.REACT_APP_CHAIN_LAUNCH_REST_URL}/api/v1/user/resetpassword/${token}`,
      data
    )
    .then((res) => {
      toast.success(res?.data?.message);
      dispatch(getResetLoading(false));
      dispatch({
        type: SET_RESET,
        payload: res.data.result,
      });
      Navigation("/sign-in");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      dispatch(getResetLoading(false));
      dispatch({
        type: SET_RESET,
        payload: null,
      });
    });
};

//-----------------

export const getForgetLoading = (payload) => ({
  type: SET_FORGET_LOADING,
  payload: payload,
});
export const getForget = (data) => (dispatch) => {
  dispatch(getForgetLoading(true));
  axios
    .post(
      `${process.env.REACT_APP_CHAIN_LAUNCH_REST_URL}/api/v1/user/forgetpassword`,
      data
    )
    .then((res) => {
      toast.success(res?.data?.message);
      dispatch(getForgetLoading(false));
      dispatch({
        type: SET_FORGET,
        payload: res.data.result,
      });
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      dispatch(getForgetLoading(false));
      dispatch({
        type: SET_FORGET,
        payload: null,
      });
    });
};
export const getDetailLoading = (payload) => ({
  type: SET_DETAIL_LOADING,
  payload: payload,
});
export const getDetail = (data, navigate) => (dispatch, getState) => {
  // const token = getState().auth.login?.token;
  dispatch(getDetailLoading(true));
  axios
    .get(
      `${process.env.REACT_APP_CHAIN_LAUNCH_REST_URL}/api/v1/user/myprofile`,
      {
        headers: {
          Authorization: `Bearer ${data}`,
        },
      }
    )
    .then((res) => {
      dispatch(getDetailLoading(false));
      dispatch({
        type: SET_DETAIL,
        payload: res.data.result,
      });
    })
    .catch((error) => {
      dispatch(getDetailLoading(false));
      dispatch({
        type: SET_DETAIL,
        payload: null,
      });
      if (
        error?.response?.status === 401 ||
        error.response?.data?.message === "Invalid Token Please Login Again" ||
        error.response?.data?.message ===
          "Your are not Logged In Please logged in First"
      ) {
        localStorage.removeItem("token");
        navigate("/sign-in");
      }
    });
};

// change password

export const changPassword = (data, Navigation) => (dispatch) => {
  const token = localStorage.getItem("token");

  dispatch(getLoginLoading(true));
  axios
    .patch(
      `${process.env.REACT_APP_CHAIN_LAUNCH_REST_URL}/api/v1/user/updatemyPassword`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      dispatch(getLoginLoading(false));
      toast.success(res?.data?.message);
      localStorage.removeItem("token");
      Navigation("/sign-in");
      // dispatch({
      //   type: SET_DETAIL,
      //   payload: res.data.result,
      // });
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      dispatch(getLoginLoading(false));

      // dispatch({
      //   type: SET_DETAIL,
      //   payload: null,
      // });
    });
};
