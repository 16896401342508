import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { NetworkValidation } from "../../../../utils/schema";
import { useFormik } from "formik";
import SteppersUI from "../../../../components/newStepper/NewStepper";
import { useDispatch, useSelector } from "react-redux";
import { getEnvironmentConfig, getNetworkSummery } from "../../../../redux/node/actions";
import { getActiveStep } from "../../../../redux/test/actions";
import { useNavigate } from "react-router";

// Styles for the vertical line
const verticalLine = {
    background:
        "radial-gradient(50% 50% at 50% 50%, rgba(1, 62, 183, 0.31) 0%, rgba(255, 255, 255, 0) 100%)",
    height: "400px",
    width: 2,
    display: { xs: "none", sm: "none", md: "block" },
};
// Styles for the button container
const buttonBox = {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    mt: 4,
};
// Styles for the buttons
const button = {
    borderRadius: "5px",
    maxWidth: "7.2rem",
    width: "100%",
    ml: ".5rem",
};
// NetworkSummary component
const NetworkSummary = ({ setScreen }) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { networkName, networkMission } = useSelector((state) => state.node);

    const initialValues = {
        networkName: "",
        // networkStatement: "",
    };

    const onSubmit = (values) => {
        setLoading(true);
        // Simulate an asynchronous operation
        try {
            setLoading(false);
            // setScreen(1);
            dispatch(getNetworkSummery(values));
            navigate("/network-details");
            dispatch(getActiveStep(1));
        } catch (error) {
            setLoading(false);
        }
    };
    const formik = useFormik({
        onSubmit,
        initialValues,
        validationSchema: NetworkValidation,
    });

    const handleBack = () => {
        dispatch(getActiveStep(0));
        dispatch(
            getNetworkSummery({
                networkName: "",
                networkStatement: "",
            }),
        );
        let data = {
            Provider: "",
            Algorithm: "",
            value: {
                idvalue: "",
                period: "",
                inputFields: [],
            },
        };
        dispatch(getEnvironmentConfig(data));
        navigate("/");
    };
    const NameOfNetwork = formik.values.networkName.replace(/\s/g, "");
    formik.values.networkName = NameOfNetwork;

    useEffect(() => {
        formik.setFieldValue("networkName", networkName);
    }, [networkName]);

    // const StatmentofNwtwork = formik.values.networkStatement.replace(/^\s+/g, "");
    // formik.values.networkStatement = StatmentofNwtwork;
    return (
        <React.Fragment>
            <Box>
                <Box
                    sx={{
                        mt: ".5rem",
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                        alignItems: "center",
                    }}>
                    {/* Stepper component */}
                    <Box sx={{ maxWidth: "600px ", width: "100%" }}>
                        <SteppersUI />
                    </Box>
                </Box>
                {/* Network summary heading */}

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        // alignItems: "center",
                        flexDirection: { xs: "column", sm: "column", md: "row" },
                    }}>
                    <Box sx={{ width: { xs: "100%", sm: "100%", md: "48%" }, mt: "37px" }}>
                        <Typography
                            variant="h1"
                            sx={{ m: { xs: "1rem 0", sm: " 0 0rem 1rem", textTransform: "none" } }}>
                            What is meant by network?
                        </Typography>
                        <Typography variant="subtitle1" textAlign="left">
                            A blockchain network is a distributed and decentralized system that
                            utilizes blockchain technology to securely record and validate
                            transactions. It operates on a peer-to-peer network, where multiple
                            participants, known as nodes, collaborate to maintain the integrity and
                            transparency of the network. In this step, you need to provide the
                            following configuration details:
                        </Typography>
                        <ul>
                            <li>
                                <Typography variant="subtitle1" textAlign="left">
                                    <span style={{ color: "#013eb7", fontFamily: "InterSemiBold" }}>
                                        {" "}
                                        Name{" "}
                                    </span>{" "}
                                    – Identity of the network
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="subtitle1" textAlign="left">
                                    <span style={{ color: "#013eb7", fontFamily: "InterSemiBold" }}>
                                        {" "}
                                        Network{" "}
                                    </span>{" "}
                                    – A user can create a maximum of 5 networks.
                                </Typography>
                            </li>
                            {/* <li>
                                <Typography variant="subtitle1" textAlign="left">
                                    Mission – Optional prose or legalese describing business
                                    purpose, bylaws, objectives, etc
                                </Typography>
                            </li> */}
                        </ul>
                        {/* <Typography variant="subtitle1" textAlign="left">
                            All operations at this tier are off-chain, and include: cloud + region
                            whitelisting, membership creation, certificate management, governance,
                            and network auditing.
                        </Typography> */}
                    </Box>
                    <Box sx={verticalLine} />
                    <Box
                        sx={{
                            width: { xs: "100%", sm: "100%", md: "48%" },
                            mt: { xs: 3, md: "37px" },
                        }}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                mb: ".6rem",
                            }}>
                            <Typography
                                variant="h1"
                                sx={{ m: { xs: "1rem 0", sm: " 0 0rem 1rem" } }}>
                                {" "}
                                Enter Network Name
                            </Typography>
                            {/* <Typography
                variant="subtitle2"
                sx={{ fontStyle: "italic", whiteSpace: "nowrap" }}
              >
                Step 1/2
              </Typography> */}
                        </Box>
                        {/* Form */}
                        <form
                            onSubmit={formik.handleSubmit}
                            style={{
                                width: "100%",
                            }}>
                            {/* Network name input field */}
                            <Box sx={{ width: "100%", maxHeight: "80px", height: "100%", mt: 1 }}>
                                <TextField
                                    label="Name of the network"
                                    variant="outlined"
                                    value={NameOfNetwork}
                                    fullWidth
                                    type="text"
                                    name="networkName"
                                    data-cy=" networkSummary-input-networkName"
                                    {...formik.getFieldProps("networkName")}
                                    error={Boolean(
                                        formik.touched.networkName && formik.errors.networkName,
                                    )}
                                    helperText={
                                        formik.touched.networkName && formik.errors.networkName
                                    }
                                    sx={{ mt: 3 }}
                                />
                            </Box>
                            {/* Network statement input field */}
                            {/* <Box
                sx={{ width: "100%", maxHeight: "80px", height: "100%", mt: 1 }}
              >
                <TextField
                  label="Network mission statement "
                  variant="outlined"
                  fullWidth
                  id="outlined-basic"
                  name="networkStatement"
                  type="text"
                  {...formik.getFieldProps("networkStatement")}
                  error={Boolean(
                    formik.touched.networkStatement &&
                      formik.errors.networkStatement
                  )}
                  helperText={
                    formik.touched.networkStatement &&
                    formik.errors.networkStatement
                  }
                  sx={{ mt: 3 }}
                />
              </Box> */}
                            {/* Buttons */}
                            <Box sx={buttonBox}>
                                <Button
                                    variant="text"
                                    data-cy="NetworkSummary-btn"
                                    sx={button}
                                    onClick={handleBack}>
                                    cancel
                                </Button>
                                {/* Buuton to submit the formik form */}
                                <Button
                                    type="submit"
                                    data-cy="NetworkSummary-Next"
                                    disabled={
                                        (!formik.values.networkName &&
                                            !formik.values.networkStatement) ||
                                        formik.errors.networkName ||
                                        loading
                                            ? true
                                            : false
                                    }
                                    variant="contained"
                                    sx={button}>
                                    {!loading ? "Next" : <CircularProgress size="1.7rem" />}
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
};

export default NetworkSummary;
