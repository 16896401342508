import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import HorizontalLine from "../../../../components/divider/HorizontalLine";
import { useSelector } from "react-redux";

const MainBoxStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
};

const Summry = ({ setScreen }) => {
    const {
        networkName,
        networkMission,
        networkRegion,
        environmentName,
        deployOption,
        provider, //1
        algorithm,
        envValue, //2
        getNewMnemonics,
        inputFields,
    } = useSelector((state) => state.node);
    const [checkMnemonic, setCheckMnemonic] = useState(true);
    return (
        <Box
            sx={{
                width: "100%",
                bgcolor: "#EAF0F7",
                borderRadius: "5px",
                padding: { xs: "1.5rem 1rem", md: "1.5rem 1.2rem" },
                marginTop: { xs: ".8rem", md: "0" },
            }}>
            <Typography variant="h1" sx={{ m: { xs: "1rem 0", sm: " .7rem 0rem 1rem" } }}>
                Summary Details
            </Typography>

            <Box>
                <Box sx={MainBoxStyle}>
                    <Typography variant="h4">Network Name:</Typography>
                    <Typography variant="subtitle2">{networkName}</Typography>
                </Box>
                <HorizontalLine />
                <Box sx={MainBoxStyle}>
                    <Typography variant="h4">Chain ID:</Typography>
                    <Typography variant="subtitle2">{envValue?.idvalue}</Typography>
                </Box>
                <HorizontalLine />
                <Box sx={MainBoxStyle}>
                    <Typography variant="h4">Chain Supply:</Typography>
                    <Typography variant="subtitle2">
                        {envValue?.inputFields?.[0]?.supplyChain} CTA
                    </Typography>
                </Box>
                <HorizontalLine />
                {/* <Box sx={MainBoxStyle}>
          <Typography variant="h4">Creation date:</Typography>
          <Typography variant="subtitle2">cdc,ldcdc</Typography>
        </Box>
        <HorizontalLine /> */}
                {/* <Box sx={MainBoxStyle}>
          <Typography variant="h4">Account Type:</Typography>
          <Typography variant="subtitle2">cdc,ldcdc</Typography>
        </Box>
        <HorizontalLine /> */}
                <Box sx={MainBoxStyle}>
                    <Typography variant="h4">Initial Stake Amount:</Typography>
                    <Typography variant="subtitle2">
                        {envValue?.inputFields?.[0]?.amount} CTA
                    </Typography>
                </Box>
                <HorizontalLine />
                <Box sx={MainBoxStyle}>
                    <Typography variant="h4">Mnemonics:</Typography>
                    {checkMnemonic ? (
                        <Button
                            variant="outlined"
                            onClick={() => setCheckMnemonic(false)}
                            sx={{
                                cursor: "pointer",
                                color: "#013eb7",
                                fontFamily: "InterMedium",
                                border: "1px solid #013eb7",
                                width: 60,
                                height: 16,
                                p: "12px",
                                fontSize: 12,
                            }}>
                            View
                        </Button>
                    ) : (
                        <Box sx={{ width: "60%" }}>
                            <Box
                                onClick={() => setCheckMnemonic(true)}
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    mb: 1,
                                }}>
                                <Button
                                    variant="outlined"
                                    onClick={() => setCheckMnemonic(false)}
                                    sx={{
                                        cursor: "pointer",
                                        color: "#013eb7",
                                        fontFamily: "InterMedium",
                                        border: "1px solid #013eb7",
                                        width: 60,
                                        height: 16,
                                        p: "12px",
                                        fontSize: 12,
                                    }}>
                                    {" "}
                                    Hide
                                </Button>
                            </Box>
                            <Typography variant="subtitle2">
                                {envValue?.inputFields?.[0]?.mnemonics}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default Summry;
