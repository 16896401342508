import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  IconButton,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  CreataLogo,
  DropDownLine,
  DropDownLogo,
  EarthLogo,
  HomeDark,
  RightArrowLogo,
  avtEmoji,
  close,
  fullLogo,
  home,
  humburger,
  infoIcon,
  worldDark,
} from "../../../assets/images";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { getActiveStep } from "../../../redux/actions";
import useDeviceSize from "../../../hooks/windowResize";
import { HtmlTooltip } from "../../sidebar/Sidebar";

const wrapper = {};
const circleBox = {
  display: "flex",
};
const circle1 = {
  width: 9,
  height: 9,
  background: "#FF5A52",
  mr: 1,
  borderRadius: "50%",
};
const circle2 = {
  width: 9,
  height: 9,
  background: "#E0B509",
  mr: 1,
  borderRadius: "50%",
};
const circle3 = {
  width: 9,
  height: 9,
  background: "#53C22B",
  mr: 1,
  borderRadius: "50%",
};
const avtBox = {
  display: "flex",
  mt: 2,
};
const infoWrapper = {
  p: 2,
};
const horizontalLine = {
  background:
    " radial-gradient(50% 50% at 50% 50%, #432C2C 0%, rgba(80, 28, 28, 0.2) 100%)",
  opacity: 0.24,
  width: "100%",
  height: 1.5,
};
const buttonWrapper = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  mt: 2,
};
const button = {
  fontSize: "12px",
  width: "90%",
  padding: "18px 12px",
  zIndex: 1000,
  display: "flex",
  justifyContent: "flex-start",
  pl: 2.5,
};

const clipPath = {
  "& .MuiDialog-container": {
    position: "absolute",
    width: "50% ",
    left: 0,
    boxShadow: "none !important",
    "@media(max-width:500px)": {
      width: "60% ",
    },
    "@media(max-width:400px)": {
      width: "75% ",
    },
    "@media(max-width:320px)": {
      width: "85% ",
    },
  },
  "& .MuiDialog-paper": {
    boxShadow: "none",
  },
};
const logoBox = {
  p: "16px 0px",
  display: "flex",
  alignItems: "center",
  height: 80,
  position: "relative",
  justifyContent: "space-between",
  // mr: 4,
  width: "100%",
};
const logoBox1 = {
  display: "flex",
  alignItems: "center",
};

const ScreenDialog = (props) => {
  //The buttonActive is use for the stepper
  const { getNode,addNetwork } = useSelector((state) => state.node);
  const [dropDown, setDropDown] = useState(false);
  const dispatch = useDispatch();
  const width = useDeviceSize();
  const handleChange = (event) => {
    setDropDown(!dropDown);
  };
  const handleLink = () => {
    dispatch(getActiveStep(0));
  };
  const [activeRoute, setActiveRoute] = useState("");
  const Navigation = useNavigate();
  const { detail } = useSelector((state) => state.auth);
  const location = useLocation();
  const parts = location.pathname.split("/");
  const id = parts[parts.length - 1];
  const currentPathname = location.pathname;

  useEffect(() => {
    setActiveRoute(currentPathname);
  }, [currentPathname]);
  let isFirstButtonDisabled = false;
  let isSecondButtonDisabled = false;
  let isNodeOverViewButton = false;
  let isOverViewButton = false;
  if (activeRoute === "/" || activeRoute === "/dashboard") {
    isFirstButtonDisabled = activeRoute !== "/";
    isSecondButtonDisabled = activeRoute !== "/dashboard";
  } else if (
    activeRoute === `/manage-node/overview/${id}` ||
    activeRoute === "/manage-node"
  ) {
    isFirstButtonDisabled = true;
    isSecondButtonDisabled = false;
  } else {
    isSecondButtonDisabled = true;
  }

  const handleClick = () => {
    setActiveRoute("/manage-node");
    // dispatch(getNods());
    props.toggle();
  };

  const capitalizeFirstLetter = (str) => {
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleClose = () => {
    Navigation("/");
    setActiveRoute("/");
    props.toggle();
  };
  useEffect(() => {
    if (width.width > 575) {
      props.toggle();
    }
  }, [props, width]);
  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.toggle}
      sx={clipPath}
      BackdropProps={{
        style: {
          backgroundColor: "transparent",
        },
      }}
    >
      <Box sx={wrapper}>
        <Box sx={infoWrapper}>
          <Box sx={logoBox} onClick={handleLink} data-cy="ScreenDialog">
            <Box sx={logoBox1}>
              <Link to="/">
                <img
                  src={fullLogo}
                  alt="creatachain logo"
                  style={{
                    height: "2.5rem",
                  }}
                />
              </Link>
              {/* <Typography
                variant="h5"
                sx={{
                  // display: { xs: "none", sm: "block" },
                  marginLeft: ".6rem",
                }}
              >
                <Link
                  to="/"
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  CREATACHAIN
                </Link>
              </Typography> */}
            </Box>
            <Box
              onClick={props.toggle}
              data-cy="ScreenDialog-toggle"
              sx={{
                cursor: "pointer",
                // marginTop: -2,
              }}
            >
              <IconButton>
                <img src={humburger} alt="humburger" style={{ height: 12 }} />
              </IconButton>
            </Box>
          </Box>

          {/* Avatar, name and email code starts  */}
        </Box>
        <Box sx={wrapper}>
          <Box sx={infoWrapper}>
            <Box sx={circleBox}>
              <Box sx={circle1} />
              <Box sx={circle2} />
              <Box sx={circle3} />
            </Box>
            {/* Avayar,name and email portion of code starts */}
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography
                    variant="subtitle2"
                    sx={{ wordBreak: "break-all" }}
                  >
                    {detail?.name}
                  </Typography>
                  <Typography variant="subtitle5">{detail?.email}</Typography>
                </React.Fragment>
              }
            >
              <Box sx={avtBox}>
                <Avatar sx={{ width: 34, height: 34, background: "#0575E6" }}>
                  {" "}
                  {capitalizeFirstLetter(detail?.name?.charAt(0)) || "A"}
                </Avatar>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    ml: 1,
                    cursor: "pointer",
                  }}
                >
                  <Typography variant="subtitle2"> {detail?.name}</Typography>
                  <Typography variant="subtitle5"> {detail?.email}</Typography>
                </Box>
              </Box>
            </HtmlTooltip>
          </Box>
          <Box sx={horizontalLine} />
          {/* Button of create node code */}
          <Box sx={buttonWrapper}>
            <NavLink
              className={isFirstButtonDisabled ? "active" : ""}
              to="/"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={handleClose}
              data-cy="newnode"
            >
              <Button
                disabled={
                  isFirstButtonDisabled ||
                  isNodeOverViewButton ||
                  isOverViewButton
                }
                variant="contained"
                data-cy="newnode"
                sx={button}
                onClick={handleClose}
              >
                <img
                  src={!isFirstButtonDisabled ? home : HomeDark}
                  alt="home"
                  style={{ height: "20px", marginRight: 10 }}
                />
                Create new node
              </Button>
            </NavLink>
          </Box>

          {/* This button will only shown whe node is created  */}
          {getNode?.length > 0 ? (
            <Box sx={buttonWrapper}>
              <NavLink
                className={isSecondButtonDisabled ? "active" : ""}
                to="/manage-node"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={handleClick}
                data-cy="manageNode"
              >
                <Button
                  disabled={
                    isSecondButtonDisabled ||
                    isNodeOverViewButton ||
                    isOverViewButton
                  }
                  variant="contained"
                  data-cy="manageNode"
                  sx={button}
                >
                  <img
                    src={!isSecondButtonDisabled ? EarthLogo : worldDark}
                    alt="Earth Logo"
                    style={{
                      height: "20px",
                      marginRight: 10,
                    }}
                  />
                  Manage node
                </Button>
              </NavLink>
            </Box>
          ) : null}
          <Box sx={buttonWrapper}>
            <NavLink
              className={isFirstButtonDisabled && ""}
              to="/help-center"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => setActiveRoute("/help-center")}
              data-cy="help"
            >
              <Button
                disabled
                variant="contained"
                data-cy="CreateNew"
                sx={button}
                onClick={() => Navigation("/help-center")}
              >
                <img
                  src={infoIcon}
                  alt="infoIcon"
                  style={{
                    height: "20px",
                    marginRight: 10,
                  }}
                />
                Help Center
              </Button>
            </NavLink>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ScreenDialog;
