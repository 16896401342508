import React, { useEffect, useState } from "react";
import Card from "../../components/card";
import { Box, Button, Typography, CircularProgress } from "@mui/material";

import OverViewContent from "./OverViewContent";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getActiveStep } from "../../redux/test/actions";
import { getChainById, getDownload } from "../../redux/actions";
// import useDeviceSize from "../../hooks/windowResize";
import DownloadingIcon from "@mui/icons-material/Downloading";
import HorizontalLine from "../../components/divider/HorizontalLine";
import { noFound } from "../../assets/images";

const SecondFlex = {
  flex: "0 0 70%",
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
};
const button = {
  width: 160,
};
const buttonWrapper = {
  display: "flex",
  justifyContent: "flex-end",
};
const AppOverviewBoxStyle = {
  flex: { xs: "0 0 100%", md: "0 0 49%" },
  width: "100%",
  overflow: "hidden",
  backgroundColor: " #EAF0F7",
  m: "2rem 0 2.4rem 0",
  height: "100%",
  borderRadius: "0px 0px 5px 5px",
};
const TopBorderStyle = {
  width: "100%",
  background: " #013EB7",
  borderRadius: "0px",
  height: "3px",
};
const FlexBoxStyle = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
};
const FirstFlex = {
  bgcolor: "#EAF0F7",
  borderRadius: "5px",
  // minHeight: "(calc100vh - 122px)",
  // maxHeight: "405px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
  maxWidth: { xs: "100%", md: "405px" },
  marginRight: { xs: "0rem", md: "1rem" },
};
const UserTupoStyle = {
  ...SecondFlex,
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  minHeight: "18px",
};
const NodeOverview = () => {
  // const { width } = useDeviceSize();
  const { addNetwork, addNetworkLoading, isChainLoading } = useSelector(
    (state) => state.node
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleBack = () => {
    navigate("/");
    dispatch(getActiveStep(0));
  };
  const handleDownload = (data, name) => {
    dispatch(getDownload(data, name));
  };
  useEffect(() => {
    dispatch(getChainById(id));
  }, [id]);
  return (
    <React.Fragment>
      {addNetwork === null ? (
        <Card>
          {isChainLoading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "68vh",
                flexDirection: "column",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "68vh",
                flexDirection: "column",
              }}
            >
              <img src={noFound} alt="noFound" />
              <Typography>no data found</Typography>
            </Box>
          )}
        </Card>
      ) : (
        <Card>
          <Box sx={{}}>
            <Typography variant="h1" my={1.5}>
              Node overview
            </Typography>
            {/* <Box sx={{ width: { xs: "100%", md: "60%" } }}> */}
            {/* All the code show on the page is in the component  */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: { xs: "column", md: "column", lg: "row" },
              }}
            >
              {/* Node information portion */}
              <Box
                sx={{
                  width: { xs: "100%", sm: "100%", md: "100%", lg: "48%" },
                }}
              >
                <OverViewContent />
              </Box>
              <Box sx={{ flex: { xs: "0 0 0 ", md: "0 0 2%" } }} />

              <Box sx={AppOverviewBoxStyle}>
                <Box sx={TopBorderStyle} />{" "}
                <Box
                  sx={{
                    padding: {
                      xs: "2rem 1rem",
                      sm: "2rem 1rem",
                      md: "2rem 1.5rem",
                    },
                    width: "100%",
                  }}
                >
                  <Typography variant="h3">Node Configuration</Typography>
                  <Typography variant="subtitle2" mt={2.2} lineHeight="22px">
                    Please note that the node configuration file contains
                    essential settings and parameters required for the proper
                    configuration and operation of the node within the
                    blockchain network. Make sure to keep the file secure and
                    follow any additional instructions or guidelines provided by
                    the blockchain network or platform you are working with.
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    mt={1}
                    mb={1}
                    lineHeight="22px"
                  >
                    Follow these instructions to configure and run your chain
                    using a downloaded configuration file.{" "}
                    <Link
                      to={{
                        pathname: "/help-center",
                        search: "?panel4",
                      }}
                      style={{
                        color: " #013EB7",
                        fontFamily: "InterSemiBold",
                      }}
                    >
                      Help Center
                    </Link>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    mt={1}
                    mb={5}
                    lineHeight="22px"
                  >
                    To download the node configuration file, click on the
                    following link
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Box sx={FlexBoxStyle}>
                      <Typography variant="h4" sx={FirstFlex}>
                        Download File:
                      </Typography>
                      <Box
                        sx={UserTupoStyle}
                        data-cy="DownloadFile2"
                        onClick={() =>
                          handleDownload(
                            addNetwork?.dirPath,
                            addNetwork?.nodeDetails?.app_state?.genutil
                              ?.gen_txs[0]?.body?.messages[0]?.description
                              ?.moniker
                          )
                        }
                      >
                        <Typography
                          variant="subtitle2"
                          mr={1.2}
                          sx={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <DownloadingIcon /> &nbsp;{" "}
                          {!addNetworkLoading ? (
                            `${addNetwork?.nodeDetails?.app_state?.genutil?.gen_txs[0]?.body?.messages[0]?.description?.moniker} Configuration file`
                          ) : (
                            <CircularProgress size="1rem" />
                          )}
                        </Typography>
                      </Box>
                    </Box>
                    <HorizontalLine />
                    {/* <Box sx={FlexBoxStyle}>
                  <Typography variant="h4" sx={FirstFlex}>
                    JSON/RPC Websocket endpoint
                  </Typography>
                  <Box sx={MainTypoStyle}>
                    <Typography variant="subtitle2" mr={1.2} sx={TypoStyle}>
                      0xa9faa38e5be71f8a9ac165af66ae304609616094
                    </Typography>
                    <CopyToClipboard
                      text=" 0xa9faa38e5be71f8a9ac165af66ae304609616094"
                      // onCopy={handleCopy}
                    >
                      <Tooltip title={isCopied ? "Copied" : "Copy"}>
                        <span style={{ cursor: "pointer" }}>
                          <Box sx={CopyBox}>
                            <img
                              src={Copyicon}
                              alt=""
                              style={{
                                height: "auto",
                                width: "100%",
                              }}
                            />
                          </Box>
                        </span>
                      </Tooltip>
                    </CopyToClipboard>
                  </Box>
                </Box>
                <HorizontalLine />
                <Box sx={FlexBoxStyle}>
                  <Typography variant="h4" sx={FirstFlex}>
                    REST API Gateway
                  </Typography>
                  <Box sx={MainTypoStyle}>
                    <Typography variant="subtitle2" mr={1.2} sx={TypoStyle}>
                      0xa9faa38e5be71f8a9ac165af66ae304609616094
                    </Typography>
                    <CopyToClipboard
                      text=" 0xa9faa38e5be71f8a9ac165af66ae304609616094"
                      // onCopy={handleCopy}
                    >
                      <Tooltip title={isCopied ? "Copied" : "Copy"}>
                        <span style={{ cursor: "pointer" }}>
                          <Box sx={CopyBox}>
                            <img
                              src={Copyicon}
                              alt=""
                              style={{
                                height: "auto",
                                width: "100%",
                              }}
                            />
                          </Box>
                        </span>
                      </Tooltip>
                    </CopyToClipboard>
                  </Box>
                </Box> */}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={buttonWrapper}
              onClick={handleBack}
              data-cy="DownloadFile-done"
            >
              <Button
                variant="contained"
                data-cy="NodeOverview-Done"
                sx={button}
              >
                Done
              </Button>
            </Box>
            {/* </Box> */}
          </Box>
        </Card>
      )}
    </React.Fragment>
  );
};

export default NodeOverview;
