import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import {
  CreataLogo,
  EthrumLogo,
  CheckIcon,
  UnCheckIcon,
} from "../../../../assets/images";

const RadioBoxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minHeight: { xs: "100%", md: "5.18rem" },
  padding: { xs: ".5rem", sm: "1.4rem" },
  borderRadius: "5px",
  cursor: "pointer",
  "@media (max-width: 500px)": {
    width: "100%",
  },
};
const LogoBoxStyle = {
  ml: {
    xs: ".7rem",
    sm: "1.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
const RadioLabelStyle = { ml: { xs: "0rem", sm: "3.1rem" } };
const RadioButton = (props) => {
  const {
    handleBlur,
    RadioValue,
    handleChange,
    handlecatenachange,
    algoValue,
    handleAlgoValue,
  } = props;
  // This component contain the radio button

  return (
    <React.Fragment>
      {/* Radio Creatachain */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          width: "100%",
          maxWidth: "540px !important",
          "@media (max-width: 578px)": {
            maxWidth: "430px !important",
          },
        }}
      >
        <Box
          onClick={handleChange}
          data-cy="radioButton"
          data-value="zenith"
          sx={{
            ...RadioBoxStyle,
            mt: "1rem",
            bgcolor: RadioValue === "zenith" ? "#FFFFFF" : "#EAF0F7",
            border: RadioValue === "zenith" ? "1px solid #013EB7" : "none",
            ":hover": {
              bgcolor: RadioValue === "zenith" ? "#FFFFFF" : "#e1eaf5",
              ":hover .CheckBoxStyle": {
                color: "#013EB7",
              },
            },
          }}
        >
          <Box sx={{ display: "flex" }}>
            <img src={CreataLogo} alt="creata logo" />
            <Box sx={LogoBoxStyle}>
              <Typography variant="h4" m=".2rem 0 .4rem 0 ">
                Zenith Mainnet
              </Typography>
            </Box>
          </Box>

          <Radio
            onBlur={handleBlur}
            icon={<img src={UnCheckIcon} alt="unchecked" />}
            checkedIcon={<img src={CheckIcon} alt="checked" />}
            checked={RadioValue === "zenith"}
            value="Creatachain"
            className="CheckBoxStyle"
            sx={{ pl: "1rem" }}
          />
        </Box>
        <Box
          onClick={handlecatenachange}
          data-cy="radioButton"
          data-value="catena"
          sx={{
            ...RadioBoxStyle,
            mt: "1rem",
            bgcolor: RadioValue === "catena" ? "#FFFFFF" : "#EAF0F7",
            border: RadioValue === "catena" ? "1px solid #013EB7" : "none",
            ":hover": {
              bgcolor: RadioValue === "catena" ? "#FFFFFF" : "#e1eaf5",
              ":hover .CheckBoxStyle": {
                color: "#013EB7",
              },
            },
          }}
        >
          <Box sx={{ display: "flex" }}>
            <img src={CreataLogo} alt="creata logo" />
            <Box sx={LogoBoxStyle}>
              {" "}
              <Typography variant="h4" m=".2rem 0 .4rem 0 ">
                Catena Mainnet
              </Typography>
            </Box>
          </Box>

          <Radio
            icon={<img src={UnCheckIcon} alt="unchecked" />}
            checkedIcon={<img src={CheckIcon} alt="checked" />}
            checked={RadioValue === "catena"}
            value="catena"
            className="CheckBoxStyle"
            sx={{ pl: "1rem" }}
          />
        </Box>
      </Box>

      {/* Radio B */}
      {/* <Box
        onClick={handleChange}
        data-value="Ethereum"
        sx={{
          ...RadioBoxStyle,
          mt: { xs: "1rem", sm: ".5rem" },
          bgcolor: RadioValue === "Ethereum" ? "#FFFFFF" : "#EAF0F7",
          border: RadioValue === "Ethereum" ? "1px solid #013EB7" : "none",
          ":hover": {
            bgcolor: RadioValue === "Ethereum" ? "#FFFFFF" : "#e1eaf5",
            ":hover .CheckBoxStyle": {
              color: "#013EB7",
            },
          },
        }}
      >
        <Box sx={{ display: "flex", ml: ".4rem" }}>
          <img src={EthrumLogo} alt="ethrium logo" />
          <Box sx={LogoBoxStyle}>
            {" "}
            <Typography variant="h4" m=".2rem 0 .4rem 0 ">
              Ethereum
            </Typography>
            <Typography variant="subtitle3">
              It is a long established fact that a reader will be
            </Typography>
          </Box>
        </Box>

        <Radio
          icon={<img src={UnCheckIcon} alt="unchecked" />}
          checkedIcon={<img src={CheckIcon} alt="checked" />}
          checked={RadioValue === "Ethereum"}
          value="Ethereum"
          sx={{ pl: "1rem" }}
          className="CheckBoxStyle"
        />
      </Box> */}
      <Typography variant="h3" lineHeight="15px" my={3}>
        Consensus Algorithm
      </Typography>
      {/* Select the Algorithm via radio button funcationality */}
      <RadioGroup
        sx={{ flexDirection: { xs: "column", sm: "row" } }}
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={algoValue}
        onChange={handleAlgoValue}
      >
        {RadioValue === "catena" ? (
          <FormControlLabel
            value="POS"
            sx={{ ml: "0rem" }}
            control={
              <Radio
                icon={<img src={UnCheckIcon} alt="unchecked" />}
                checkedIcon={<img src={CheckIcon} alt="checked" />}
                checked={algoValue === "POS"}
              />
            }
            label="Augusteum"
          />
        ) : (
          <FormControlLabel
            value="POS"
            sx={{ ml: "0rem" }}
            control={
              <Radio
                icon={<img src={UnCheckIcon} alt="unchecked" />}
                checkedIcon={<img src={CheckIcon} alt="checked" />}
                checked={algoValue === "POS"}
              />
            }
            label="Augusteum"
          />
        )}

        {/* <FormControlLabel
          value="Raft"
          sx={RadioLabelStyle}
          control={
            <Radio
              icon={<img src={UnCheckIcon} alt="unchecked" />}
              checkedIcon={<img src={CheckIcon} alt="checked" />}
              checked={algoValue === "Raft"}
            />
          }
          label="Raft"
        />
        <FormControlLabel
          value="IBFT"
          sx={RadioLabelStyle}
          control={
            <Radio
              icon={<img src={UnCheckIcon} alt="unchecked" />}
              checkedIcon={<img src={CheckIcon} alt="checked" />}
              checked={algoValue === "IBFT"}
            />
          }
          label="IBFT"
        /> */}
      </RadioGroup>
      <Typography variant="h3" lineHeight="15px" my={3}>
        Network Type
      </Typography>
      {/* Select the Algorithm via radio button funcationality */}
      {RadioValue === "catena" ? (
        <RadioGroup
          sx={{ flexDirection: { xs: "column", sm: "row" } }}
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          // value={algoValue}
          onChange={handleAlgoValue}
        >
          {/* <FormControlLabel
                    value="CVM"
                    sx={{ ml: "0rem" }}
                    control={
                        <Radio
                            disabled
                            icon={<img src={UnCheckIcon} alt="unchecked" />}
                            checkedIcon={<img src={CheckIcon} alt="checked" />}
                            checked={algoValue === "POS"}
                        />
                    }
                    label="CVM"
                /> */}
          <FormControlLabel
            value="Catena (CIP-20)"
            sx={{ ml: "0rem" }}
            control={
              <Radio
                icon={<img src={UnCheckIcon} alt="unchecked" />}
                checkedIcon={<img src={CheckIcon} alt="checked" />}
                checked={algoValue === "POS"}
              />
            }
            label="Catena (CIP-20) Chain"
          />
        </RadioGroup>
      ) : (
        <RadioGroup
          sx={{ flexDirection: { xs: "column", sm: "row" } }}
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          // value={algoValue}
          onChange={handleAlgoValue}
        >
          {/* <FormControlLabel
                    value="CVM"
                    sx={{ ml: "0rem" }}
                    control={
                        <Radio
                            disabled
                            icon={<img src={UnCheckIcon} alt="unchecked" />}
                            checkedIcon={<img src={CheckIcon} alt="checked" />}
                            checked={algoValue === "POS"}
                        />
                    }
                    label="CVM"
                /> */}
          <FormControlLabel
            value="Zenith Chain"
            sx={{ ml: "0rem" }}
            control={
              <Radio
                icon={<img src={UnCheckIcon} alt="unchecked" />}
                checkedIcon={<img src={CheckIcon} alt="checked" />}
                checked={algoValue === "POS"}
              />
            }
            label="Zenith Chain"
          />
        </RadioGroup>
      )}
    </React.Fragment>
  );
};

export default RadioButton;
