import * as actionTypes from "./actionTypes";

const initialState = {
    stepperSteps: -1,
    buttonActive:false
};

const testReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ACTIVE_STEP: {
            return {
                ...state,
                stepperSteps: action.payload,
            };
        }
        case actionTypes.SET_BUTTON_ACTIVE: {
            return {
                ...state,
                buttonActive: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

export default testReducer;
