import React, { useState } from "react";
import { MinimumConfiguration } from "./components/minimumConfiguration";
import { EnvironmentSummary } from "./components/environmentSummary";

const ViewEnvironment = () => {
  // this state will mange the conditional move from one to other page
  const [activeStep, setActiveStep] = useState(0);
  // move to the next page
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  // move back to the page
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <React.Fragment>
      {/* Render the appropriate component based on the active step */}
      {/* {activeStep === 0 ? (
        <EnvironmentSummary handleNext={handleNext} />
      ) : activeStep === 1 ? (
        <MinimumConfiguration handleBack={handleBack} />
      ) : null} */}
      <MinimumConfiguration  />
    </React.Fragment>
  );
};

export default ViewEnvironment;
