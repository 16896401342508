import * as yup from "yup";
// import { isValidNumber } from "libphonenumber-js";
// Signin page schema to validate the input value
export const SignInValidation = yup.object({
  email: yup.string().email("Enter valid email").required("Email is required"),
  password: yup
    .string()
    .trim()
    // .min(8, "Invalid  password")
    .required("Password is required"),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&()_+\-=[\]{};':"\\|,.<>/?])(?=.{8,})/,
  //   "Invalid  password"
  // ),
});
// Signup page schema to validate the input value
export const SignUpValidation = yup.object({
  name: yup
    .string()
    .min(3, "Name contain 3 letters")
    .required("Name is required"),
  email: yup.string().email("Enter valid email").required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(/[a-z]/, "Password must contain at least one lowercase character")
    .matches(/[A-Z]/, "Password must contain at least one uppercase character")
    .matches(
      /^(?=.*[!@#$%^&()_+\-=[\]{};':"\\|,.<>/?])/,
      "Password must contain at least one special character"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Both password need to be the same")
    .required("Confirm password is required"),

  // phoneNumber: yup
  //   .string()
  //   .test("valid-phone", "Invalid phone number", (value) => {
  //     if (!value) return true; // Allow empty value
  //     return isValidNumber(`+${value}`);
  //   })
  //   .required("Phone number is required"),
  // phoneNumber: yup
  //   .string()
  //   .min(10, "Invalid phone number")
  //   .required("Phone number is required"),
});
// Password reset page schema to validate the input value
export const PasswordResetValidation = yup.object({
  email: yup.string().email("Enter valid email").required("Email is required"),
});
// Network summery page schema to validate the input value
export const NetworkValidation = yup.object({
  networkName: yup
    .string()
    .required("Enter Network Name")
    .max(20, "Network Name must be a maximum of 20 characters long"),
  // .min(3, "Network Name contain 3 letters")
  // networkStatement: yup
  //   .string()
  //   // .min(8, "Network Mission Statement contain 8 letters")
  //   .required("Enter Network Statement"),
});
// Environment summery  page schema to validate the input value
export const EnvironementValidation = yup.object({
  envName: yup
    .string()
    .min(3, "Environment name contain 3 letters")
    .required("Enter envitonment name"),
});
// Envirnment minimum configration page optional  validate schema with the input value
export const MiniConfigValidation = (format) => {
  let idValidation;
  if (format === "catena") {
    idValidation = yup
      .string()
      .matches(
        /^[a-zA-Z]+_\d+-\d+$/,
        "Chain ID for catena should be in the format 'text_number-number'"
      )
      .matches(/^[a-z0-9-_]+$/, {
        message: "Chain ID must be lowercase",
        excludeEmptyString: true, // Ensure empty string fails validation
      })
      .max(20, "Chain ID must be a maximum of 20 characters long")
      .required("Chain ID required");
  } else {
    idValidation = yup
      .string()

      .matches(
        /^[a-zA-Z0-9-_]+$/,
        "Chain ID can only contain letters, numbers, and dashes"
      )
      .max(20, "Chain ID must be a maximum of 20 characters long")
      .required("Chain ID required");
  }

  return yup.object({
    idvalue: idValidation,
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password should be of minimum 8 characters length")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(
        /[a-z]/,
        "Password must contain at least one lowercase character"
      )
      .matches(
        /[A-Z]/,
        "Password must contain at least one uppercase character"
      )
      .matches(
        /^(?=.*[!@#$%^&()_+\-=[\]{};':"\\|,.<>/?])/,
        "Password must contain at least one special character"
      ),
    inputFields: yup.array().of(
      yup.object().shape({
        accountName: yup
          .string()
          .required("Account Name required")
          .max(20, "Account Name must be at most 20 characters long"),
        mnemonics: yup.string().required("Mnemonics required"),
        amount: yup
          .string()
          .test(
            "amount-validation",
            "Stake Amount should be greater than or equal to 100000 and less than Chain Supply",
            function (value) {
              const { supplyChain } = this.parent;
              const amount = parseInt(value);
              const supplyChainValue = parseInt(supplyChain);
              return amount >= 100000 && amount < supplyChainValue;
            }
          )
          .required("Stake Amount required"),
        supplyChain: yup
          .string()
          .test(
            "supplyChain-validation",
            "Chain Supply should be greater than or equal to 100000",
            function (value) {
              const supplyChainValue = parseInt(value);
              return supplyChainValue >= 100000;
            }
          )
          .required("Chain Supply required"),
      })
    ),
  });
};

export const NodeValidation = yup.object({
  newName: yup
    .string()
    .min(3, "Node name contain 3 letters")
    .required("Enter Node name"),
});

export const SignNewPassword = yup.object({
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(/[a-z]/, "Password must contain at least one lowercase character")
    .matches(/[A-Z]/, "Password must contain at least one uppercase character")
    .matches(
      /^(?=.*[!@#$%^&()_+\-=[\]{};':"\\|,.<>/?])/,
      "Password must contain at least one special character"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Both password need to be the same")
    .required("Confirm password is required"),
});

export const emailActive = yup.object({
  email: yup.string().email("Enter valid email").required("Email is required"),
});

export const changePassword = yup.object({
  oldPassword: yup.string().required("Current password is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(/[a-z]/, "Password must contain at least one lowercase character")
    .matches(/[A-Z]/, "Password must contain at least one uppercase character")
    .matches(
      /^(?=.*[!@#$%^&()_+\-=[\]{};':"\\|,.<>/?])/,
      "Password must contain at least one special character"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Both password need to be the same")
    .required("Confirm password is required"),
});
