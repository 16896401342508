import React, { useState } from "react";
import Card from "../../components/card/Card";
import NodeSummary from "./components/nodeSummary/NodeSummary";
import NodeConfig from "./components/nodeConfig/NodeConfig";
import NodeSetting from "./components/nodeSetting/NodeSetting";

const ViewNodes = () => {
  const [screen, setScreen] = useState(0);
  return (
    <React.Fragment>
      <Card>
        {screen === 0 ? (
          <NodeSummary setScreen={setScreen} />
        ) : screen === 1 ? (
          <NodeConfig setScreen={setScreen} />
        ) : screen === 2 ? (
          <NodeSetting setScreen={setScreen} />
        ) : (
          ""
        )}
      </Card>
    </React.Fragment>
  );
};

export default ViewNodes;
