import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { tick } from "../../assets/images";
import { palette } from "../../theme/palette";
import { useSelector } from "react-redux";

const ColorLibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 15,
    left: "calc(-55% + 20px)",
    right: "calc(45% + 20px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: palette.primary.main,
      marginLeft: "4px",
      marginRight: "4px",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: palette.primary.main,
      marginLeft: "4px",
      marginRight: "4px",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#E8E9E9",
    borderRadius: 1,
    marginLeft: "4px",
    marginRight: "4px",
  },
}));

const content = {
  "& .MuiStepLabel-label": {
    color: "#9CA3AF",
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "center",
    fontFamily: "InterRegular",
  },
  "& .MuiStepLabel-label.Mui-active": {
    color: "#013eb7",
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "center",
    fontFamily: "InterRegular",
  },
  "& .MuiStepLabel-label.Mui-completed": {
    color: "#013eb7",
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "center",
    fontFamily: "InterRegular",
  },
};

const ColorLibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  zIndex: 1,
  color: "#fff",
  width: 30,
  height: 30,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    background: "#fff",
    color: "#fff",
  }),
  ...(ownerState.completed && {
    background: "fff",
    color: "#fff",
  }),
}));

const IconDta = ({ data, title, success }) => {
  return (
    <Box
      sx={{
        height: "30px",
        width: "30px",
        backgroundColor: success ? palette.primary.main : "#fff",
        borderRadius: "50%",
        border:
          data || success
            ? `1px solid ${palette.primary.main}`
            : "1px solid #d1d5db",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {title}
    </Box>
  );
};

const main = {
  width: "100%",
  minHeight: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const primaryDot = {
  width: 10,
  height: 10,
  borderRadius: "50%",
  background: palette.primary.main,
};
const greyDot = {
  width: 10,
  height: 10,
  borderRadius: "50%",
  background: "#d1d5db",
};
function ColorLibStepIcon(props) {
  const { active, completed, className } = props;
  // The given code will show the completion and panding via below code they get value via redux to compere
  const icons = {
    // When it will bhe equal to the 1 it will show tick on the first stepper circle
    1: (
      <IconDta
        data={active}
        title={
          active ? (
            <Box sx={primaryDot} />
          ) : completed ? (
            <img src={tick} alt="tick" />
          ) : (
            <Box sx={greyDot} />
          )
        }
        success={completed}
      />
    ),
    // When it will bhe equal to the 2 it will show tick on the second stepper circle
    2: (
      <IconDta
        data={active}
        title={
          active ? (
            <Box sx={primaryDot} />
          ) : completed ? (
            <img src={tick} alt="tick" />
          ) : (
            <Box sx={greyDot} />
          )
        }
        success={completed}
      />
    ),
    // When it will bhe equal to the 3 it will show tick on the third stepper circle
    3: (
      <IconDta
        data={active}
        title={
          active ? (
            <Box sx={primaryDot} />
          ) : completed ? (
            <img src={tick} alt="tick" />
          ) : (
            <Box sx={greyDot} />
          )
        }
        success={completed}
      />
    ),
    4: (
      <IconDta
        data={active}
        title={
          active ? (
            <Box sx={primaryDot} />
          ) : completed ? (
            <img src={tick} alt="tick" />
          ) : (
            <Box sx={greyDot} />
          )
        }
        success={completed}
      />
    ),
  };

  return (
    <ColorLibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {<>{icons[String(props.icon)]}</>}
    </ColorLibStepIconRoot>
  );
}
const steps = [
  "Create Network",
  "Network Details",
  "Node Settings",
  "Manage Account",
  // "Create Node"
];

export default function SteppersUI() {
  const { stepperSteps } = useSelector((state) => state.test);
  return (
    // Stepper main code
    <Box sx={main}>
      <Stack sx={{ width: "100%" }} spacing={6}>
        <Stepper
          alternativeLabel
          activeStep={stepperSteps}
          connector={<ColorLibConnector />}
        >
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel sx={content} StepIconComponent={ColorLibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </Box>
  );
}
