import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Accordian from "./Accordian";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import { palette } from "../../../../theme/palette";
import { getActiveStep } from "../../../../redux/test/actions";
import {
  chainId,
  getAddNetwork,
  getAllMnemonics,
  getEnvironmentConfig,
} from "../../../../redux/node/actions";
import { MiniConfigValidation } from "../../../../utils/schema";
import Card from "../../../../components/card/Card";
import SteppersUI from "../../../../components/newStepper/NewStepper";
import Account from "./Account";
import PreFunf from "./PreFunf";
import SelectPharse from "./SelectPharse";
import Summry from "./Summry";
const bip39 = require("bip39");
const CryptoJS = require("crypto-js");
const CardDividerStyle = {
  background:
    " radial-gradient(50% 50% at 50% 50%, rgba(1, 62, 183, 0.31) 0%, rgba(255, 255, 255, 0) 100%)",
  height: 300,
  width: 4,
};
const FlexBox1Style = {
  flex: { xs: "0 0 100%", md: "0 0 48%" },
  display: { xs: "flex", md: "block" },
  justifyContent: "center",
  flexDirection: "column",
};
const MainTypoStyle = {
  textTransform: "none",
  mb: "1.3rem",
  mt: { xs: "1rem", md: "0" },
  lineHeight: { xs: "30px", md: "38px" },
};
const FlexBox2Style = {
  display: { xs: "none", md: "flex" },
};
const FlexBox3Style = {
  flex: { xs: "0 0 100%", md: "0 0 48%" },
  width: "100%",
};
const UpperBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  mt: "2.9rem",
};
const BtnBoxStyle = {
  display: "flex",
  justifyContent: "flex-end",
  mt: "1.5rem",
};

const Settings = () => {
  const location = useLocation();
  const state = location.state;
  const [RadioValue, setValue] = useState(state.type);
  const [algoValue, setAlgoValue] = useState("POS");
  const [inputValue, setInputFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getMnemonic, SetGetMnemonic] = useState("");
  const [hasError, setHasError] = useState(true);
  const [hasAddError, setHasAddError] = useState(true);
  const [screen, setScreen] = useState(0);
  const [selectedValue, setSelectedValue] = useState("");
  const {
    networkName,
    networkMission,
    networkRegion,
    environmentName,
    deployOption,
    provider, //1
    algorithm,
    envValue, //2
    getNewMnemonics,
    inputFields,
  } = useSelector((state) => state.node);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notify = () =>
    toast.success("Environment is created.", {
      style: {
        padding: "14px",
        color: palette.primary.main,
        animation: "none",
        transition: "none",
      },
      iconTheme: {
        primary: palette.primary.main,
        secondary: "#FFFAEE",
      },
    });

  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === "addAccount") {
      setInputFields([
        // ...inputValue,
        {
          accountName: "",
          mnemonics: "",
          amount: 100000,
          supplyChain: 1000000,
        },
      ]);
    } else if (event.target.value === "generateAccount") {
      // if (event.target.value === "generateAccount") {
      if (getNewMnemonics) {
        setInputFields([
          // ...inputValue,
          {
            accountName: "Account-1",
            mnemonics: getNewMnemonics,
            amount: 100000,
            supplyChain: 1000000,
          },
        ]);
      } else {
        const GetMnemonics = bip39.generateMnemonic((12 / 12) * 128);
        const nemonics = GetMnemonics && GetMnemonics?.split(" ");
        const duplicateElement =
          nemonics &&
          nemonics?.filter((item, index) => nemonics?.indexOf(item) !== index);

        if (duplicateElement.length > 0) {
          const newMnemonics = bip39.generateMnemonic((12 / 12) * 128);
          dispatch(getAllMnemonics(newMnemonics));
          SetGetMnemonic(newMnemonics);
          setInputFields([
            // ...inputValue,
            {
              accountName: "Account-1",
              mnemonics: newMnemonics,
              amount: 100000,
              supplyChain: 1000000,
            },
          ]);
        } else {
          dispatch(getAllMnemonics(GetMnemonics));
          SetGetMnemonic(GetMnemonics);
          setInputFields([
            // ...inputValue,
            {
              accountName: "Account-1",
              mnemonics: GetMnemonics,
              amount: 100000,
              supplyChain: 1000000,
            },
          ]);
        }
      }
      // }
    } else {
      return;
    }
  };

  // const handleGenerateField = () => {
  //   if (getNewMnemonics) {
  //     setInputFields([
  //       ...inputValue,
  //       {
  //         accountName: "Account-1",
  //         mnemonics: getNewMnemonics,
  //         amount: 100000,
  //         supplyChain: 1000000,
  //       },
  //     ]);
  //   } else {
  //     const GetMnemonics = bip39.generateMnemonic((12 / 12) * 128);
  //     dispatch(getAllMnemonics(GetMnemonics));
  //     SetGetMnemonic(GetMnemonics);
  //     setInputFields([
  //       ...inputValue,
  //       {
  //         accountName: "Account-1",
  //         mnemonics: GetMnemonics,
  //         amount: 100000,
  //         supplyChain: 1000000,
  //       },
  //     ]);
  //   }
  // };
  // Handling the onchange of the textfiels and add to the inputfield state
  const handleInputChange = (index, event) => {
    const updatedFields = [...inputValue];
    updatedFields[index][event.target.name] = event.target.value;
    setInputFields(updatedFields);
  };
  // Remove the add account input fields
  const handleRemoveField = (index) => {
    const updatedFields = [...inputValue];
    updatedFields.splice(index, 1);
    const value = formik.values.inputFields;
    value.splice(index, 1);
    setInputFields(updatedFields);
  };
  // Handling Consensus Algorithm value
  const handleAlgoValue = (event) => {
    setAlgoValue(event.target.value);
  };
  // Handling   Provider value
  const handleChange = (event) => {
    const value = event.currentTarget.getAttribute("data-value");
    setValue(value);
  };
  // Formik initial value
  const initialValues = {
    idvalue: "",
    password: "",
    // amount: "",
    // period: "",
    inputFields: [],
  };

  // Adding the add account fields more than one logic
  // const handleAddField = () => {
  //   if (selectedValue === "addAccount") {
  //     setInputFields([
  //       ...inputValue,
  //       {
  //         accountName: "",
  //         mnemonics: "",
  //         amount: 100000,
  //         supplyChain: 1000000,
  //       },
  //     ]);
  //   } else {
  //     setInputFields([
  //       ...inputValue,

  //       {
  //         accountName: envValue?.inputFields?.[0]?.accountName,
  //         mnemonics: envValue?.inputFields?.[0]?.mnemonics,
  //         supplyChain: envValue?.inputFields?.[0]?.supplyChain
  //           ? envValue?.inputFields?.[0]?.supplyChain
  //           : 1000000,
  //         amount: envValue?.inputFields?.[0]?.amount
  //           ? envValue?.inputFields?.[0]?.amount
  //           : 100000,
  //       },
  //     ]);
  //   }
  // };

  // Formik handle submit

  const onSubmit = async (values) => {
    const cipherText = CryptoJS.AES.encrypt(
      JSON.stringify(values?.inputFields[0]?.mnemonics),
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString();
    const apiData = {
      validator: networkName,
      chainId: values?.idvalue,
      password: values?.password,
      accountName: values?.inputFields[0]?.accountName,
      mnemonic: cipherText,
      balance: values?.inputFields[0]?.supplyChain * 1,
      stakeAmount: values?.inputFields[0]?.amount * 1,
      ...state,
    };
    let data = { Provider: RadioValue, Algorithm: algoValue, value: values };
    try {
      if (
        !values?.inputFields[0]?.accountName ||
        values?.inputFields[0]?.accountName.length < 1
      ) {
        setHasAddError(false);
      } else {
        dispatch(getActiveStep(3));
        dispatch(getEnvironmentConfig(data));
        // navigate("/node-overview");
        // notify();
        dispatch(getAddNetwork(apiData, navigate, setLoading));
      }
    } catch (error) {}
  };
  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema: MiniConfigValidation(state.type),
  });
  useEffect(() => {
    const fetchData = async () => {
      const data = {
        Provider: RadioValue,
        Algorithm: algoValue,
        value: formik.values,
      };
      dispatch(getEnvironmentConfig(data));
    };

    fetchData(); // Call the function to fetch data when component mounts
  }, [RadioValue, algoValue, formik.values, dispatch]); // Add dependencies if needed
  const handleBackScreen = () => {
    // dispatch(getActiveStep(1));
    // navigate("/network-details");
    if (selectedValue === "addAccount") {
      setScreen(2);
    } else {
      setScreen(3);
    }
  };
  // const{setFieldValue}=formik
  useEffect(() => {
    formik?.setFieldValue("inputFields", inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);
  useEffect(() => {
    if (formik?.values?.inputFields?.[0]?.mnemonics) {
      const validateMnemonic = bip39?.validateMnemonic(
        formik?.values?.inputFields[0]?.mnemonics
      );
      setHasError(validateMnemonic === true ? true : false);
    }
  }, [formik.values, hasError]);

  useEffect(() => {
    if (!state?.change) {
      formik.setFieldValue("idvalue", envValue.idvalue);
      formik.setFieldValue("password", envValue.password);
    }
  }, [envValue.value, selectedValue !== "addAccount", formik.errors]);
  useEffect(() => {
    formik.setFieldValue("inputFields", envValue.inputFields);
    formik.setFieldValue("period", envValue.period);
  }, [envValue.value]);
  useEffect(() => {
    let data = {
      Provider: RadioValue,
      Algorithm: algoValue,
      value: formik.values,
    };
    dispatch(getEnvironmentConfig(data));
  }, [formik.values]);
  const { stepperSteps } = useSelector((state) => state.test);
  useEffect(() => {
    if (stepperSteps === -1) {
      navigate("/");
    }
  }, [stepperSteps]);

  return (
    <Card>
      <Box
        sx={{
          mt: { xs: "1.8rem", md: ".5rem" },
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Box sx={{ maxWidth: "600px ", width: "100%" }}>
          <SteppersUI />
        </Box>
      </Box>

      <Box
        display="flex"
        sx={{
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          mt: 4,
        }}
      >
        {/* First flex box contain title and text */}
        <Box sx={FlexBox1Style}>
          <Typography variant="h1" sx={MainTypoStyle}>
            {screen === 0
              ? " What is meant by node settings?"
              : screen === 1
              ? "Pre-funded accounts"
              : screen === 2
              ? selectedValue === "addAccount"
                ? "What is meant by account details?"
                : "What is meant by generate account?"
              : screen === 3
              ? "Phrase verification step"
              : screen === 4
              ? "Summary"
              : ""}
          </Typography>
          {screen === 0 ? (
            <Box>
              <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
                <span style={{ color: "#013eb7", fontFamily: "InterSemiBold" }}>
                  {" "}
                  Nodes play{" "}
                </span>{" "}
                a critical role in a Blockchain network by maintaining the
                network's security and consensus. To ensure that all nodes agree
                on the state of the Blockchain, they validate transactions,
                verify data, and communicate with other nodes.
              </Typography>

              <Typography variant="subtitle1" textAlign="left" mt={1}>
                A{" "}
                <span style={{ color: "#013eb7", fontFamily: "InterSemiBold" }}>
                  {" "}
                  Chain ID{" "}
                </span>{" "}
                is a one-of-a-kind identifier for a blockchain network.
              </Typography>

              <Typography variant="subtitle1" textAlign="left" mt={1}>
                The network configuration you are performing is being encrypted
                with this
                <span style={{ color: "#013eb7", fontFamily: "InterSemiBold" }}>
                  {" "}
                  password
                </span>
                . Therefore, please save this password to view these
                configurations in the future and use it to import accounts.
              </Typography>
            </Box>
          ) : screen === 1 ? (
            <Box>
              <Typography variant="subtitle1" textAlign="left">
                An account can be designated as a
                <span style={{ color: "#013eb7", fontFamily: "InterSemiBold" }}>
                  {" "}
                  pre-funded account
                </span>
                . When an account is designated as pre-funded, the initial
                supply of network will be deposited into this account.
              </Typography>
            </Box>
          ) : screen === 2 ? (
            <Box>
              {selectedValue === "addAccount" ? (
                <Typography variant="subtitle1" textAlign="left">
                  Adding an account requires
                  <span
                    style={{ color: "#013eb7", fontFamily: "InterSemiBold" }}
                  >
                    {" "}
                    mnemonics
                  </span>
                  . If you don't have a mnemonic, you can go back and generate
                  an account, and please remember to save your mnemonic.
                </Typography>
              ) : (
                <>
                  <Typography variant="subtitle1" textAlign="left">
                    Users can also change their default
                    <span
                      style={{
                        color: "#013eb7",
                        fontFamily: "InterSemiBold",
                      }}
                    >
                      {" "}
                      Account name
                    </span>
                    .
                  </Typography>
                  <Typography variant="subtitle1" textAlign="left" mt={1}>
                    <span
                      style={{
                        color: "#013eb7",
                        fontFamily: "InterSemiBold",
                      }}
                    >
                      Mnemonic{" "}
                    </span>
                    phrases are an optimized version of private keys for easier
                    memorization. You can use a mnemonic or seed phrase to
                    confirm transactions and recover lost cryptocurrency wallet
                    accounts.
                  </Typography>
                </>
              )}
              <Typography variant="subtitle1" textAlign="left" mt={1}>
                <span style={{ color: "#013eb7", fontFamily: "InterSemiBold" }}>
                  {" "}
                  Chain Supply{" "}
                </span>{" "}
                is the total supply of a CTA coin that exists within a network.
                It should be greater than 100000
              </Typography>

              <Typography variant="subtitle1" textAlign="left" mt={1}>
                <span style={{ color: "#013eb7", fontFamily: "InterSemiBold" }}>
                  {" "}
                  Initial Stake amount{" "}
                </span>
                refers to the initial quantity or value of a CTA coin that a
                participant commits and locks up as a stake when participating
                in a consensus mechanism. It should be greater than or equal to
                100000 and less than Chain Supply
              </Typography>
            </Box>
          ) : screen === 3 ? (
            <Box>
              <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
                Verification step for the generated mnemonic. Please arrange the
                words below in the appropriate order to proceed further.
              </Typography>
            </Box>
          ) : screen === 4 ? (
            <Box>
              <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
                Please check the
                <span style={{ color: "#013eb7", fontFamily: "InterSemiBold" }}>
                  {" "}
                  summary{" "}
                </span>{" "}
                of the whole process. If you want to make any changes, you can
                do so. However, once you finish, your network will be created,
                and you will not be able to change it again.
              </Typography>
            </Box>
          ) : (
            ""
          )}
        </Box>
        {/* Second flex box conatin the divider line  */}
        <Box sx={FlexBox2Style}>
          {" "}
          <Box sx={CardDividerStyle} />
        </Box>

        <Box sx={FlexBox3Style}>
          {/* Formik form starts */}
          <form
            onSubmit={formik.handleSubmit}
            style={{
              width: "100%",
              height: "auto",
            }}
          >
            {/* All code on the Accordian is in this child component */}
            {screen === 0 ? (
              <Accordian
                formik={formik}
                handleRemoveField={handleRemoveField}
                handleInputChange={handleInputChange}
                state={state}
                // handleAddField={handleAddField}
                // handleGenerateField={handleGenerateField}
                inputValue={inputValue}
                hasError={hasError}
                hasAddError={hasAddError}
                envValue={envValue}
                setScreen={setScreen}
              />
            ) : screen === 1 ? (
              <PreFunf
                formik={formik}
                handleRemoveField={handleRemoveField}
                handleInputChange={handleInputChange}
                // handleAddField={handleAddField}
                // handleGenerateField={handleGenerateField}
                inputValue={inputValue}
                hasError={hasError}
                hasAddError={hasAddError}
                envValue={envValue}
                setScreen={setScreen}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                handleChange={handleChangeRadio}
              />
            ) : screen === 2 ? (
              <Account
                formik={formik}
                handleRemoveField={handleRemoveField}
                handleInputChange={handleInputChange}
                // handleAddField={handleAddField}
                // handleGenerateField={handleGenerateField}
                inputValue={inputValue}
                hasError={hasError}
                hasAddError={hasAddError}
                envValue={envValue}
                setScreen={setScreen}
                selectedValue={selectedValue}
              />
            ) : screen === 3 ? (
              <SelectPharse setScreen={setScreen} />
            ) : screen === 4 ? (
              <Summry setScreen={setScreen} />
            ) : (
              ""
            )}
            {screen === 4 && (
              <Box sx={BtnBoxStyle}>
                <Button
                  // onClick={handleBack}
                  onClick={handleBackScreen}
                  data-cy="Back4"
                  sx={{
                    borderRadius: "5px",
                    maxWidth: "7.2rem",
                    width: "100%",
                  }}
                  variant="text"
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  data-cy="Finish"
                  disabled={
                    // formik?.values?.inputFields[0]?.mnemonics &&
                    // formik?.values?.inputFields[0]?.mnemonics
                    //   ? !hasError
                    //   : null || loading
                    //   ? true
                    //   : false
                    !hasError ? true : loading ? true : false
                  }
                  sx={{
                    borderRadius: "5px",
                    maxWidth: "7.2rem",
                    width: "100%",
                    ml: ".5rem",
                  }}
                  variant="contained"
                >
                  {!loading ? " Finish" : <CircularProgress size="1.7rem" />}
                </Button>
              </Box>
            )}
          </form>
        </Box>
      </Box>
    </Card>
  );
};

export default Settings;
