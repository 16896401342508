export const GET_NETWORK_SUMMERY = "GET_NETWORK_SUMMERY";
export const GET_NETWORK_REGION = "GET_NETWORK_REGION";

export const GET_ENVIRONMENT_SUMMERY = "GET_ENVIRONMENT_SUMMERY";
export const GET_ENVIRONMENT_CONFIG = "GET_ENVIRONMENT_CONFIG";

export const GET_NODE_SUMMERY = "GET_NODE_SUMMERY";
export const GET_NODE_SETTING = "GET_NODE_SETTING";

export const SEND_NODE_INFORMATION = "SEND_NODE_INFORMATION";

export const SET_ADD_NETWORK = "SET_ADD_NETWORK";
export const SET_ADD_NETWORK_LOADING = "SET_ADD_NETWORK_LOADING";

export const SET_NODE = "SET_NODE";
export const GET_MNEMONICS = "GET_MNEMONICS";
export const SET_NODE_LOADING = "SET_NODE_LOADING";

export const DOWNLOAD_FILE = "DOWNLOAD_FILE";

export const IS_CHAIN = "IS_CHAIN";
export const CHAIN_LOADING = "CHAIN_LOADING";