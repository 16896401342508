/* eslint-disable no-sequences */
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { AuthBackground } from "../../components/auth";
import { SignUpValidation } from "../../utils/schema";
import { BlueLeftArrow, Visibility, VisibilityOff } from "../../assets/images";
import useDeviceSize from "../../hooks/windowResize";
// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/high-res.css";
import "./auth.css";
import { getSignUp, getSignUpLoading } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "react-hot-toast";

const iBtn = {
  cursor: "pointer",
  boxShadow: "none",
};
// const ErrorTypo = {
//     fontWeight: 400,
//     fontSize: "0.75rem",
//     lineHeight: 1.66,
//     letterSpacing: "0.03333em",
//     textAlign: "left",
//     marginLeft: "20px",
//     marginTop: "3px",
//     color: "#e53935",
// };
const MainBoxStyle = {
  display: { xs: "flex", md: "none" },
  position: "absolute",
  left: "1.5rem",
  top: "1.5rem ",
  cursor: "pointer",
};
// const phoneMainBox = {
//     width: "100%",
//     height: "82px",
//     mt: 3,
//     position: "relative",
// };
// const phoneDropDown = {
//     position: "absolute",
//     zIndex: 99,
//     top: "20%",
//     left: "9.2%",
//     "@media (max-width:482px)": {
//         left: "10%",
//     },
//     "@media (max-width:440px)": {
//         left: "12%",
//     },
//     "@media (max-width:370px)": {
//         left: "14%",
//     },
//     "@media (max-width:318px)": {
//         left: "17%",
//     },
// };
// const phonePlaceholder = {
//     fontFamily: "InterRegular",
//     fontSize: "16px",
//     fontWeight: 400,
//     color: "#9CA3AF",
//     position: "absolute",
//     zIndex: 99,
//     top: "10%",
//     left: "15%",
//     backgroundColor: "white",
//     padding: ".5rem",
//     "@media (max-width:482px)": {
//         left: "18%",
//     },
//     "@media (max-width:415px)": {
//         left: "20%",
//     },
//     "@media (max-width:384px)": {
//         left: "23%",
//     },
//     "@media (max-width:348px)": {
//         left: "27%",
//     },
//     "@media (max-width:301px)": {
//         left: "30%",
//         whiteSpace: "nowrap",
//         width: "4rem",
//     },
//     "@media (max-width:280px)": {
//         left: "33%",
//         width: "7rem",
//         display: "block",
//         overflow: "hidden",
//         whiteSpace: "nowrap",
//     },
// };
const BackStyle = { fontSize: "20px", marginLeft: ".5rem", color: "#013EB7" };
const TextFieldBoxSyle = { width: "100%", height: "82px", mt: 1 };

const SignUp = ({ recaptchaReady }) => {
  const Navigation = useNavigate();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { signUpLoading } = useSelector((state) => state.auth);
  const { height } = useDeviceSize();
  const initialValues = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    // phoneNumber: "",
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const [blur, setBlur] = useState(false);
  // const phoneInputRef = useRef(null);

  // const handleClick = () => {
  //     if (phoneInputRef.current) {
  //         phoneInputRef.current.focus();
  //         // You can also access other methods of the PhoneInput component using the ref, e.g., phoneInputRef.current.isValidNumber()
  //     }
  // };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  // this function is also validating the phone number
  // const handlePhoneNumberChange = (value, country) => {
  //     formik.setFieldValue("phoneNumber", value);
  // };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const onSubmit = (values) => {
    handleLogin(values);
  };
  const handleLogin = async (values) => {
    if (!executeRecaptcha) {
      toast.error("Execute reCAPTCHA not yet available");
      return;
    }

    dispatch(getSignUpLoading(true));
    const token = await executeRecaptcha("login");

    if (!token) {
      toast.error("reCAPTCHA verification failed");
      return;
    }

    // Include the reCAPTCHA token in your login request

    const data = {
      name: values.name,
      email: values.email,
      password: values.password,
      confirmPassword: values.confirmPassword,
      recaptcha: token,
    };

    // Dispatch your login action with the data
    dispatch(getSignUp(data, formik.resetForm));
  };
  // formik
  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema: SignUpValidation,
  });
  // removing the blank space from the input values
  const sanitizedPssword = formik.values.password.replace(/\s/g, "");
  const sanitizedConfPassword = formik.values.confirmPassword.replace(
    /\s/g,
    ""
  );
  formik.values.password = sanitizedPssword;
  formik.values.confirmPassword = sanitizedConfPassword;

  return (
    <React.Fragment>
      <AuthBackground newHeight={height > 680 ? "100vh" : "100%"}>
        <Box sx={{ zIndex: "100", maxWidth: "32rem", width: "100%" }}>
          {/* End and start of form boc , */}
          <Box
            onClick={() => Navigation("/sign-in")}
            sx={MainBoxStyle}
            data-cy="sigup-box"
          >
            {" "}
            <img src={BlueLeftArrow} alt=" Left Arrow" />
            <Typography variant="subtitle4" sx={BackStyle}>
              Back
            </Typography>
          </Box>
          <Typography
            variant="h1"
            textAlign="center"
            color="#000"
            sx={{ p: "5rem 0 3rem 0" }}
          >
            CREATE AN ACCOUNT
          </Typography>
          <form
            onSubmit={formik.handleSubmit}
            style={{
              padding: "0 2rem",
            }}
          >
            {/* Name input field */}
            <Box sx={TextFieldBoxSyle}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                type="name"
                name="name"
                data-cy=" signUp-input-name"
                {...formik.getFieldProps("name")}
                error={Boolean(formik.touched.name && formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                sx={{ mt: 2 }}
              />
            </Box>
            {/* Email input field */}
            <Box sx={TextFieldBoxSyle}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                type="email"
                name="email"
                data-cy=" signUp-input-email"
                {...formik.getFieldProps("email")}
                error={Boolean(formik.touched.email && formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                sx={{ mt: 2 }}
              />
            </Box>
            {/* Password input field */}
            <Box sx={TextFieldBoxSyle}>
              <TextField
                label="Password "
                variant="outlined"
                value={sanitizedPssword}
                fullWidth
                name="password"
                data-cy=" signUp-input-password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        data-cy="sigup-toggle"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        sx={iBtn}
                      >
                        {showPassword ? (
                          <img src={Visibility} width="20px" alt="visibleon" />
                        ) : (
                          <img
                            src={VisibilityOff}
                            width="20px"
                            alt="visibleoff"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...formik.getFieldProps("password")}
                error={Boolean(
                  formik.touched.password && formik.errors.password
                )}
                helperText={formik.touched.password && formik.errors.password}
                sx={{ mt: 2 }}
              />
            </Box>
            {/* Confirm password input field */}
            <Box sx={TextFieldBoxSyle}>
              <TextField
                label="Confirm password "
                variant="outlined"
                value={sanitizedConfPassword}
                fullWidth
                name="confirmPassword"
                data-cy=" signUp-input-confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        data-cy="sigup-toggle-password"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        sx={iBtn}
                      >
                        {showConfirmPassword ? (
                          <img src={Visibility} width="20px" alt="visibleon" />
                        ) : (
                          <img
                            src={VisibilityOff}
                            width="20px"
                            alt="visibleoff"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...formik.getFieldProps("confirmPassword")}
                error={Boolean(
                  formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                )}
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                sx={{ mt: 2 }}
              />
            </Box>
            {/* Phone number  input field */}
            {/* <Box>
              <Box sx={phoneMainBox}>
                <Box sx={phoneDropDown}>
                  <img src={BlueDropDown} alt="phone " />
                </Box>
                <Typography
                  onClick={() => {
                    setBlur(true);
                    handleClick();
                  }}
                  sx={{
                    ...phonePlaceholder,
                    display: blur ? "none" : "block",
                  }}
                >
                  Enter your number
                </Typography>
                <PhoneInput
                  country={"kr"}
                  autoFormat={false}
                  value={formik.values.phoneNumber}
                  onChange={handlePhoneNumberChange}
                  onFocus={() => {
                    setBlur(true);
                  }}
                  name="phoneNumber"
                  placeholder={"Enter phone number"}
                  containerStyle={{
                    border:
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                        ? "1px solid red"
                        : "1px solid #CACACA",
                    borderRadius: "5px",
                  }}
                  inputProps={{
                    name: "mobile",
                    ref: phoneInputRef,
                    style: {
                      height: "56px",
                      width: "100%",
                      fontSize: "1rem",
                    },
                  }}
                  inputStyle={{
                    width: "300px", // Set the width of the input field
                  }}
                  dropdownProps={{
                    name: "country",
                    tabIndex: 1,
                  }}
                  buttonStyle={{
                    minWidth: "55px",
                  }}
                  dropdownStyle={{
                    fontFamily: "InterRegular",
                    fontSize: "14px",
                  }}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                  <Typography style={ErrorTypo}>
                    {formik.errors.phoneNumber}
                  </Typography>
                ) : null}
              </Box>
            </Box> */}
            {/* Sign up button */}
            {/* Disbale the button on basis of loading state and adding spinner */}
            <Button
              type="submit"
              data-cy="signUp"
              disabled={signUpLoading || !recaptchaReady ? true : false}
              sx={{ borderRadius: "5px", width: "100%", mt: 2 }}
              variant="contained"
            >
              {!signUpLoading ? " Sign up" : <CircularProgress size="1.7rem" />}
            </Button>
          </form>
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "center", padding: "2rem" }}
          >
            Already have an account? {/* It will show the underline on hover */}
            <Link
              to="/sign-in"
              style={{
                fontStyle: "italic",
                color: "#013EB7",
                whiteSpace: "nowrap",
              }}
              onMouseOver={(event) => (
                (event.target.style.color = "#032d82"),
                (event.target.style.textDecoration = "underline")
              )}
              onMouseOut={(event) => (
                (event.target.style.color = "#013EB7"),
                (event.target.style.textDecoration = "none")
              )}
            >
              Sign in
            </Link>
          </Typography>
        </Box>
      </AuthBackground>
    </React.Fragment>
  );
};

export default SignUp;
