import React, { useEffect, useState } from "react";
import SignIn from "./Login";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useLocation, useNavigate } from "react-router-dom";
import SignUp from "./SignUp";

const Recaptcha = () => {
  const Navigation = useNavigate();
  const token = localStorage.getItem("token");

  const { pathname } = useLocation();
  const [recaptchaReady, setRecaptchaReady] = useState(false);

  useEffect(() => {
    // Create a script element for the reCAPTCHA API
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?render=explicit";
    script.async = true;

    // Set the callback function for when the script is loaded
    script.onload = () => setRecaptchaReady(true);

    // Append the script to the document's head
    document.head.appendChild(script);

    // Clean up the script element when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
      {pathname === "/sign-in" ? (
        <SignIn recaptchaReady={recaptchaReady} />
      ) : pathname === "/sign-up" ? (
        <SignUp recaptchaReady={recaptchaReady} />
      ) : (
        ""
      )}
    </GoogleReCaptchaProvider>
  );
};

export default Recaptcha;

// useEffect(() => {
//   // Simulate some loading time
//   setTimeout(() => {
//     setIsLoading(false);
//   }, 2000); // You can adjust the loading time as per your requirement
// }, []);
