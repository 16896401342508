/* eslint-disable no-sequences */
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { SignNewPassword, changePassword } from "../../utils/schema";
import { BlueLeftArrow, Visibility, VisibilityOff } from "../../assets/images";
import useDeviceSize from "../../hooks/windowResize";

import { changPassword, getReset } from "../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
// import queryString from "query-string";
// import HeaderName from "../../components/headerName/HeaderName";
import Card from "../../components/card/Card";
const iBtn = {
  cursor: "pointer",
  boxShadow: "none",
};
// eslint-disable-next-line
const MainBoxStyle = {
  display: { xs: "flex", md: "none" },
  position: "absolute",
  left: "1.5rem",
  top: "1.5rem ",
  cursor: "pointer",
};
// eslint-disable-next-line
const BackStyle = { fontSize: "20px", marginLeft: ".5rem", color: "#013EB7" };
const TextFieldBoxSyle = { width: "100%", height: "82px", mt: 1 };

const PasswordSetting = () => {
  const Navigation = useNavigate();
  const dispatch = useDispatch();
  // const location = useLocation();
  const { loginLoading } = useSelector((state) => state.auth);
  // const { height } = useDeviceSize();

  const initialValues = {
    oldPassword: "",
    password: "",
    confirmPassword: "",
  };
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const onSubmit = (values) => {
    const data = {
      previousPassword: values.oldPassword,
      newPassword: values.password,
      confirmPassword: values.confirmPassword,
    };
    dispatch(changPassword(data, Navigation));
  };
  // formik
  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema: changePassword,
  });
  // removing the blank space from the input values
  const sanitizedPssword = formik.values.password.replace(/\s/g, "");
  const sanitizedConfPassword = formik.values.confirmPassword.replace(
    /\s/g,
    ""
  );
  formik.values.password = sanitizedPssword;
  formik.values.confirmPassword = sanitizedConfPassword;

  return (
    <Card>
      {/* <HeaderName /> */}
      {/* <AuthBackground newHeight={height > 680 ? "100vh" : "100%"}> */}
      <Box
        sx={{ zIndex: "100", maxWidth: "32rem", width: "100%", m: "0 auto" }}
      >
        {/* End and start of form boc , */}

        <Typography
          variant="h1"
          textAlign="center"
          color="#013EB7"
          sx={{ p: "5rem 0 3rem 0" }}
        >
          Change Password
        </Typography>
        <form
          onSubmit={formik.handleSubmit}
          style={{
            padding: 0,
          }}
        >
          {/* Password input field */}
          <Box sx={TextFieldBoxSyle}>
            <TextField
              label=" Current password "
              variant="outlined"
              value={formik.values.oldPassword}
              fullWidth
              name="oldPassword"
              data-cy="input-password2"
              type={showOldPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowOldPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={iBtn}
                      data-cy="reset-IconButton"
                    >
                      {showOldPassword ? (
                        <img src={Visibility} width="20px" alt="visibleon" />
                      ) : (
                        <img
                          src={VisibilityOff}
                          width="20px"
                          alt="visibleoff"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                onChange: (event) => {
                  const { name, value } = event.target;
                  const formattedValue = value.replace(/\s/g, "");
                  formik.setFieldValue(name, formattedValue);
                },
              }}
              {...formik.getFieldProps("oldPassword")}
              error={Boolean(
                formik.touched.oldPassword && formik.errors.oldPassword
              )}
              helperText={
                formik.touched.oldPassword && formik.errors.oldPassword
              }
              sx={{ mt: 2 }}
            />
          </Box>

          <Box sx={TextFieldBoxSyle}>
            <TextField
              label=" New password "
              variant="outlined"
              value={sanitizedPssword}
              fullWidth
              name="password"
              data-cy="input-password2"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={iBtn}
                      data-cy="reset-IconButton"
                    >
                      {showPassword ? (
                        <img src={Visibility} width="20px" alt="visibleon" />
                      ) : (
                        <img
                          src={VisibilityOff}
                          width="20px"
                          alt="visibleoff"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...formik.getFieldProps("password")}
              error={Boolean(formik.touched.password && formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              sx={{ mt: 2 }}
            />
          </Box>
          {/* Confirm password input field */}
          <Box sx={TextFieldBoxSyle}>
            <TextField
              label="Confirm password "
              variant="outlined"
              value={sanitizedConfPassword}
              fullWidth
              name="confirmPassword"
              data-cy="input-confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      data-cy="reset-IconButton2"
                      edge="end"
                      sx={iBtn}
                    >
                      {showConfirmPassword ? (
                        <img src={Visibility} width="20px" alt="visibleon" />
                      ) : (
                        <img
                          src={VisibilityOff}
                          width="20px"
                          alt="visibleoff"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...formik.getFieldProps("confirmPassword")}
              error={Boolean(
                formik.touched.confirmPassword && formik.errors.confirmPassword
              )}
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              sx={{ mt: 2 }}
            />
          </Box>

          {/* Disbale the button on basis of loading state and adding spinner */}
          <Box
            sx={{ display: "flex", mt: 2, gap: 2, justifyContent: "flex-end" }}
          >
            <Button
              variant="outlined"
              data-cy="Confirm-cancel"
              onClick={() => Navigation("/")}
              sx={{ borderRadius: "5px", width: { xs: "50%", md: "30%" } }}
            >
              Back
            </Button>
            <Button
              type="submit"
              data-cy="Confirm"
              disabled={loginLoading ? true : false}
              sx={{ borderRadius: "5px", width: { xs: "50%", md: "30%" } }}
              variant="contained"
            >
              {!loginLoading ? " Update" : <CircularProgress size="1.7rem" />}
            </Button>
          </Box>
        </form>
      </Box>
      {/* </AuthBackground> */}
    </Card>
  );
};

export default PasswordSetting;
