import React from "react";
import { ViewDashboard, ViewNetwork, ViewNode } from "./view";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import { ForgetPassword, ResetPassword, SignIn, SignUp } from "./view/auth";
import ViewEnvironment from "./view/createEnvironment/ViewEnvironment";
import { NodeOverview } from "./view/nodeOverview";
import { Dashboard } from "./view/dashboard";
import { NodeAndAppOverview } from "./view/nodeAndAppOverview";
import Activate from "./view/auth/Activate";
import PrivateRoutes from "./helper/PrivateRoutes";
import { NotFound } from "./components/404";
import Settings from "./view/createEnvironment/components/minimumConfiguration/Settings";
import Recaptch from "./view/auth/Recaptch";
import Recaptcha from "./view/auth/Recaptch";
import PublicRoute from "./helper/PublicRoutes";
import PasswordSetting from "./view/settings/PasswardSetting";
import HelpCenter from "./view/settings/HelpCenter";

const AppRoutes = () => {
  return (
    // <BrowserRouter>
    <MainLayout>
      <Routes>
        <Route path="/" element={<PrivateRoutes />}>
          <Route path="/" element={<ViewDashboard />} />
          <Route path="/network" element={<ViewNetwork />} />
          <Route path="/node" element={<ViewNode />} />
          <Route path="/network-details" element={<ViewEnvironment />} />
          <Route path="/node-overview/:id" element={<NodeOverview />} />
          <Route path="/manage-node" element={<Dashboard />} />
          <Route
            path="/manage-node/overview/:id"
            element={<NodeAndAppOverview />}
          />
          <Route path="/node-settings" element={<Settings />} />
          <Route path="/settings" element={<PasswordSetting />} />
          <Route path="/help-center" element={<HelpCenter />} />
        </Route>
        <Route path="/sign-in" element={<PublicRoute />}>
          <Route path="/sign-in" element={<Recaptcha />} />
          {/*  */}
        </Route>
        <Route path="/sign-up" element={<Recaptcha />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/email-activate" element={<Activate />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/not-found" replace />} />
      </Routes>
    </MainLayout>
    // </BrowserRouter>
  );
};

export default AppRoutes;
