import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDetail } from "../../redux/actions";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { CreataLogo, fullLogo } from "../../assets/images";

const logoBox1 = {
  display: "flex",
  alignItems: "center",
};
const HeaderInfo = () => {
  const Navigator = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const { detail } = useSelector((state) => state.auth);
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      dispatch(getDetail(token, Navigator));
    }
  }, [dispatch, token]);
  const capitalizeFirstLetter = (str) => {
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const { pathname } = useLocation();

  const wrapper = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    width: "100%",
    // mt: 2,
    flexDirection: "column",

    padding: "13px 0px",
  };

  return (
    <React.Fragment>
      <Box sx={{ ...wrapper, background: "rgba(255, 255, 255, 0.56)" }}>
        {/* Logo icon code */}

        {/* Notification and other buttons in code */}
        <Box
          sx={{
            flex: "0 0 50%",
            padding: "0 0 1rem 0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            width: "98%",
            m: "0 auto",
            p: "5px 0px",
          }}
        >
          <Box sx={logoBox1}>
            <Link to="/">
              <img
                src={fullLogo}
                alt="creatachain logo"
                style={{
                  height: "2.5rem",
                }}
              />
            </Link>
            {/* <Typography
              variant="h5"
              sx={{
                // display: { xs: "none", sm: "block" },
                marginLeft: ".6rem",
              }}
            >
              <Link
                to="/"
                style={{
                  fontSize: "1rem",
                }}
              >
                CREATACHAIN
              </Link>
            </Typography> */}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                background:
                  "linear-gradient(0deg, rgba(1, 62, 183, 0.14), rgba(1, 62, 183, 0.14))",
                borderRadius: "4px",
                border: "1px solid #013EB724",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ color: "#000", p: "4px 10px" }}
              >
               Zenith-Mainnet
              </Typography>
            </Box>
            <IconButton
              onClick={handleClick}
              data-cy="header-small"
              size="small"
              sx={{
                ml: 2,
              }}
              aria-controls={anchorEl ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={anchorEl ? "true" : undefined}
            >
              {getDetail.image ? (
                <Avatar sx={{ width: 40, height: 40 }}>
                  <img
                    src={getDetail.image}
                    alt="profile"
                    width="100%"
                    height="100%"
                  />
                </Avatar>
              ) : (
                <Avatar sx={{ width: 32, height: 32, background: "#0575E6" }}>
                  {capitalizeFirstLetter(detail?.name?.charAt(0)) || "A"}
                </Avatar>
              )}
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              data-cy="account-menu"
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem sx={{ m: 1 }} onClick={() => Navigator("/help-center")}>
                Help center
              </MenuItem>
              <Divider sx={{ borderStyle: "dashed" }} />
              <MenuItem onClick={() => Navigator("/settings")} sx={{ m: 1 }}>
                Settings
              </MenuItem>
              <Divider sx={{ borderStyle: "dashed" }} />
              <MenuItem
                sx={{ m: 1 }}
                onClick={() => {
                  localStorage.removeItem("token");
                  // Navigator("/sign-in");
                  Navigator("/sign-in", { replace: true });
                }}
                data-cy="Logout-menu"
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default HeaderInfo;
