import {
  Box,
  Button,
  CircularProgress,
  SvgIcon,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Card from "../../components/card/Card";
import { RightArrLogo } from "../../assets/images";
import { ReactComponent as ExclaminationIcon } from "../../assets/images/exclamination.svg";
import HorizontalLine from "../../components/divider";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getActiveStep } from "../../redux/test/actions";
import { getNods } from "../../redux/node/actions";
import { SET_ADD_NETWORK } from "../../redux/node/actionTypes";
import { format } from "timeago.js";
import { palette } from "../../theme/palette";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";

// Extend dayjs with the required plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const StartedStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "100%",
  height: "100%",
  width: "100%",
  maxWidth: "70px",
  maxHeight: "32px",
  bgcolor: "#49AA26",
  borderRadius: "5px",
  padding: ".35rem 1rem",
  cursor: "pointer",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "revert",
  },
};
const ButtonStyle = {
  borderRadius: "5px",
  borderTopLeftRadius: "0px",
  borderTopRightRadius: "0px",
  fontSize: "14px",
  fontWeight: 500,
  maxHeight: "32px",
};
const FirstFlex = {
  bgcolor: "#EAF0F7",
  borderRadius: "5px",
  // minHeight: "calc(100vh - 122px)",
  // maxHeight: "550px",
  // maxHeight: "405px",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "space-between",
  width: { xs: "100%", md: "40%" },
  // maxWidth: { xs: "100%", md: "40%" },
  marginRight: { xs: "0rem", md: "1rem" },
};
const PauseBox = {
  display: "flex",
  justifyContent: "space-between",
  // marginTop: "2rem",
  flexWrap: "wrap",
  alignItems: "center",
};
const PauseButton = {
  ml: { xs: 0, sm: 1 },
  "& .MuiButton-startIcon": { marginRight: "2px" },
  bgcolor: "green",
  maxWidth: "70px",
  minWidth: "70px",
  padding: ".2rem",
  maxHeight: "24px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "5px",
  boxShadow: " 0px 7.508px 10.5112px rgba(0, 0, 0, 0.07)",
  color: "#000",
  // "&:hover": {
  //   backgroundColor: "green",
  // },
};
const DividerBox = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  py: "1.2rem",
};
const DividerStyle = {
  background:
    " radial-gradient(50% 50% at 50% 50%, rgba(1, 62, 183, 0.25) 0%, rgba(255, 255, 255, 0) 100%)",
  height: "4px",
  maxWidth: "223px",
  width: "100%",
};
const MainBoxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
// Dashboard component
const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { informationOfNode, getNode } = useSelector((state) => state.node);
  //   const [time, setTime] = useState(null);
  //   useEffect(() => {
  //     if (time === null) {
  //       setTime(getNode ? getNode[0] : "");
  //     }
  //   }, []);
  const handleLink = () => {
    dispatch(getActiveStep(0));
  };

  const Navigation = useNavigate();
  // useEffect(() => {
  //   dispatch(getNods());
  // }, []);

  const handelNavigate = (item) => {
    navigate(`/manage-node/overview/${item?._id}`);
    // dispatch({
    //   type: SET_ADD_NETWORK,
    //   payload: item,
    // });
  };

  useEffect(() => {
    dispatch({
      type: SET_ADD_NETWORK,
      payload: null,
    });
  }, []);

  return (
    <React.Fragment>
      {/* Card component */}
      {getNode === null ? (
        <Card>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "500px",
            }}
          >
            <CircularProgress />{" "}
          </Box>
        </Card>
      ) : (
        <Card>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              width: "100%",
            }}
          >
            {/* First Flex Box */}
            <Box sx={FirstFlex}>
              <Box sx={{ padding: { xs: "1.5rem 1rem", md: "1.5rem 1.2rem" } }}>
                <Typography variant="h3" mb={5}>
                  {getNode?.length > 1 ? "Blockchain nodes" : "Blockchain node"}{" "}
                  ({getNode?.length})
                </Typography>

                {getNode?.map((item, i) => (
                  <Box key={i}>
                    {/* Pause Box */}
                    <Box sx={PauseBox}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          // onClick={() => setTime(item)}
                          onClick={() => handelNavigate(item)}
                          data-cy="dashboard"
                          sx={{ cursor: "pointer" }}
                        >
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  maxWidth: {
                                    xs: "80px",
                                    sm: "80px",
                                    md: "110px",
                                    lg: "110px",
                                  },
                                  minWidth: {
                                    xs: "80px",
                                    sm: "80px",
                                    md: "110px",
                                    lg: "110px",
                                  },
                                }}
                              >
                                {
                                  item?.nodeDetails?.app_state?.genutil
                                    ?.gen_txs[0]?.body?.messages[0]?.description
                                    ?.moniker
                                }
                              </Typography>
                              <img
                                src={RightArrLogo}
                                alt="arrow logo"
                                style={{ margin: ".2rem 1rem 0rem " }}
                              />
                              {/* Pause button to stop the node */}
                            </Box>
                            <Typography
                              variant="subtitle7"
                              sx={{ fontWeight: "bold" }}
                            >
                              {item?.node_type === "catena"
                                ? "Catena (CIP-20) Chain"
                                : "Zenith Chain"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={PauseButton}>
                          {/* <Box sx={{ mr: "2px", width: "12px" }}>
                          <ExclaminationIcon width="100%" height="100%" />
                        </Box> */}
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: 400,
                              color: "#fff",
                            }}
                          >
                            Configured
                          </Typography>
                        </Box>
                      </Box>
                      {/*  It only show the status of the node , Started Box  */}
                      <Box
                        //   onClick={() =>
                        //     navigate(`/manage-node/overview/${item?._id}`)
                        //   }
                        //   `/dashboard/${itm?._id}`
                        sx={{
                          color: "#013eb7",
                          textDecoration: "revert",
                          cursor: "pointer",
                          "@media (maxWidth:600px)": {
                            fontSize: "12px",
                          },
                        }}
                        onClick={() => handelNavigate(item)}
                        data-cy="dashboard2"
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "12px", sm: "14px" },
                            cursor: "pointer",
                          }}
                        >
                          Overview
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={DividerBox}>
                      <Box sx={DividerStyle} />
                    </Box>
                  </Box>
                ))}

                {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: ".2rem",
                }}
              >
                <Link to="/overview">
                  <Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="h4">Qudrat node</Typography>
                      <img
                        src={RightArrLogo}
                        alt="arrow logo"
                        style={{ margin: ".2rem .5rem 0rem " }}
                      />
                    </Box>
                    <Typography variant="subtitle7">Magnus mage</Typography>
                  </Box>
                </Link>
              </Box> */}
              </Box>
              {/* Create Node Button */}
              {/* <Button
              fullWidth
              variant="contained"
              sx={ButtonStyle}
              onClick={() => {
                Navigation("/");
                handleLink();
              }}
            >
              Create node
            </Button> */}
            </Box>
            {/* Second Box */}
            <Box
              sx={{
                width: { xs: "100%", md: "58%" },
                bgcolor: "#EAF0F7",
                borderRadius: "5px",
                padding: { xs: "1.5rem 1rem", md: "1.5rem 1.2rem" },
                // minHeight: "calc(100vh - 122px)",
                // minHeight: "402px",
                // maxHeight: "550px",
                marginTop: { xs: ".8rem", md: "0" },
              }}
            >
              <Typography variant="h3" sx={{ mb: "2rem" }}>
                Activity logs
              </Typography>
              {getNode?.map((item, i) => (
                <Box key={i}>
                  <Box sx={MainBoxStyle}>
                    <Typography
                      sx={{
                        color: "#013eb7",
                        fontFamily: "InterSemiBold",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: { xs: "150px", md: "150px" },
                      }}
                    >
                      {
                        item?.nodeDetails?.app_state?.genutil?.gen_txs[0]?.body
                          ?.messages[0]?.description?.moniker
                      }
                    </Typography>
                    <Typography
                      sx={{
                        color: "#013eb7",
                        fontFamily: "InterSemiBold",
                        textOverflow: "ellipsis",
                        display: "flex",
                        alignItems: "center",
                        // overflow: "hidden",
                        // width: { xs: "150px", md: "150px" },
                      }}
                    >
                      <DownloadIcon fontSize="small" />
                      &nbsp; Downloads: {item?.download_attempt}
                    </Typography>
                  </Box>
                  <Box sx={MainBoxStyle}>
                    <Typography variant="h4">Creation date:</Typography>
                    <Typography variant="subtitle2">
                      {/* {format(item?.createdAt)} (
                    {new Date(item?.createdAt).toUTCString()}) */}

                      {dayjs(item?.createdAt)
                        .utc()
                        .format("MMM-DD-YYYY hh:mm:ss A +[UTC]")}
                    </Typography>
                  </Box>
                  <HorizontalLine />
                  {/* <Box sx={MainBoxStyle}>
                  <Typography variant="h4">Updated date:</Typography>
                  <Typography variant="subtitle2">
                    {format(item?.updatedAt)} (
                    {new Date(item?.updatedAt).toUTCString()})
                  </Typography>
                </Box>
                <HorizontalLine /> */}
                </Box>
              ))}

              {/* <Box sx={MainBoxStyle}>
              <Typography variant="h4">Creation date:</Typography>
              <Typography variant="subtitle2">Blockchain Node</Typography>
            </Box> */}
              {/* <HorizontalLine /> */}
            </Box>
          </Box>
        </Card>
      )}
    </React.Fragment>
  );
};

export default Dashboard;
