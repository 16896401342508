import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { useLocation, useParams } from "react-router-dom";

const HeaderName = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          overflow: "hidden",
          marginBottom: ".75rem",
          minHeight: "17px",
        }}
      >
        <Typography
          variant="subtitle6"
          sx={{
            display: "inline-block",
            whiteSpace: " nowrap",
            width: "100%",
            textAlign: "left",
          }}
        >
          {pathname === "/"
            ? "Home"
            : pathname === "/network"
            ? "Create Network"
            : pathname === "/network-details"
            ? "Network Details"
            : pathname === "/node"
            ? "Create Node"
            : pathname === `/manage-node/overview/${id}`
            ? "Node Overview"
            : pathname === "/overview"
            ? "Node Overview"
            : pathname === "/manage-node"
            ? "Manage Nodes"
            : pathname === "/node-settings"
            ? "Node Settings"
            : pathname === "/settings"
            ? "Settings"
            : ""}
        </Typography>
        <Divider
          sx={{
            width: "100%",
            borderColor: "rgba(1, 62, 183, 0.14)",
            margin: ".15rem 0 0 1rem",
          }}
        />
      </Box>
    </React.Fragment>
  );
};

export default HeaderName;
