/* eslint-disable no-sequences */
import { Box, Typography } from "@mui/material";
import React from "react";
import ControlledAccordions from "../../components/accordianFaq";
import { backIcon } from "../../assets/images";
import { Link, useLocation, useNavigate } from "react-router-dom";

const HelpCenter = () => {
  const location = useLocation();
  const Navigate=useNavigate();
  const Back=()=>{
    Navigate(-1)
  }
  return (
    <React.Fragment>
      <Box
        sx={{
          zIndex: "100",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box onClick={Back} sx={{cursor:"pointer"}} data-cy="backbtn">
            <Typography
              sx={{
                fontFamily: "InterMedium",
                fontSize: 14,
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={backIcon}
                alt="backIcon"
                style={{
                  marginRight: 6,
                }}
              />{" "}
              Back
            </Typography>
          </Box>
          <Typography variant="h1" my={1.5}>
            Help Center
          </Typography>
          <Typography></Typography>
        </Box>
        <Typography variant="subtitle1" mt={1} mb={4}>
          Need help with something? Here are our most frequently asked
          questions.
        </Typography>
        <ControlledAccordions />
      </Box>
    </React.Fragment>
  );
};

export default HelpCenter;
