import { Avatar, Box, Button, IconButton } from "@mui/material";
import React from "react";
import Header from "../components/header/Header";
import {
    Link,
    NavLink,
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import Sidebar, { HtmlTooltip } from "../components/sidebar/Sidebar";
import {
    CreataLogo,
    EarthLogo,
    HomeDark,
    avtEmoji,
    drawerOpen,
    fullLogo,
    home,
    humburger,
    infoIcon,
    // humburger,
    mask,
    worldDark,
} from "../assets/images";
// import Drawer from "@mui/material/Drawer";
// import AppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { styled, useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { getActiveStep } from "../redux/test/actions";
import { useEffect } from "react";
import { useState } from "react";
import useDeviceSize from "../hooks/windowResize";
import HeaderInfo from "../components/header/HeaderInfo";
const drawerWidth = 250;

const drawerBox = {
    display: { sm: "block", xs: "none" },
    position: "relative",
    "& .MuiDrawer-paper": {
        background: "rgba(255, 255, 255, 0.56);",
        borderRight: "1px solid transparent",
    },
};
const childBox = {
    width: "100%",
    mt: "90px",
    pb: "24px ",
};
const logoBox = {
    p: "16px 6px",
    display: "flex",
    alignItems: "center",
    height: 80,
    position: "relative",
    justifyContent: "space-between",
    // mr: 4,
    width: "100%",
};
const logoBox1 = {
    display: "flex",
    alignItems: "center",
};

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "column",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));
const buttonWrapper = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mt: 2,
    // width: 10,
    cursor: "pointer",
};
const button = {
    // fontSize: "10px",
    minWidth: "40px !important",
    height: "40px !important",
    padding: "1px",
    // zIndex: 1000,
};
const MainLayout = ({ children }) => {
    const { pathname } = useLocation();
    const wrapper = (open) => {
        return {
            width: open
                ? { xs: `calc(100% - 0px)`, sm: `calc(100% - ${drawerWidth}px)` }
                : { xs: `calc(100% - 0px)`, sm: `calc(100% - 64px)` },
            ml: `${drawerWidth}px`,
            background: "rgba(255, 255, 255, 0.56)",
            boxShadow: "none",
            backdropFilter: "blur(8px)",
        };
    };

    // const { width } = useDeviceSize();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(true);
    const Navigate = useNavigate();
    const location = useLocation();
    const parts = location.pathname.split("/");
    const id = parts[parts.length - 1];
    const { stepperSteps } = useSelector((state) => state.test);
    const { getNode, addNetwork } = useSelector((state) => state.node);
    const { detail } = useSelector((state) => state.auth);
    const [activeRoute, setActiveRoute] = useState("");
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const token = localStorage.getItem("token");

    // useEffect(() => {
    //     if (stepperSteps === 0) {
    //         Navigate("/");
    //     }
    // }, [ stepperSteps]);
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const handleLink = () => {
        dispatch(getActiveStep(0));
    };
    useEffect(() => {
        setActiveRoute(pathname);
        // if (width === 0) {
        //     setOpen(true);
        // } else if (width < 600) {
        //     setOpen(false);
        // } else {
        //     setOpen(true);
        // }
    }, [pathname]);
    let isFirstButtonDisabled = false;
    let isSecondButtonDisabled = false;
    let isNodeOverViewButton = false;
    let isOverViewButton = false;
    if (activeRoute === "/" || activeRoute === "/dashboard") {
        isFirstButtonDisabled = activeRoute !== "/";
        isSecondButtonDisabled = activeRoute !== "/dashboard";
    } else if (activeRoute === `/manage-node/overview/${id}` || activeRoute === "/manage-node") {
        isFirstButtonDisabled = true;
        isSecondButtonDisabled = false;
    } else {
        isSecondButtonDisabled = true;
    }

    const capitalizeFirstLetter = (str) => {
        if (typeof str !== "string" || str.length === 0) {
            return "";
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    return (
        <>
            {/* The custom rapper will not apply on the given pages links */}
            {pathname === "/sign-in" ||
            pathname === "/sign-up" ||
            pathname === "/forget-password" ||
            pathname === "/resetPassword" ||
            pathname === "/email-activate" ||
            pathname === "/not-found" ||
            pathname === "/email-activate" ? (
                <Box>{children}</Box>
            ) : (
                // This layout will be apply on all other screens

                <Box
                    sx={{
                        display: "flex",
                        position: "relative",
                        flexDirection: pathname === "/help-center" ? "column" : "none",
                    }}>
                    <img
                        src={mask}
                        alt="mask"
                        style={{ position: "absolute", width: "100%", top: 0 }}
                    />

                    {/* <CssBaseline /> */}

                    {pathname === "/help-center" ? (
                        <HeaderInfo />
                    ) : (
                        <>
                            <AppBar sx={wrapper(open)} open={open}>
                                <Toolbar>
                                    <Header />
                                </Toolbar>
                            </AppBar>

                            <Drawer sx={drawerBox} variant="permanent" anchor="left" open={open}>
                                <DrawerHeader>
                                    {open ? (
                                        <>
                                            <Box
                                                sx={logoBox}
                                                onClick={handleLink}
                                                data-cy="Drawer1">
                                                <Box sx={logoBox1}>
                                                    <Link to="/">
                                                        <img
                                                            src={fullLogo}
                                                            alt="creatachain logo"
                                                            style={{
                                                                height: "2.5rem",
                                                            }}
                                                        />
                                                    </Link>
                                                    {/* <Typography
                                                        variant="h5"
                                                        sx={{
                                                            // display: { xs: "none", sm: "block" },
                                                            marginLeft: ".6rem",
                                                        }}>
                                                        <Link
                                                            to="/"
                                                            style={{
                                                                fontSize: "1rem",
                                                            }}>
                                                            CREATACHAIN
                                                        </Link>
                                                    </Typography> */}
                                                </Box>
                                                <Box
                                                    onClick={handleDrawerClose}
                                                    data-cy="Drawer2"
                                                    sx={{
                                                        cursor: "pointer",
                                                        // marginTop: -2,
                                                    }}>
                                                    <IconButton>
                                                        <img
                                                            src={humburger}
                                                            alt="humburger"
                                                            style={{ height: 12 }}
                                                        />
                                                    </IconButton>
                                                </Box>
                                            </Box>

                                            <Divider />
                                            <Sidebar handleDrawerClose={handleDrawerClose} />
                                        </>
                                    ) : theme.direction === "rtl" ? (
                                        ""
                                    ) : (
                                        <>
                                            <Box
                                                onClick={handleDrawerOpen}
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                }}
                                                data-cy="Drawer3">
                                                <Link to="/" onClick={handleLink} data-cy="Drawer3">
                                                    <img
                                                        src={CreataLogo}
                                                        alt="creatachain logo"
                                                        style={{
                                                            height: "3rem",
                                                            marginTop: "18px",
                                                        }}
                                                    />
                                                </Link>
                                                <img
                                                    src={drawerOpen}
                                                    alt="humburger"
                                                    style={{
                                                        height: "1.05rem",
                                                        marginTop: "14px",
                                                        marginBottom: "18px",
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </Box>
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <Typography
                                                            variant="subtitle2"
                                                            sx={{ wordBreak: "break-all" }}>
                                                            {detail?.name}
                                                        </Typography>
                                                        <Typography variant="subtitle5">
                                                            {detail?.email}
                                                        </Typography>
                                                    </React.Fragment>
                                                }>
                                                <Avatar
                                                    sx={{
                                                        width: 38,
                                                        height: 38,
                                                        background: "#0575E6",
                                                        cursor: "pointer",
                                                    }}>
                                                    {" "}
                                                    {capitalizeFirstLetter(
                                                        detail?.name?.charAt(0),
                                                    ) || "A"}
                                                </Avatar>
                                            </HtmlTooltip>

                                            {/* Button of create node code */}
                                            <Box
                                                sx={buttonWrapper}
                                                onClick={() => setActiveRoute("/")}
                                                data-cy="mainLayout">
                                                <NavLink
                                                    activeclassname={
                                                        isFirstButtonDisabled ? "active" : ""
                                                    }
                                                    to="/"
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }}
                                                    onClick={() => setActiveRoute("/")}
                                                    data-cy="mainLayout2">
                                                    <Button
                                                        variant="contained"
                                                        data-cy="home"
                                                        sx={button}
                                                        disabled={
                                                            isFirstButtonDisabled ||
                                                            isNodeOverViewButton ||
                                                            isOverViewButton
                                                        }
                                                        onClick={() => setActiveRoute("/")}>
                                                        <img
                                                            src={
                                                                !isFirstButtonDisabled
                                                                    ? home
                                                                    : HomeDark
                                                            }
                                                            alt="home"
                                                            // style={{ height: "18px", marginRight: 10 }}
                                                        />
                                                    </Button>
                                                </NavLink>
                                            </Box>
                                            {/* This button will only shown whe node is created  */}
                                            <Box>
                                                {getNode?.length > 0 ? (
                                                    <Box sx={buttonWrapper}>
                                                        <NavLink
                                                            activeclassname={
                                                                isSecondButtonDisabled
                                                                    ? "active"
                                                                    : ""
                                                            }
                                                            to="/manage-node"
                                                            style={{
                                                                width: "100%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                            onClick={() =>
                                                                setActiveRoute("/manage-node")
                                                            }
                                                            data-cy="EarthLogo">
                                                            <Button
                                                                disabled={
                                                                    isSecondButtonDisabled ||
                                                                    isNodeOverViewButton ||
                                                                    isOverViewButton
                                                                }
                                                                variant="contained"
                                                                data-cy="EarthLogo"
                                                                onClick={() =>
                                                                    setActiveRoute("/manage-node")
                                                                }
                                                                sx={button}>
                                                                <img
                                                                    src={
                                                                        !isSecondButtonDisabled
                                                                            ? EarthLogo
                                                                            : worldDark
                                                                    }
                                                                    alt="Earth Logo"
                                                                    // style={{
                                                                    //     height: "18px",
                                                                    //     marginRight: 10,
                                                                    // }}
                                                                />
                                                            </Button>
                                                        </NavLink>
                                                    </Box>
                                                ) : null}
                                            </Box>
                                            <Box mt={2}>
                                                <NavLink
                                                    // className={isFirstButtonDisabled && ""}
                                                    to="/help-center"
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }}
                                                    onClick={() => setActiveRoute("/help-center")}
                                                    data-cy="help">
                                                    <Button
                                                        disabled
                                                        variant="contained"
                                                        data-cy="helpCenter"
                                                        onClick={() =>
                                                            setActiveRoute("/help-center")
                                                        }
                                                        sx={button}>
                                                        <img src={infoIcon} alt="infoIcon" />
                                                    </Button>
                                                </NavLink>
                                            </Box>
                                        </>
                                    )}
                                </DrawerHeader>
                            </Drawer>
                        </>
                    )}

                    <Box
                        sx={{
                            width: "100%",
                            mt: pathname === "/help-center" ? "15px" : "90px",
                            pb: "24px ",
                        }}>
                        <Toolbar sx={{ flexDirection: "column" }}>{children}</Toolbar>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default MainLayout;
