import React, { useEffect } from "react";
import { AuthBackground } from "../../components/auth";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { BlueLeftArrow } from "../../assets/images";
import { useLocation, useNavigate } from "react-router-dom";
import { getActivationAccount, getResendEmail } from "../../redux/actions";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { emailActive } from "../../utils/schema";

const MainBox = {
  zIndex: "100",
  maxWidth: "32rem",
  width: "100%",
};
const BackBoxStyle = {
  display: { xs: "flex", md: "none" },
  position: "absolute",
  left: "1.5rem",
  top: "1.5rem ",
  cursor: "pointer",
};

const Activate = () => {
  const Navigation = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { activation, activationLoading } = useSelector((state) => state.auth);

  const initialValues = {
    email: "",
  };

  const onSubmit = (values) => {
    dispatch(getResendEmail(values));
  };

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema: emailActive,
  });

  const params = queryString.parse(location.search);
  useEffect(() => {
    if (params?.token) {
      dispatch(getActivationAccount(params?.token));
    }
  }, [dispatch, params?.token]);
  return (
    <React.Fragment>
      <AuthBackground newHeight="100vh">
        <Box sx={MainBox}>
          <Box onClick={() => Navigation("/sign-in")} sx={BackBoxStyle} data-cy="Activate">
            {" "}
            <img src={BlueLeftArrow} alt=" Left Arrow" />
            <Typography
              variant="subtitle4"
              sx={{ fontSize: "20px", marginLeft: ".5rem", color: "#013EB7" }}
            >
              Back
            </Typography>
          </Box>
          <Typography
            variant="h1"
            color="#000"
            sx={{ p: "5rem 0 3rem 0", textAlign: "center" }}
          >
            Email Activation
          </Typography>
          <Box sx={{ textAlign: "center", mb: "2rem" }}>
            {activationLoading ? (
              <Typography>Loading ...</Typography>
            ) : activation?.status === "success" ? (
              "You have successfully verified your email to continue"
            ) : (
              "Your link has been expired retry again"
            )}
          </Box>
          {activation?.status === "success" ? (
            <>
              <Box
                sx={{
                  padding: "0 2rem",
                }}
              >
                <Button
                  disabled={activationLoading ? true : false}
                  variant="contained"
                  data-cy="SignIn"
                  sx={{ borderRadius: "5px", width: "100%", mt: 5.75 }}
                  onClick={() => Navigation("/sign-in")}
                >
                  {!activationLoading ? (
                    " Sign In"
                  ) : (
                    <CircularProgress size="1.7rem" />
                  )}
                </Button>
              </Box>
            </>
          ) : (
            <Box>
              <form
                onSubmit={formik.handleSubmit}
                style={{
                  padding: "0 2rem",
                }}
              >
                <Box sx={{ width: "100%", height: "82px", mt: 1 }}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    type="email"
                    data-cy="input_email"
                    name="email"
                    {...formik.getFieldProps("email")}
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    sx={{ mt: 3 }}
                  />
                </Box>
                <Button
                  disabled={activationLoading ? true : false}
                  type="submit"
                  sx={{ borderRadius: "5px", width: "100%", mt: 5.75 }}
                  variant="contained"
                  data-cy="Resend"
                >
                  {!activationLoading ? (
                    " Resend"
                  ) : (
                    <CircularProgress size="1.7rem" />
                  )}
                </Button>
              </form>
            </Box>
          )}
        </Box>
      </AuthBackground>
    </React.Fragment>
  );
};

export default Activate;
