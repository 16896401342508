import { Box } from "@mui/material";
import React from "react";

const MainBoxStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  my: "1rem",
};
const boxStyle = {
  height: "4px",
  width: "100%",
  maxWidth: "500px",
  background:
    "radial-gradient(50% 50% at 50% 50%, rgba(1, 62, 183, 0.5) 0%, rgba(255, 255, 255, 0) 100%)",
};
function HorizontalLine() {
  return (
    // Horixontal line code 
    <Box sx={MainBoxStyle}>
      <Box sx={boxStyle} />
    </Box>
  );
}

export default HorizontalLine;
