/* eslint-disable no-sequences */
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { AuthBackground } from "../../components/auth";
import { SignNewPassword } from "../../utils/schema";
import { BlueLeftArrow, Visibility, VisibilityOff } from "../../assets/images";
import useDeviceSize from "../../hooks/windowResize";
import "./auth.css";
import { getReset } from "../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
const iBtn = {
  cursor: "pointer",
  boxShadow: "none",
};

const MainBoxStyle = {
  display: { xs: "flex", md: "none" },
  position: "absolute",
  left: "1.5rem",
  top: "1.5rem ",
  cursor: "pointer",
};
const BackStyle = { fontSize: "20px", marginLeft: ".5rem", color: "#013EB7" };
const TextFieldBoxSyle = { width: "100%", height: "82px", mt: 1 };

const ResetPassword = () => {
  const Navigation = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { resetLoading } = useSelector((state) => state.auth);
  const { height } = useDeviceSize();

  let params = queryString.parse(location.search);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const onSubmit = (values) => {
    const data = {
      newPassword: values.password,
      confirmPassword: values.confirmPassword,
    };
    dispatch(getReset(data, params?.token, Navigation));
  };
  // formik
  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema: SignNewPassword,
  });
  // removing the blank space from the input values
  const sanitizedPssword = formik.values.password.replace(/\s/g, "");
  const sanitizedConfPassword = formik.values.confirmPassword.replace(
    /\s/g,
    ""
  );
  formik.values.password = sanitizedPssword;
  formik.values.confirmPassword = sanitizedConfPassword;

  return (
    <React.Fragment>
      <AuthBackground newHeight={height > 680 ? "100vh" : "100%"}>
        <Box sx={{ zIndex: "100", maxWidth: "32rem", width: "100%" }}>
          {/* End and start of form boc , */}
          <Box onClick={() => Navigation("/sign-in")} sx={MainBoxStyle} data-cy="reset-box">
            {" "}
            <img src={BlueLeftArrow} alt=" Left Arrow" />
            <Typography variant="subtitle4" sx={BackStyle}>
              Back
            </Typography>
          </Box>
          <Typography
            variant="h1"
            textAlign="center"
            color="#000"
            sx={{ p: "5rem 0 3rem 0" }}
          >
            RESET PASSWORD
          </Typography>
          <form
            onSubmit={formik.handleSubmit}
            style={{
              padding: "0 2rem",
            }}
          >
            {/* Password input field */}
            <Box sx={TextFieldBoxSyle}>
              <TextField
                label=" New password "
                variant="outlined"
                value={sanitizedPssword}
                fullWidth
                name="password"
                data-cy="input-password2"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        sx={iBtn}
                        data-cy="reset-IconButton"
                      >
                        {showPassword ? (
                          <img src={Visibility} width="20px" alt="visibleon" />
                        ) : (
                          <img
                            src={VisibilityOff}
                            width="20px"
                            alt="visibleoff"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...formik.getFieldProps("password")}
                error={Boolean(
                  formik.touched.password && formik.errors.password
                )}
                helperText={formik.touched.password && formik.errors.password}
                sx={{ mt: 2 }}
              />
            </Box>
            {/* Confirm password input field */}
            <Box sx={TextFieldBoxSyle}>
              <TextField
                label="Confirm password "
                variant="outlined"
                value={sanitizedConfPassword}
                fullWidth
                name="confirmPassword"
                data-cy="input-confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        data-cy="reset-IconButton2"
                        edge="end"
                        sx={iBtn}
                      >
                        {showConfirmPassword ? (
                          <img src={Visibility} width="20px" alt="visibleon" />
                        ) : (
                          <img
                            src={VisibilityOff}
                            width="20px"
                            alt="visibleoff"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...formik.getFieldProps("confirmPassword")}
                error={Boolean(
                  formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                )}
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                sx={{ mt: 2 }}
              />
            </Box>

            {/* Disbale the button on basis of loading state and adding spinner */}
            <Button
              type="submit"
              data-cy="Confirm"
              disabled={resetLoading ? true : false}
              sx={{ borderRadius: "5px", width: "100%", mt: 2 }}
              variant="contained"
            >
              {!resetLoading ? " Confirm" : <CircularProgress size="1.7rem" />}
            </Button>
          </form>
        </Box>
      </AuthBackground>
    </React.Fragment>
  );
};

export default ResetPassword;
