// import { isDisabled } from "@testing-library/user-event/dist/utils";
import { palette } from "../palette";
export const MuiTextField = {
  styleOverrides: {
    root: {
      "&  .css-1ajg3sn": {
        color: " #9CA3AF",
        fontFamily: "InterRegular",
      },
      "&  .css-xv7msg ": {
        color: palette.primary.main,
        fontFamily: "InterRegular",
      },
      "&  .css-m94p6h-MuiFormLabel-root-MuiInputLabel-root": {
        color: " #9CA3AF",
        fontFamily: "InterRegular",
      },
      "&  .css-1thh7x2-MuiFormLabel-root-MuiInputLabel-root ": {
        color: palette.primary.main,
        fontFamily: "InterRegular",
      },
      "& label.Mui-focused": {
        color: palette.primary.main,
        fontFamily: "InterRegular",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "red",
      },
      "& .MuiOutlinedInput-root": {
        textAlign: "left",
        borderRadius: "5px",
        color: palette.text.primary,
        fontFamily: "InterRegular",
        "& fieldset": {
          borderColor: "#D1D5DB",
        },
        "&:hover fieldset": {
          borderColor: palette.primary.main,
        },
        "&.Mui-focused fieldset": {
          border: "1px solid #013EB7",
        },
        "& .MuiSvgIcon-root": {
          color: palette.primary.main,
          // fontSize: 50,
        },
       
          "& .MuiOutlinedInput-input": {
            fontFamily: "InterRegular",
            padding: "10px 18px 10px 18px",
            font:"icon",
            height:"2rem"
          },
      },
    },
  },
};
