import React, { useState } from "react";
import Card from "../../components/card/Card";
import NetworkSummary from "./components/networkSummary/NetworkSummary";
import HomeRegion from "./components/homeRegion/HomeRegion";

const ViewNetwork = () => {
    const [screen, setScreen] = useState(0);

    return (
        <React.Fragment>
            {/* this will show the two screen conditionaly, not by router */}
            <Card>
                {/* {screen === 0 ? (
                    <NetworkSummary setScreen={setScreen} />
                )
                 : screen === 1 ? (
                    <HomeRegion setScreen={setScreen} />
                )
                 : (
                    ""
                )} */}
                <NetworkSummary  />
            </Card>
        </React.Fragment>
    );
};

export default ViewNetwork;
