import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ExpandMoreIcon,
  PlusICon,
  Visibility,
  VisibilityOff,
} from "../../../../assets/images";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { color } from "@mui/system";
import { paste } from "@testing-library/user-event/dist/paste";
import { useNavigate } from "react-router-dom";
import { chainId, getActiveStep } from "../../../../redux/actions";
const bip39 = require("bip39");
const AccordianStyle = {
  // bgcolor: "#EAF0F7",
  border: "none",
  // padding: { xs: "1rem 0", sm: "0" },
  borderRadius: "5px",
};
const MapBoxStyle = {
  bgcolor: "#FFFFFF",
  padding: "1rem 1rem .1rem",
  borderRadius: "12px",
  mt: "1rem",
  minHeight: "480px",
};
const AddBtnStyle = {
  display: "flex",
  m: "1.7rem 0 .2rem 0",
  maxWidth: "10rem",
};
const TypoStyle = {
  color: "#013EB7",
  ml: ".5rem",
  cursor: "pointer",
};
const closeButton = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  height: 35,
  width: 35,
  mt: 1,
  cursor: "pointer",

  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04);",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    height: 35,
    width: 35,
  },
};

const iBtn = {
  cursor: "pointer",
  boxShadow: "none",
};
const BtnBoxStyle = {
  display: "flex",
  justifyContent: "flex-end",
  mt: "1.5rem",
};

const Accordian = (props) => {
  const {
    formik,
    handleRemoveField,
    handleInputChange,
    state,
    // handleAddField,
    inputValue,
    // handleGenerateField,
    hasError,
    hasAddError,
    envValue,
    setScreen,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isChain } = useSelector((state) => state.node);

  const [showPassword, setShowPassword] = useState(false);
  const [response, setResponse] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleBackScreen = () => {
    dispatch(getActiveStep(1));
    navigate("/network-details");
  };

  const handleNextScreen = async () => {
    const checkResponse = await dispatch(
      chainId(formik?.values.idvalue, navigate)
    );

    setResponse(checkResponse);
    if (!checkResponse) {
      setScreen(1);
      dispatch(getActiveStep(3));
    }
  };

  // const chainId = formik?.values?.idvalue?.replace(/\s/g, "");
  // formik?.values.idvalue = chainId;

  // formik.values.inputFields[index]?.accountName
  useEffect(() => {
    if (formik?.values?.idvalue) {
      setResponse(false);
    }
  }, [formik?.values?.idvalue]);

  return (
    <React.Fragment>
      <Typography
        variant="h1"
        sx={{ m: { xs: "1rem 0", sm: " .3rem 0rem 1.8rem" } }}
      >
        Node Settings
      </Typography>
      <Typography variant="subtitle2" mb={3}>
        These options cannot be changed after the network is created.
      </Typography>
      {/* <Accordion sx={AccordianStyle} disableGutters> */}
      {/* <AccordionSummary
                    sx={{ maxHeight: "40px" }}
                    expandIcon={<img src={ExpandMoreIcon} alt="dropdown logo" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography variant="subtitle2">
                        Customize your Chain ID, Block period and pre-funded accounts
                    </Typography>
                </AccordionSummary> */}
      <Box sx={AccordianStyle}>
        <AccordionDetails sx={{ p: 0 }}>
          <Typography variant="h3" lineHeight="15px" my={2}>
            Chain ID
          </Typography>
          <Typography variant="subtitle2">
            By giving your chain its own unique ID or choose your own
            alphanumeric ID e.g. (
            {state?.type === "catena"
              ? "test_9000-1"
              : "test-9000-1 or test_9000-1"}
            )
          </Typography>
          {/* ID text field code */}
          <Box sx={{ width: "100%", height: "82px", mt: 2.5, mb: 1.5 }}>
            <TextField
              label="Chain ID"
              variant="outlined"
              value={formik?.values.idvalue || ""}
              fullWidth
              id="outlined-basic"
              name="idvalue"
              type="text"
              data-cy=" acoordian-input-idvalue"
              {...formik.getFieldProps("idvalue")}
              error={Boolean(
                response || (formik.touched.idvalue && formik.errors.idvalue)
              )}
              helperText={
                response
                  ? "Chian Id exist"
                  : "" || (formik.touched.idvalue && formik.errors.idvalue)
              }
              sx={{ mt: 1 }}
              InputProps={{
                onChange: (event) => {
                  const { name, value } = event.target;
                  const formattedValue = value.replace(/\s/g, "");
                  formik.setFieldValue(name, formattedValue);
                },
              }}
            />
          </Box>
          {/* <Typography variant="h3" lineHeight="15px" mb={2}>
            Block Period
          </Typography> */}
          {/* <Typography variant="subtitle2">
            Blocks are mined regularly with your chosen consensus algorithm,
            with or without transactions. Transactions will be included in the
            next available block. Lower block times reduce latency, and higher
            block times aid throughput.
          </Typography> */}
          {/* Period textfield code */}
          {/* <Box sx={{ width: "100%", height: "82px", mb: 1 }}></Box> */}

          <Box>
            <Typography variant="h3" lineHeight="15px" mb={2}>
              Password
            </Typography>
            <Typography variant="subtitle2">
              This password will be used to encrypt network configuration files
              and facilitate network creation. Remember, you'll need this
              password to unlock configuration files, manage account unlocking,
              and perform transactions using the CLI.
            </Typography>
            <Box sx={{ width: "100%", height: "82px", mt: 1.5, mb: 1.5 }}>
              <TextField
                label="Password"
                variant="outlined"
                value={formik?.values.password || ""}
                fullWidth
                id="outlined-basic"
                name="password"
                type={showPassword ? "text" : "password"}
                data-cy=" acoordian-input-password"
                {...formik.getFieldProps("password")}
                error={Boolean(
                  formik.touched.password && formik.errors.password
                )}
                helperText={formik.touched.password && formik.errors.password}
                sx={{ mt: 1 }}
                InputProps={{
                  onChange: (event) => {
                    const { name, value } = event.target;
                    const formattedValue = value.replace(/\s/g, "");
                    formik.setFieldValue(name, formattedValue);
                  },
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        sx={iBtn}
                      >
                        {showPassword ? (
                          <img src={Visibility} width="20px" alt="visibleon" />
                        ) : (
                          <img
                            src={VisibilityOff}
                            width="20px"
                            alt="visibleoff"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Box>
      {/* </Accordion> */}
      <Box sx={BtnBoxStyle}>
        <Button
          // onClick={handleBack}
          onClick={handleBackScreen}
          data-cy="Back4"
          sx={{
            borderRadius: "5px",
            maxWidth: "7.2rem",
            width: "100%",
          }}
          variant="text"
        >
          Back
        </Button>

        <Button
          onClick={handleNextScreen}
          data-cy="Finish"
          disabled={
            !formik?.values?.idvalue ||
            !formik?.values?.password ||
            formik.errors.idvalue ||
            formik.errors.password
              ? true
              : false
            //   ? !hasError
            //   : null || loading
            //   ? true
            //   : false

            // !hasError ? true : loading ? true : false
          }
          sx={{
            borderRadius: "5px",
            maxWidth: "7.2rem",
            width: "100%",
            ml: ".5rem",
          }}
          variant="contained"
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default Accordian;
