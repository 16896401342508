import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Card from "../../../../components/card/Card";
import SteppersUI from "../../../../components/newStepper/NewStepper";
import { useFormik } from "formik";
import { MiniConfigValidation } from "../../../../utils/schema";
import toast from "react-hot-toast";
import { palette } from "../../../../theme/palette";
import Accordian from "./Accordian";
import RadioButton from "./RadioButton";
import { useNavigate } from "react-router";
import { getActiveStep } from "../../../../redux/test/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  getAddNetwork,
  getEnvironmentConfig,
} from "../../../../redux/node/actions";
import { useLocation } from "react-router-dom";
const bip39 = require("bip39");
const CardDividerStyle = {
  background:
    " radial-gradient(50% 50% at 50% 50%, rgba(1, 62, 183, 0.31) 0%, rgba(255, 255, 255, 0) 100%)",
  height: 300,
  width: 4,
};
const FlexBox1Style = {
  flex: { xs: "0 0 100%", md: "0 0 48%" },
  display: { xs: "flex", md: "block" },
  justifyContent: "center",
  flexDirection: "column",
};
const MainTypoStyle = {
  mb: "1.3rem",
  textTransform: "none",
  mt: { xs: "1rem", md: "0" },
  lineHeight: { xs: "30px", md: "38px" },
};
const FlexBox2Style = {
  display: { xs: "none", md: "flex" },
};
const FlexBox3Style = {
  flex: { xs: "0 0 100%", md: "0 0 48%" },
  width: "100%",
};
const UpperBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  mt: ".4rem",
};
const BtnBoxStyle = {
  display: "flex",
  justifyContent: "flex-end",
  mt: "1.5rem",
};

const MinimumConfiguration = (props) => {
  const { handleBack } = props;
  const {
    networkName,
    networkMission,
    networkRegion,
    environmentName,
    deployOption,
    provider,
    algorithm,
    envValue,
    nameofNode,
    membership,
    nodeofRegion,
    addNetworkLoading,
  } = useSelector((state) => state.node);
  const [RadioValue, setValue] = useState(provider ? provider : "zenith");
  const [algoValue, setAlgoValue] = useState("POS");
  const [inputValue, setInputFields] = useState([]);
  const [state, setState] = useState("");
  const [statechange, setStatechange] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notify = () =>
    toast.success("Environment is created.", {
      style: {
        padding: "14px",
        color: palette.primary.main,
        animation: "none",
        transition: "none",
      },
      iconTheme: {
        primary: palette.primary.main,
        secondary: "#FFFAEE",
      },
    });
  // Adding the add account fields more than one logic
  const handleAddField = () => {
    setInputFields([
      ...inputValue,
      { address: "", mnemonics: "", balance: "" },
    ]);
  };
  const handleGenerateField = () => {
    const GetMnemonics = bip39.generateMnemonic((12 / 12) * 128);
    setInputFields([
      ...inputValue,
      {
        address: "TestAddress",
        mnemonics: GetMnemonics,
        balance: "TestBalance",
      },
    ]);
  };
  // Handling the onchange of the textfiels and add to the inputfield state
  const handleInputChange = (index, event) => {
    const updatedFields = [...inputValue];
    updatedFields[index][event.target.name] = event.target.value;
    setInputFields(updatedFields);
  };
  // Remove the add account input fields
  const handleRemoveField = (index) => {
    const updatedFields = [...inputValue];
    updatedFields.splice(index, 1);
    const value = formik.values.inputFields;
    value.splice(index, 1);
    setInputFields(updatedFields);
  };
  // Handling Consensus Algorithm value
  const handleAlgoValue = (event) => {
    setAlgoValue(event.target.value);
  };
  // Handling   Provider value
  const handleChange = (event) => {
    const value = event.currentTarget.getAttribute("data-value");
    setValue(value);
    setStatechange(true);
  };
  const handlecatenachange = (event) => {
    const value = event.currentTarget.getAttribute("data-value");
    setValue(value);
    setStatechange(true);
  };
  const handleBlur = () => {
    setStatechange(false);
  };

  const handleBackScreen = () => {
    dispatch(getActiveStep(0));
    navigate("/network");
  };
  const handleNext = () => {
    dispatch(getActiveStep(2));
    const state = { type: RadioValue, change: statechange };
    navigate("/node-settings", { state });
  };

  // Formik initial value
  const initialValues = {
    idvalue: "",
    period: "",
    inputFields: inputValue,
  };

  // Formik handle submit
  const onSubmit = (values) => {
    const apiData = {
      validator: networkName,
      chainId: values?.idvalue,
      accountName: values?.inputFields[0]?.address,
    };
    let data = { Provider: RadioValue, Algorithm: algoValue, value: values };

    try {
      dispatch(getActiveStep(2));
      dispatch(getEnvironmentConfig(data));
      // navigate("/node-overview");
      // notify();
      dispatch(getAddNetwork(apiData, navigate));
    } catch (error) {}
  };
  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema: MiniConfigValidation,
  });

  const { stepperSteps } = useSelector((state) => state.test);
  useEffect(() => {
    if (stepperSteps === -1) {
      navigate("/");
    }
  }, [stepperSteps]);
  return (
    <React.Fragment>
      {/* Main code  starts */}
      <Card>
        <Box
          sx={{
            mt: { xs: "1.8rem", md: ".5rem" },
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
            mb: 4,
          }}
        >
          <Box sx={{ maxWidth: "600px ", width: "100%" }}>
            <SteppersUI />
          </Box>
        </Box>

        <Box
          display="flex"
          sx={{
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
          }}
        >
          {/* First flex box contain title and text */}
          <Box sx={FlexBox1Style}>
            <Typography variant="h1" sx={MainTypoStyle}>
              What is meant by network details?
            </Typography>
            <Box>
              <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
                <span style={{ color: "#013eb7", fontFamily: "InterSemiBold" }}>
                  {" "}
                  Network Providers{" "}
                </span>{" "}
                are entities or organizations that offer access to blockchain
                networks or platforms where users can create, deploy, and
                interact with decentralized applications (dApps).
              </Typography>
              {/* <ul style={{ paddingLeft: 25 }}>
                                <li>
                                    <Typography variant="subtitle1" textAlign="left">
                                        IBFT - 3 user nodes + monitor node
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle1" textAlign="left">
                                        Raft - 2 user nodes + monitor node
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle1" textAlign="left">
                                        POA - 2 user nodes + monitor node
                                    </Typography>
                                </li>
                            </ul> */}
              <br />
              <Typography variant="subtitle1" textAlign="left">
                Creata Chain ecosystem is built upon the
                <span style={{ color: "#013eb7", fontFamily: "InterSemiBold" }}>
                  {" "}
                  Augusteum Core Consensus
                </span>
                , a hybrid consensus method which is a combination of Delegated
                Proof of Stake and Practical Byzantine fault tolerance.
                Augusteum Consensus Core gives token holders the ability to vote
                for "delegates" via a reputation-based system, enabling
                transparent and inclusive consensus.
              </Typography>
              <br />
              <Typography variant="subtitle1" textAlign="left">
                <span style={{ color: "#013eb7", fontFamily: "InterSemiBold" }}>
                  Zenith Mainnet
                </span>{" "}
              </Typography>

              <Typography variant="subtitle1" textAlign="left">
                Built on the sound foundation of the Creata Chain network,
                Zenith, the non-CVM chain symbolizes stability fostering diverse
                decentralized applications with its proven resilience.
              </Typography>
              <br />
              <Typography variant="subtitle1" textAlign="left">
                <span style={{ color: "#013eb7", fontFamily: "InterSemiBold" }}>
                  Catena Mainnet
                </span>
              </Typography>

              <Typography variant="subtitle1" textAlign="left">
                Catena(CIP-20) harnesses CVM potential offering unrivaled
                scalability, robust security, and enhanced features to redefine
                the norm in the blockchain realm.
              </Typography>
            </Box>
          </Box>
          {/* Second flex box conatin the divider line  */}
          <Box sx={FlexBox2Style}>
            {" "}
            <Box sx={CardDividerStyle} />
          </Box>
          {/* Thirst Flex box code */}
          <Box sx={FlexBox3Style}>
            <Box>
              <Box sx={UpperBoxStyle}>
                <Typography
                  variant="h1"
                  sx={{ m: { xs: "1rem 0", sm: " 0 0rem 1rem" } }}
                >
                  Network Details
                </Typography>
                {/* <Typography
                  variant="subtitle2"
                  sx={{
                    fontStyle: "italic",
                    whiteSpace: "nowrap",
                    pl: ".4rem",
                  }}
                >
                  Step 2/2
                </Typography> */}
              </Box>
              <Typography variant="subtitle2" my={1}>
                Choose your provider and associated algorithm.
              </Typography>
              <Typography variant="h3" lineHeight="15px" mt={2}>
                Provider
              </Typography>
              {/* Provider and Consensus Algorithm code in this child component */}
              <RadioButton
                handleBlur={handleBlur}
                RadioValue={RadioValue}
                handleChange={handleChange}
                handlecatenachange={handlecatenachange}
                algoValue={algoValue}
                handleAlgoValue={handleAlgoValue}
              />
              <Box sx={BtnBoxStyle}>
                <Button
                  // onClick={handleBack}
                  onClick={handleBackScreen}
                  data-cy="minConfig1"
                  sx={{
                    borderRadius: "5px",
                    maxWidth: "7.2rem",
                    width: "100%",
                  }}
                  variant="text"
                >
                  Back
                </Button>
                <Button
                  onClick={handleNext}
                  disabled={addNetworkLoading ? true : false}
                  sx={{
                    borderRadius: "5px",
                    maxWidth: "7.2rem",
                    width: "100%",
                    ml: ".5rem",
                  }}
                  variant="contained"
                  data-cy="Next3"
                >
                  {!addNetworkLoading ? (
                    " Next"
                  ) : (
                    <CircularProgress size="1.7rem" />
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default MinimumConfiguration;
