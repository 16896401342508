import * as actionTypes from "./actionTypes";

const initialState = {
  login: null,
  loginLoading: false,
  signUp: null,
  signUpLoading: false,
  activation: null,
  activationLoading: false,
  reset: null,
  resetLoading: false,
  forget: null,
  forgetLoading: false,
  detail: null,
  detailLoading: false,
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOGIN: {
      return {
        ...state,
        login: action.payload,
      };
    }
    case actionTypes.SET_LOGIN_LOADING:
      return { ...state, loginLoading: action.payload };

    case actionTypes.SET_SIGNUP: {
      return {
        ...state,
        signUp: action.payload,
      };
    }
    case actionTypes.SET_SIGNUP_LOADING:
      return { ...state, signUpLoading: action.payload };
    case actionTypes.SET_ACTIVATION_ACCOUNT: {
      return {
        ...state,
        activation: action.payload,
      };
    }
    case actionTypes.SET_ACTIVATION_ACCOUNT_LOADING:
      return { ...state, activationLoading: action.payload };

    case actionTypes.SET_RESET: {
      return {
        ...state,
        reset: action.payload,
      };
    }
    case actionTypes.SET_RESET_LOADING:
      return { ...state, resetLoading: action.payload };

    case actionTypes.SET_FORGET: {
      return {
        ...state,
        forget: action.payload,
      };
    }
    case actionTypes.SET_FORGET_LOADING:
      return { ...state, forgetLoading: action.payload };
    case actionTypes.SET_DETAIL: {
      return {
        ...state,
        detail: action.payload,
      };
    }
    case actionTypes.SET_DETAIL_LOADING:
      return { ...state, detailLoading: action.payload };

    default: {
      return state;
    }
  }
};

export default authReducer;
