import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import SteppersUI from "../../../../components/newStepper/NewStepper";
import { NodeValidation } from "../../../../utils/schema";
import { getActiveStep } from "../../../../redux/test/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getNodeSummery } from "../../../../redux/node/actions";

const verticalLine = {
  background:
    "radial-gradient(50% 50% at 50% 50%, rgba(1, 62, 183, 0.31) 0%, rgba(255, 255, 255, 0) 100%)",
  height: "400px",
  width: 2,
  display: { xs: "none", sm: "none", md: "block" },
};
const buttonBox = {
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  mt: 4,
};
const button = {
  width: 120,
  borderRadius: "5px",
};
const NodeSummary = ({ setScreen }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    newName: "",
    selectRegion: "",
    membership: "",
  };
  const onSubmit = (values) => {
    setLoading(true);

    try {
      dispatch(getNodeSummery(values));
      setLoading(false);
      setScreen(1);
    } catch (error) {
      setLoading(false);
    }
  };
  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema: NodeValidation,
  });

  const handleBack = () => {
    dispatch(getActiveStep(0));
    navigate("/");
  };
  const NetworkName = formik.values.newName.replace(/^\s+/g, "");
  formik.values.newName = NetworkName;

  return (
    <React.Fragment>
      <Box>
        <Box
          sx={{
            mt: "2.5rem",
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Box sx={{ maxWidth: "600px ", width: "100%" }}>
            <SteppersUI />
          </Box>
        </Box>
        {/* Title of the page */}
        <Typography
          variant="h1"
          mb={3}
          sx={{ m: { xs: "1rem 0", sm: " 0 0rem 1rem" } }}
        >
          Node Summary
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "100%", md: "48%" } }}>
            <Typography variant="subtitle1" textAlign="left">
              Whether you're a pioneer helping make the initial technology
              choice, or part of an established business network ready to
              accelerate the next phase of business value - at Kaleido we have
              what you need:
            </Typography>
            <ul>
              <li>
                <Typography variant="subtitle1" textAlign="left">
                  PoC: Environments ready to run in minutes
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" textAlign="left">
                  Pilot: Secure decentralized transactions at scale
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" textAlign="left">
                  Production: Enterprise grade infra, key management, hybrid
                  networking, ISO Certification, and SLAs
                </Typography>
              </li>
            </ul>
          </Box>
          <Box sx={verticalLine} />
          <Box
            sx={{
              width: { xs: "100%", sm: "100%", md: "48%" },
              mt: { xs: 3, md: 0 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: "1rem",
              }}
            >
              <Typography variant="h3">Set Up Your Node</Typography>
              {/* <Typography variant="subtitle2" sx={{ fontStyle: "italic" }}>
                Step 1/3
              </Typography> */}
            </Box>
            <Typography variant="subtitle2" mb={3}>
              Provision a node to take part in this environment.
            </Typography>

            <form
              onSubmit={formik.handleSubmit}
              style={{
                width: "100%",
              }}
            >
              {/* Select input field */}
              <Box
                sx={{ width: "100%", maxHeight: "80px", height: "100%", mt: 1 }}
              >
                <TextField
                  fullWidth
                  select
                  name="membership"
                  label="Select membership"
                  value={formik.values.membership}
                  data-cy=" nodeSummary-input"
                  {...formik.getFieldProps("membership")}
                  // value={formik?.values?.selectRegion}
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  sx={{
                    "& .MuiSelect-icon": {
                      width: "30px",
                    },
                  }}
                >
                  <MenuItem value="creata">Creata</MenuItem>
                </TextField>
              </Box>
              {/* name input field */}
              <Box sx={{ width: "100%", height: "82px", mt: 1 }}>
                <TextField
                  label="Name of this node"
                  variant="outlined"
                  fullWidth
                  type="newName"
                  name="newName"
                  data-cy=" nodeSummary-input-newName"
                  {...formik.getFieldProps("newName")}
                  error={Boolean(
                    formik.touched.newName && formik.errors.newName
                  )}
                  helperText={formik.touched.newName && formik.errors.newName}
                  sx={{ mt: 2 }}
                />
              </Box>
              {/* Select region field */}
              <TextField
                fullWidth
                select
                name="selectRegion"
                label="Select Region"
                data-cy=" nodeSummary-input-selectRegion"
                value={formik.values.selectRegion || "US-east 1"}
                {...formik.getFieldProps("selectRegion")}
                // value={formik?.values?.selectRegion}
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                sx={{
                  mt: 2,
                  "& .MuiSelect-icon": {
                    width: "30px",
                  },
                }}
              >
                <MenuItem value="US-east 1">US-east 1</MenuItem>
                <MenuItem value="US-east 2">US-east 2</MenuItem>
              </TextField>
              <Box sx={buttonBox}>
                {/* Back Button */}
                <Button variant="text" sx={button} data-cy="NodeSummary-cancel" onClick={handleBack}>
                  cancel
                </Button>
                {/* Next Button */}
                <Button
                  type="submit"
                  data-cy="NodeSummary-Next"
                  // disable the button ,untill get the respose
                  disabled={!formik.values.newName || loading ? true : false}
                  sx={{
                    borderRadius: "5px",
                    maxWidth: "7.2rem",
                    ml: ".5rem",
                  }}
                  variant="contained"
                >
                  {!loading ? " Next" : <CircularProgress size="1.7rem" />}{" "}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default NodeSummary;
