import { Box, SvgIcon, Typography } from "@mui/material";
import React from "react";
import whitebg from "../../assets/images/whitebg.webp";
import { ReactComponent as HalfLeftIcon } from "../../assets/images/halfleft.svg";
import { ReactComponent as HalfDownIcon } from "../../assets/images/halfdown.svg";
import { ReactComponent as BlueCircleIcon } from "../../assets/images/bluecircle.svg";
import { ReactComponent as LightBlueCircleIcon } from "../../assets/images/lightbluecircle.svg";
import { LeftArrow, logo, vector } from "../../assets/images";
import { useMatch, useNavigate } from "react-router-dom";

const BgStyle = {
    flex: { xs: "0 0 100%", md: "0 0 50%" },
    backgroundImage: `url(${whitebg})`,
    position: "relative",
};
// Styling for different circular icons
const CircularStyle1 = {
    fontSize: "2.63rem",
    position: "absolute",
    right: "-.6rem",
    top: "87%",
};
const CircularStyle2 = {
    fontSize: "8.88rem",
    position: "absolute",
    top: "-3rem",
    left: 0,
    right: 0,
    margin: "0 auto",
};
const CircularStyle3 = {
    fontSize: "2rem",
    position: "absolute",
    right: ".5%",
    top: "3%",
};
const CircularStyle4 = {
    fontSize: "1.5rem",
    position: "absolute",
    left: "10%",
    top: "30%",
};
const CircularStyle5 = {
    fontSize: "1.125rem",
    position: "absolute",
    left: "23%",
    top: "11%",
};
const CircularStyle6 = {
    fontSize: "1.125rem",
    position: "absolute",
    right: "7%",
    top: "50%",
};
const FormMainBoxStyle = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
};
const wrapper = {
    background: " linear-gradient(142.29deg, #013EB7 -0.11%, #0597D9 100.59%)",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    position: "relative",
    width: "100%",
};
const bgImg = {
    backgroundImage: `url(${vector})`,
    backgroundRepeat: "no-repeat",
    position: "absolute",
    height: "100vh",
    width: "100%",
    backgroundPosition: "center center",
};
const card = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    mt: "150px",
    ml: "-26px",
    width: "100%",
};

const AuthBackground = (props) => {
    const Navigation = useNavigate();
    const matchSignup = useMatch("/sign-up");
    const active = useMatch("/email-activate");
    const matchResetPassword = useMatch("/forget-password");

    return (
        <React.Fragment>
            <Box>
                {/* <Container disableGutters maxWidth="xl"> */}
                <Box sx={{ display: "flex", height: props.newHeight, overflow: "hidden" }}>
                    <Box
                        sx={{
                            display: { xs: "none", md: "flex" },
                            flex: "0 0 50%",
                        }}>
                        <Box sx={wrapper}>
                            <Box sx={bgImg} />
                            <Box sx={card}>
                                {matchSignup || matchResetPassword || active ? (
                                    <Box
                                        onClick={() => Navigation("/sign-in")}
                                        data-cy="AuthBackground"
                                        sx={{
                                            display: "flex",
                                            position: "absolute",
                                            left: "2.87rem",
                                            top: "4rem ",
                                            cursor: "pointer",
                                        }}>
                                        {" "}
                                        <img src={LeftArrow} alt=" Left Arrow" />
                                        <Typography
                                            variant="subtitle4"
                                            sx={{ fontSize: "20px", marginLeft: ".5rem" }}>
                                            Back
                                        </Typography>
                                    </Box>
                                ) : null}

                                <img src={logo} alt="logo" style={{ marginBottom: 20 }} />
                                <Typography variant="splash1">CREATACHAIN LAUNCH PLATFORM </Typography>
                                <Box
                                    sx={{
                                        width: { sm: "70%", md: "70%", lg: "68%", xl: "58%" },
                                        mt: 2,
                                    }}>
                                    <Typography variant="subtitle4">
                                        Empowering creators, entrepreneurs, and businesses,
                                        CreataChain Launch Platform simplifies the process of
                                        launching custom blockchain projects. With a comprehensive
                                        suite of tools and expert guidance, users can easily create
                                        and deploy their own personalized blockchain networks.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {/* This box contain the input form */}
                    <Box sx={BgStyle}>
                        {/* Circular design code start on bg iamge */}
                        <SvgIcon inheritViewBox component={HalfLeftIcon} sx={CircularStyle1} />
                        <SvgIcon inheritViewBox component={HalfDownIcon} sx={CircularStyle2} />
                        <SvgIcon inheritViewBox component={BlueCircleIcon} sx={CircularStyle3} />
                        <SvgIcon
                            inheritViewBox
                            component={LightBlueCircleIcon}
                            sx={CircularStyle4}
                        />
                        <SvgIcon inheritViewBox component={BlueCircleIcon} sx={CircularStyle5} />
                        <SvgIcon
                            inheritViewBox
                            component={LightBlueCircleIcon}
                            sx={CircularStyle6}
                        />
                        {/* End and start of form boc */}

                        <Box sx={FormMainBoxStyle}>{props.children}</Box>
                    </Box>
                </Box>
                {/* </Container> */}
            </Box>
        </React.Fragment>
    );
};

export default AuthBackground;
