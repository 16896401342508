import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Card from "../../components/card/Card";
import { EarthImage } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveStep,
  getEnvironmentConfig,
  getNetworkSummery,
} from "../../redux/actions";
import { SET_ADD_NETWORK } from "../../redux/node/actionTypes";

const CardDividerStyle = {
  background:
    " radial-gradient(50% 50% at 50% 50%, rgba(1, 62, 183, 0.31) 0%, rgba(255, 255, 255, 0) 100%)",
  height: 350,
  width: 4,
};

const FlexBox1Style = {
  flex: { xs: "0 0 100%", md: "0 0 49%" },
  display: { xs: "flex", md: "block" },
  justifyContent: "center",
  px: { xs: 0, sm: "1rem" },
};
const MainTypoStyle = {
  mt: { xs: "1.5rem", md: "7rem" },
  mb: "1.3rem",
  textAlign: { xs: "center", md: "left" },
  lineHeight: 1.5,
};
const FlexBox2Style = {
  flex: "0 0 2%",
  display: { xs: "none", md: "flex" },
  alignItems: "center",
};
const FlexBox3Style = {
  flex: { xs: "0 0 100%", md: "0 0 49%" },
  marginBottom: "1.5rem",
  px: { xs: "0rem ", md: "2rem", lg: "4rem" },
};
const ButtonBoxStyle = {
  display: "flex",
  justifyContent: "center",
  marginTop: ".6rem",
};

const ViewDashboard = () => {
  const Navigation = useNavigate();
  const dispatch = useDispatch();
  const { stepperSteps } = useSelector((state) => state.test);
  const handleNext = () => {
    Navigation("/network");
    dispatch(getActiveStep(0));
  };

  useEffect(() => {
    dispatch(
      getNetworkSummery({
        networkName: "",
        networkStatement: "",
      })
    );
    let data = {
      Provider: "",
      Algorithm: "",
      value: {
        idvalue: "",
        password: "",
        period: "",
        inputFields: [],
      },
    };
    dispatch(getEnvironmentConfig(data));
    dispatch({
      type: SET_ADD_NETWORK,
      payload: null,
    });
  }, []);

  useEffect(() => {
    if (stepperSteps === -1) {
      Navigation("/");
    }
  }, [stepperSteps]);

  return (
    <React.Fragment>
      {/* Main code  starts */}
      <Card>
        <Box
          display="flex"
          sx={{
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
          }}
        >
          {/* First flex box contain title and text */}
          <Box sx={FlexBox1Style}>
            <Box pr={2}>
              <Typography variant="h1" sx={MainTypoStyle}>
                Welcome to CreataChain Launch Platform
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ textAlign: { xs: "center", md: "left" } }}
              >
                Welcome to the Launch Platform, where innovative
                technology intersects with an unwavering commitment to security
                and transparency. Our platform invites users to embark on a
                transformative journey, granting them the power to create their
                own network and node, empowering them with complete control over
                their digital assets.
              </Typography>
            </Box>
          </Box>
          {/* Second flex box conatin the divider line  */}
          <Box sx={FlexBox2Style}>
            {" "}
            <Box sx={CardDividerStyle} />
          </Box>
          {/* Third Flex box code */}
          <Box sx={FlexBox3Style}>
            <Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  sx={{
                    marginBottom: "1.12rem",
                    marginTop: { xs: "1.12rem", md: "5.6rem" },
                    height: "221px",
                    width: { xs: "150px", sm: "225px" },
                    // maxWidth: "225px",
                    // maxHeight: "221px",
                  }}
                >
                  <img
                    src={EarthImage}
                    alt="Earth connectivity"
                    style={{ height: "auto", width: "100%" }}
                  />
                </Box>
              </Box>

              <Typography variant="subtitle1">
                Unleash your creativity with the Launch Platform as
                we forge a unique network by crafting resources and infusing
                vitality through the addition of a blockchain node. Let’s unlock
                a world of limitless possibilities and innovation.
              </Typography>
              <Box sx={ButtonBoxStyle}>
                <Button
                  onClick={handleNext}
                  data-cy="ViewDashboard-Lets"
                  sx={{
                    borderRadius: "5px",
                    maxWidth: "195px",
                    textTransform: "none",
                  }}
                  variant="contained"
                >
                  Lets start
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default ViewDashboard;
