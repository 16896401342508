import { combineReducers } from "redux";
import testReducer from "./test/reducer";
import nodeReducer from "./node/reducer";
import authReducer from "./auth/reducer";

const rootReducer = combineReducers({
    test: testReducer,
    node: nodeReducer,
    auth: authReducer,
});

export default rootReducer;
