import {
  Avatar,
  Button,
  Box,
  Typography,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  avtEmoji,
  worldDark,
  HomeDark,
  home,
  EarthLogo,
  infoIcon,
} from "../../assets/images";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNods } from "../../redux/node/actions";
import styled from "@emotion/styled";
import shadows from "@mui/material/styles/shadows";
import QuizIcon from "@mui/icons-material/Quiz";

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "#000",

    fontSize: theme.typography.pxToRem(12),
    boxShadow: "05px 05px 80px #00000025",
    padding: "20px 20px",
    minHeight: "70px",
  },
}));

const wrapper = {
  width: "100%",
  height: "calc(100vh - 124px)",
  // background: " rgba(255, 255, 255, 0.56)",
  // border: " 0.356px solid rgba(245, 239, 235, 0.4)",
  zIndex: 1,
  // display: { xs: "none", sm: "block" },
};
const circleBox = {
  display: "flex",
};
const circle1 = {
  width: 9,
  height: 9,
  background: "#FF5A52",
  mr: 1,
  borderRadius: "50%",
};
const circle2 = {
  width: 9,
  height: 9,
  background: "#E0B509",
  mr: 1,
  borderRadius: "50%",
};
const circle3 = {
  width: 9,
  height: 9,
  background: "#53C22B",
  mr: 1,
  borderRadius: "50%",
};
const avtBox = {
  display: "flex",
  mt: 2,
  cursor: "pointer",
};
const infoWrapper = {
  p: 2,
};
const horizontalLine = {
  background:
    " radial-gradient(50% 50% at 50% 50%, #432C2C 0%, rgba(80, 28, 28, 0.2) 100%)",
  opacity: 0.24,
  width: "100%",
  height: 1.5,
};
const buttonWrapper = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  mt: 2,
};
const button = {
  fontSize: "12px",
  width: "90%",
  padding: "18px 12px",
  zIndex: 1000,
  display: "flex",
  justifyContent: "flex-start",
  pl: 2.5,
};

const Sidebar = () => {
  const [activeRoute, setActiveRoute] = useState("");
  const Navigation = useNavigate();


  const dispatch = useDispatch();
  //The buttonActive is use for the stepper and managed via redux
  const { buttonActive } = useSelector((state) => state.test);
  const { getNode, addNetwork } = useSelector((state) => state.node);
  const { detail } = useSelector((state) => state.auth);
  const location = useLocation();
  const parts = location.pathname.split("/");
  const id = parts[parts.length - 1];
  const currentPathname = location.pathname;
  const token = localStorage.getItem("token");

  useEffect(() => {
    setActiveRoute(currentPathname);
  }, [currentPathname]);
  let isFirstButtonDisabled = false;
  let isSecondButtonDisabled = false;
  let isNodeOverViewButton = false;
  let isOverViewButton = false;
  if (activeRoute === "/" || activeRoute === "/dashboard") {
    isFirstButtonDisabled = activeRoute !== "/";
    isSecondButtonDisabled = activeRoute !== "/dashboard";
  } else if (
    activeRoute === `/manage-node/overview/${id}` ||
    activeRoute === "/manage-node"
  ) {
    isFirstButtonDisabled = true;
    isSecondButtonDisabled = false;
  } else {
    isSecondButtonDisabled = true;
  }

  const handleClick = () => {
    setActiveRoute("/manage-node");
    // dispatch(getNods());
  };
  useEffect(() => {
    if (token) {
      dispatch(getNods(Navigation));
    }
  }, []);
  const capitalizeFirstLetter = (str) => {
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return (
    <React.Fragment>
      <Box sx={wrapper}>
        <Box sx={infoWrapper}>
          <Box sx={circleBox}>
            <Box sx={circle1} />
            <Box sx={circle2} />
            <Box sx={circle3} />
          </Box>
          {/* Avayar,name and email portion of code starts */}
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography variant="subtitle2" sx={{ wordBreak: "break-all" }}>
                  {detail?.name}
                </Typography>
                <Typography variant="subtitle5">{detail?.email}</Typography>
              </React.Fragment>
            }
          >
            <Box sx={avtBox}>
              <Avatar sx={{ width: 34, height: 34, background: "#0575E6" }}>
                {" "}
                {capitalizeFirstLetter(detail?.name?.charAt(0)) || "A"}
              </Avatar>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  ml: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: "80%",
                  }}
                >
                  {" "}
                  {detail?.name}
                </Typography>
                <Typography
                  variant="subtitle5"
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: "80%",
                  }}
                >
                  {" "}
                  {detail?.email}
                </Typography>
              </Box>
            </Box>
          </HtmlTooltip>
        </Box>
        <Box sx={horizontalLine} />
        {/* Button of create node code */}
        <Box sx={buttonWrapper}>
          <NavLink
            className={isFirstButtonDisabled ? "active" : ""}
            to="/"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={() => setActiveRoute("/")}
            data-cy="CreateNew"
          >
            <Button
              disabled={
                isFirstButtonDisabled ||
                isNodeOverViewButton ||
                isOverViewButton
              }
              variant="contained"
              data-cy="CreateNew"
              sx={button}
              onClick={() => Navigation("/")}
            >
              <img
                src={!isFirstButtonDisabled ? home : HomeDark}
                alt="home"
                style={{ height: "20px", marginRight: 10 }}
              />
              Create new node
            </Button>
          </NavLink>
        </Box>

        {/* This button will only shown whe node is created  */}
        {getNode?.length > 0 ? (
          <Box sx={buttonWrapper}>
            <NavLink
              className={isSecondButtonDisabled ? "active" : ""}
              to="/manage-node"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={handleClick}
              data-cy="manageNode2"
            >
              <Button
                disabled={
                  isSecondButtonDisabled ||
                  isNodeOverViewButton ||
                  isOverViewButton
                }
                variant="contained"
                data-cy="manageNode2"
                sx={button}
              >
                <img
                  src={!isSecondButtonDisabled ? EarthLogo : worldDark}
                  alt="Earth Logo"
                  style={{
                    height: "20px",
                    marginRight: 10,
                  }}
                />
                Manage node
              </Button>
            </NavLink>
          </Box>
        ) : null}

        <Box sx={buttonWrapper}>
          <NavLink
            className={isFirstButtonDisabled && ""}
            to="/help-center"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={() => setActiveRoute("/help-center")}
            data-cy="help"
          >
            <Button
              disabled
              variant="contained"
              data-cy="CreateNew"
              sx={button}
              onClick={() => Navigation("/help-center")}
            >
              <img
                src={infoIcon}
                alt="infoIcon"
                style={{
                  height: "20px",
                  marginRight: 10,
                }}
              />
              Help Center
            </Button>
          </NavLink>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Sidebar;
