import React, { useEffect } from "react";
import AppRoutes from "./routes";
import { Toaster } from "react-hot-toast";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const App = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem("token");
        navigate("/sign-in");
    };

    const token = localStorage.getItem("token");

    useEffect(() => {
        const checkTokenExpiration = () => {
            if (token) {
                const decodedJwt = jwtDecode(token);
                const expTime = decodedJwt?.exp * 1000;

                if (expTime < Date.now()) {
                    handleLogout();
                } else {
                    setTimeout(checkTokenExpiration, 1000);
                }
            }
        };
        checkTokenExpiration();

        return () => {
            clearTimeout();
        };
    }, [token]);

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            <AppRoutes />
        </>
    );
};

export default App;
