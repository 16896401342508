import { Outlet, Navigate } from "react-router-dom";
// import { useAuth } from "./customHooks";

const useAuth = () => {
    const user = localStorage.getItem("token");
    if (user) {
        return true;
    } else {
        return false;
    }
};
const PrivateRoutes = () => {
    const auth = useAuth();

    return auth ? <Outlet /> : <Navigate to="/sign-in" />;
};

export default PrivateRoutes;
