import React from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import SteppersUI from "../../../../components/newStepper/NewStepper";
import { key } from "../../../../assets/images";
import { palette } from "../../../../theme/palette";
import { useState } from "react";

const verticalLine = {
  background:
    "radial-gradient(50% 50% at 50% 50%, rgba(1, 62, 183, 0.31) 0%, rgba(255, 255, 255, 0) 100%)",
  height: "400px",
  width: 2,
  display: { xs: "none", sm: "none", md: "block" },
};
const buttonBox = {
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  mt: 4,
};
const button = {
  borderRadius: "5px",
  maxWidth: "7.2rem",
  width: "100%",
  ml: ".5rem",
};
const cardWrapper = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: "#fff",
  borderRadius: "8px",
  padding: "12px 16px",
  mb: 1.5,
};
const textBox = {
  display: "flex",
  alignItems: "flex-start",
};

const data = [
  {
    title: "Key-store",
    description: "Enhance the protection of Key Material",
  },
  {
    title: "Key-store",
    description: "Enhance the protection of Key Material",
  },
  {
    title: "Key-store",
    description: "Enhance the protection of Key Material",
  },
  {
    title: "Key-store",
    description: "Enhance the protection of Key Material",
  },
];
const NodeConfig = ({ setScreen }) => {
  const [loading, setLoading] = useState(false);

  const handleBack = () => {
    setScreen(0);
  };
  const handleNext = () => {
    setLoading(true);
    setScreen(2);
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Box>
        <Box
          sx={{
            mt: "2.5rem",
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Box sx={{ maxWidth: "600px ", width: "100%" }}>
            <SteppersUI />
          </Box>
        </Box>
        {/* Title */}
        <Typography
          variant="h1"
          mb={3}
          sx={{ m: { xs: "1rem 0", sm: " 0 0rem 1rem" } }}
        >
          Node Configurations
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "100%", md: "48%" } }}>
            <Typography variant="subtitle1" textAlign="left">
              Whether you're a pioneer helping make the initial technology
              choice, or part of an established business network ready to
              accelerate the next phase of business value - at Kaleido we have
              what you need:
            </Typography>
            <ul>
              <li>
                <Typography variant="subtitle1" textAlign="left">
                  PoC: Environments ready to run in minutes
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" textAlign="left">
                  Pilot: Secure decentralized transactions at scale
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" textAlign="left">
                  Production: Enterprise grade infra, key management, hybrid
                  networking, ISO Certification, and SLAs
                </Typography>
              </li>
            </ul>
          </Box>
          <Box sx={verticalLine} />
          <Box
            sx={{
              width: { xs: "100%", sm: "100%", md: "48%" },
              mt: { xs: 3, md: 0 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: "1rem",
              }}
            >
              <Typography variant="h3"> Configurations</Typography>
              {/* <Typography variant="subtitle2" sx={{ fontStyle: "italic" }}>
                Step 2/3
              </Typography> */}
            </Box>
            <Typography variant="subtitle2" mb={3}>
              You can connect your node to native cloud services. Configurations
              can also be edited after creation.
            </Typography>

            <Box>
              {/* Maping the key-store creation buttons*/}
              {data.map((item, i) => (
                <Box sx={cardWrapper} key={i}>
                  <Box sx={textBox}>
                    <img
                      src={key}
                      alt="key"
                      style={{ height: 16, marginTop: 2, marginRight: 4 }}
                    />
                    <Box>
                      <Typography variant="h4" color={palette.primary.main}>
                        {item.title}
                      </Typography>
                      <Typography variant="subtitle3" lineHeight="15px">
                        {item.description}
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    variant="outlined"
                    data-cy="NodeConfig-Create"
                    sx={{
                      width: 67,
                      color: palette.primary.main,
                      padding: "14px",
                    }}
                  >
                    Create
                  </Button>
                </Box>
              ))}
            </Box>
            {/* Button */}
            <Box sx={buttonBox}>
              <Button variant="text" data-cy="NodeConfig-Back" sx={button} onClick={handleBack}>
                Back
              </Button>
              <Button
                onClick={handleNext}
                data-cy="NodeConfig-Next"
                disabled={loading ? true : false}
                variant="contained"
                sx={button}
              >
                {!loading ? "Next" : <CircularProgress size="1.7rem" />}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default NodeConfig;
