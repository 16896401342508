/* eslint-disable no-sequences */
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import React, {  useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { SignInValidation } from "../../utils/schema";
import { AuthBackground } from "../../components/auth";
import { Visibility, VisibilityOff } from "../../assets/images";
import { getLogin, getLoginLoading } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "react-hot-toast";

// Styling for the "Forgot your password?" link
const forgetStyle = {
    fontStyle: "italic",
    textAlign: "end",
    color: "primary.main",
    ":hover": {
        color: "#04328c",
        textDecoration: "underline",
    },
};
// Styling for the form
const FormStyle = {
    padding: "0 2rem",
};
const iBtn = {
    cursor: "pointer",
    boxShadow: "none",
};
// Styling for the sign-in button
const ButtonStyle = { borderRadius: "5px", width: "100%", mt: 5.75 };
// Styling for the main typography
const MainTypoStyle = { p: "5rem 0 3rem 0", textAlign: "center" };
const SignIn = ({ recaptchaReady }) => {
    const dispatch = useDispatch();
    const Navigation = useNavigate();

    const { executeRecaptcha } = useGoogleReCaptcha();

    const { loginLoading } = useSelector((state) => state.auth);

    const initialValues = {
        email: "",
        password: "",
    };

    // let params = queryString.parse(location.search);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onSubmit = (values) => {
        handleLogin(values);
    };

    const handleLogin = async (values) => {
        if (!executeRecaptcha) {
            toast.error("Execute reCAPTCHA not yet available");
            return;
        }
        dispatch(getLoginLoading(true));

        const token = await executeRecaptcha("login");

        if (!token) {
            toast.error("reCAPTCHA verification failed");
            return;
        }

        // Include the reCAPTCHA token in your login request
        const data = {
            email: values.email,
            password: values.password,
            recaptcha: token,
        };

        // Dispatch your login action with the data
        dispatch(getLogin(data, Navigation));
    };

    const formik = useFormik({
        onSubmit,
        initialValues,
        validationSchema: SignInValidation,
    });
    const sanitizedValue = formik.values.password.replace(/\s/g, "");
    formik.values.password = sanitizedValue;

    return (
        <React.Fragment>
            <AuthBackground newHeight="100vh">
                <Box sx={{ zIndex: "100", maxWidth: "32rem", width: "100%" }}>
                    <Typography variant="h1" color="#000" sx={MainTypoStyle}>
                        WELCOME BACK!
                    </Typography>
                    <form onSubmit={formik.handleSubmit} style={FormStyle}>
                        {/* Email field */}
                        <Box sx={{ width: "100%", height: "82px", mt: 1 }}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="email"
                                value={formik.values.email || ""}
                                type="email"
                                name="email"
                                {...formik.getFieldProps("email")}
                                error={Boolean(formik.touched.email && formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                sx={{ mt: 3 }}
                                // InputLabelProps={{
                                //     shrink: true,
                                // }}
                                placeholder="Email"
                                label={formik?.values?.email?.length > 0 ? "Email" : ""}
                            />
                        </Box>
                        {/* Password field */}
                        <Box sx={{ width: "100%", height: "82px", mt: 1, mb: 3 }}>
                            <TextField
                                variant="outlined"
                                value={sanitizedValue}
                                fullWidth
                                id="outlined-basic"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                // InputLabelProps={{
                                //     shrink: true,
                                // }}

                                placeholder={"Password"}
                                label={formik?.values?.password?.length > 0 ? "Password" : ""}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                sx={iBtn}>
                                                {showPassword ? (
                                                    <img
                                                        src={Visibility}
                                                        width="20px"
                                                        alt="visibleon"
                                                    />
                                                ) : (
                                                    <img
                                                        src={VisibilityOff}
                                                        width="20px"
                                                        alt="visibleoff"
                                                    />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                {...formik.getFieldProps("password")}
                                error={Boolean(formik.touched.password && formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                                sx={{ mt: 3 }}
                            />
                        </Box>
                        {/* "Forgot your password?" link */}
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Link to="/forget-password">
                                <Typography variant="subtitle2" sx={forgetStyle}>
                                    Forgot your password?
                                </Typography>
                            </Link>
                        </Box>
                        {/* Disbale the button on basis of loading state and adding spinner */}
                        <Button
                            type="submit"
                            disabled={loginLoading || !recaptchaReady ? true : false}
                            sx={ButtonStyle}
                            variant="contained">
                            {!loginLoading ? " Sign in" : <CircularProgress size="1.7rem" />}
                        </Button>
                    </form>
                    {/* "Don't have an account?" text and sign-up link */}
                    <Typography variant="subtitle1" sx={{ padding: "2rem", textAlign: "center" }}>
                        Don't have an account? {/* It will show the underline on hover */}
                        <Link
                            to="/sign-up"
                            style={{
                                fontStyle: "italic",
                                color: "#013EB7",
                                whiteSpace: "nowrap",
                            }}
                            onMouseOver={(event) => (
                                (event.target.style.color = "#032d82"),
                                (event.target.style.textDecoration = "underline")
                            )}
                            onMouseOut={(event) => (
                                (event.target.style.color = "#013EB7"),
                                (event.target.style.textDecoration = "none")
                            )}>
                            Sign up
                        </Link>
                    </Typography>{" "}
                </Box>
            </AuthBackground>
        </React.Fragment>
    );
};

export default SignIn;
