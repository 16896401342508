import { useEffect, useState } from "react";

// We are adding three dots in the requird places
export function ThreeDotsinCenter(text) {
  // Here we are adding 12 and 6 letters to show on two screens above 600px and below
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    handleResize(); // Initial check

    window.addEventListener("resize", handleResize); // Listen for resize events

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  let result;
  let firstWords;
  let lastWords;
  let middle;
  // First of all to add three dots text should be more than 20 lettera
  if (text?.length > 20) {
    //   Adding 6 letters after and before the three dots
    if (screenWidth <= 600) {
      firstWords = text.substr(0, 6);
      lastWords = text.substr(-6);
      middle = "...";
      result = `${firstWords}${middle}${lastWords}`;
    } else if (screenWidth <= 1000) {
      //   Adding 12 letters after and before the three dots
      firstWords = text.substr(0, 9);
      lastWords = text.substr(-9);
      middle = "...";
      result = `${firstWords}${middle}${lastWords}`;
    } else {
      //   Adding 12 letters after and before the three dots
      firstWords = text.substr(0, 12);
      lastWords = text.substr(-12);
      middle = "...";
      result = `${firstWords}${middle}${lastWords}`;
    }
  } else {
    // If letter length is less than 20 then return the same text
    result = text;
  }

  return result;
}
