import axios from "axios";
import {
  DOWNLOAD_FILE,
  GET_ENVIRONMENT_CONFIG,
  GET_ENVIRONMENT_SUMMERY,
  GET_NETWORK_REGION,
  GET_NETWORK_SUMMERY,
  GET_NODE_SETTING,
  GET_NODE_SUMMERY,
  SEND_NODE_INFORMATION,
  SET_ADD_NETWORK,
  SET_ADD_NETWORK_LOADING,
  SET_NODE,
  SET_NODE_LOADING,
  GET_MNEMONICS,
  IS_CHAIN,
  CHAIN_LOADING,
} from "./actionTypes";
import { toast } from "react-hot-toast";

export const getNetworkSummery = (item) => ({
  type: GET_NETWORK_SUMMERY,
  payload: item,
});
export const getAllMnemonics = (item) => ({
  type: GET_MNEMONICS,
  payload: item,
});
export const getNetworkRegion = (item) => ({
  type: GET_NETWORK_REGION,
  payload: item,
});
// ENVIRONMENT
export const getEnvironmentSummery = (item) => ({
  type: GET_ENVIRONMENT_SUMMERY,
  payload: item,
});
export const getEnvironmentConfig = (item) => ({
  type: GET_ENVIRONMENT_CONFIG,
  payload: item,
});
// Node
export const getNodeSummery = (item) => ({
  type: GET_NODE_SUMMERY,
  payload: item,
});
export const getNodeSetting = (item) => ({
  type: GET_NODE_SETTING,
  payload: item,
});

export const sendNodeInfomation = (item) => ({
  type: SEND_NODE_INFORMATION,
  payload: item,
});

export const getNodsLoading = (payload) => ({
  type: SET_NODE_LOADING,
  payload: payload,
});

export const getNods = (Navigation) => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(getNodsLoading(true));
  axios
    .get(`${process.env.REACT_APP_CHAIN_LAUNCH_REST_URL}/api/v1/creata`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      // toast.success(res?.data?.status);
      dispatch(getNodsLoading(false));

      dispatch({
        type: SET_NODE,
        payload: res.data.result,
      });
    })
    .catch((error) => {
      dispatch(getNodsLoading(false));
      toast.error(error.response?.data?.message);
      if (
        error?.response?.status === 401 ||
        error.response?.data?.message === "Invalid Token Please Login Again" ||
        error.response?.data?.message ===
          "Your are not Logged In Please logged in First"
      ) {
        localStorage.removeItem("token");
        Navigation("/sign-in");
      }
    });
};

export const getAddNetworkLoading = (payload) => ({
  type: SET_ADD_NETWORK_LOADING,
  payload: payload,
});

const downloadFile = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const getDownload = (dirPath, name) => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(getAddNetworkLoading(true));
  axios
    .get(dirPath, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    })

    .then((res) => {
      // DOWNLOAD_FILE
      dispatch(getAddNetworkLoading(false));
      dispatch({
        type: DOWNLOAD_FILE,
        payload: res.data,
      });
      dispatch(getNods());
      if (res?.data) {
        downloadFile(res?.data, `${name}_configuration.zip`);
      }
    })
    .catch((error) => {
      dispatch(getAddNetworkLoading(false));
      // dispatch(getAddNetworkLoading(false));
      toast.error(error.response?.data?.message);
    });
};

export const getAddNetwork = (data, navigate, setLoading) => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(getAddNetworkLoading(true));
  setLoading(true);
  axios
    .post(
      `${process.env.REACT_APP_CHAIN_LAUNCH_REST_URL}/api/v1/creata`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      // toast.success(res?.data?.message);
      dispatch(getAddNetworkLoading(false));
      setLoading(false);
      dispatch(getNods());
      const dirPath = res.data?.result?.dirPath;
      const name =
        res.data?.result?.nodeDetails?.app_state?.genutil?.gen_txs[0]?.body
          ?.messages[0]?.description?.moniker;
      dispatch(getDownload(dirPath, name));

      navigate(`/node-overview/${res?.data?.result?._id}`);
      // downloadFile(res?.data?.result?.dirPath)

      dispatch({
        type: SET_ADD_NETWORK,
        payload: res.data.result,
      });
      dispatch(
        getNetworkSummery({
          networkName: "",
          networkStatement: "",
        })
      );
      let data = {
        Provider: "",
        Algorithm: "",
        value: {
          idvalue: "",
          password: "",
          period: "",
          inputFields: [],
        },
      };
      dispatch(getEnvironmentConfig(data));
    })
    .catch((error) => {
      dispatch(getAddNetworkLoading(false));
      setLoading(false);
      toast.error(error.response?.data?.message);
      if (
        error?.response?.status === 401 ||
        error.response?.data?.message === "Invalid Token Please Login Again" ||
        error.response?.data?.message ===
          "Your are not Logged In Please logged in First"
      ) {
        localStorage.removeItem("token");
        navigate("/sign-in");
      }
    });
};

//================

// export const chainId = async(nameId) => (dispatch) => {
//   const token = localStorage.getItem("token");
//     axios
//     .get(`${process.env.REACT_APP_CHAIN_LAUNCH_REST_URL }/api/v1/creata/${nameId}`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     })
//     .then((res) => {
//       const dirPath = res.data.result.found;

//       console.log(dirPath, "dirPathdirPath");

//       dispatch({
//         type: IS_CHAIN,
//         payload: dirPath,
//       });
//     })
//     .catch((error) => {
//       console.log(error, "error");
//     });
// };
export const chainId = (nameId, navigate) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("token");
    axios
      .get(
        `${process.env.REACT_APP_CHAIN_LAUNCH_REST_URL}/api/v1/creata/${nameId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        resolve(res.data.result.found); // Resolve the promise with the response
      })
      .catch((error) => {
        reject(error); // Reject the promise with the error
        if (
          error?.response?.status === 401 ||
          error.response?.data?.message ===
            "Invalid Token Please Login Again" ||
          error.response?.data?.message ===
            "Your are not Logged In Please logged in First"
        ) {
          localStorage.removeItem("token");
          navigate("/sign-in");
        }
      });
  });
};

//================getChainById======================

export const getChainByIdLoading = (value) => ({
  type: CHAIN_LOADING,
  payload: value,
});

export const getChainById = (id) => async (dispatch) => {
  dispatch(getChainByIdLoading(true));
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("token");
    axios
      .get(
        `${process.env.REACT_APP_CHAIN_LAUNCH_REST_URL}/api/v1/creata/getChainById/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: SET_ADD_NETWORK,
          payload: res?.data.result,
        });
        dispatch(getChainByIdLoading(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getChainByIdLoading(false));
      });
  });
};
