import { palette } from "./palette";

export function remToPx(value) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }) {
  return {
    "@media (max-width:600px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:900px)": {
      fontSize: pxToRem(md),
    },
    "@media (min-width:1200px)": {
      fontSize: pxToRem(lg),
    },
  };
}

export const typography = {
  h1: {
    fontFamily: "InterBold",
    // fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "27px",
    letterSpacing: "0.02em",
    color: palette.primary.main,
    textTransform: "capitalize",
    fontSize: pxToRem(26),
    ...responsiveFontSizes({ sm: 22, md: 26, lg: 26 }),
  },
  h2: {
    fontFamily: "InterBold",
    // fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "18px",
    letterSpacing: "0.01em",
    textAlign: "center",
    color: palette.primary.main,
    fontSize: pxToRem(22),
    ...responsiveFontSizes({ sm: 18, md: 20, lg: 22 }),
  },
  h3: {
    fontFamily: "InterSemiBold",
    // fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "16px",
    letterSpacing: "0.02em",
    textAlign: "left",
    color: palette.primary.main,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 15, md: 17, lg: 18 }),
  },
  h4: {
    fontFamily: "InterMedium",
    // fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "17px",
    textAlign: "left",
    color: palette.text.primary,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 12, md: 13, lg: 14 }),
  },
  h5: {
    fontFamily: "InterMedium",
    // fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "18px",
    textAlign: "center",
    letterSpacing: ".1rem",
    color: palette.primary.main,
    fontSize: pxToRem(18.24),
    ...responsiveFontSizes({ sm: 14.24, md: 16.24, lg: 18.24 }),
  },
  splash1: {
    fontFamily: "InterBold",
    // fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: "normal",
    textAlign: "center",
    color: palette.primary.light,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 20, md: 22, lg: 24 }),
  },
  subtitle1: {
    fontFamily: "InterRegular",
    // fontSize: "18px",
    // fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "28px",
    letterSpacing: "normal",
    textAlign: "center",
    color: palette.text.primary,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 15, md: 17, lg: 18 }),
  },

  subtitle2: {
    fontFamily: "InterRegular",
    // fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "16px",
    letterSpacing: "normal",
    textAlign: "left",
    color: palette.text.primary,
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ sm: 11, md: 12, lg: 14 }),
  },
  subtitle3: {
    fontFamily: "InterRegular",
    // fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    textAlign: "left",
    color: palette.text.primary,
    fontSize: pxToRem(12),
    ...responsiveFontSizes({ sm: 10, md: 11, lg: 12 }),
  },
  subtitle4: {
    fontFamily: "InterMedium",
    // fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "26px",
    letterSpacing: "normal",
    textAlign: "center",
    color: palette.primary.light,
    display: "flex",
    fontSize: pxToRem(16),
    ...responsiveFontSizes({ sm: 12, md: 14, lg: 16 }),
  },
  subtitle5: {
    fontFamily: "InterRegular",
    // fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "16px",
    letterSpacing: "0.2848px",
    textAlign: "left",
    color: palette.primary.dark,
    opacity: 0.6,
    fontSize: pxToRem(11),
    ...responsiveFontSizes({ sm: 9, md: 11, lg: 11 }),
  },
  subtitle6: {
    fontFamily: "InterMedium",
    fontSize: "14px",
    // fontWeight: 500,
    fontStyle: "italic",
    lineHeight: "15.74px",
    textAlign: "center",
    letterSpacing: ".01rem",
    color: palette.primary.main,
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ sm: 11, md: 13, lg: 14 }),
  },
  subtitle7: {
    fontFamily: "InterRegular",
    // fontWeight: 300,
    fontStyle: "normal",
    lineHeight: "12px",
    letterSpacing: "0.02em",
    textAlign: "left",
    color: palette.primary.main,
    fontSize: pxToRem(12),
    ...responsiveFontSizes({ sm: 9, md: 11, lg: 12 }),
  },
};
