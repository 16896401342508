import React, { useState,useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "@emotion/styled";
import { drop } from "../../assets/images";
import { useLocation } from "react-router-dom";

const ControlledAccordions = () => {
  const location = useLocation();

  const [expanded, setExpanded] = useState("panel1");

  useEffect(() => {
    if (location.search === "?panel4") {
      setExpanded("panel4");
    }
  }, [location.search]);
  

    const handleChange = (panel) => (_, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const renderAccordion = (
        panelId,
        summaryText,
        detailText,
        step,
        step2,
        step3,
        step4,
        step5,
        step6,
        discription,
        link,
    ) => (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
            }}>
            <Accordion
                sx={{
                    background: "transparent",
                    width: { xs: "90%", sm: "80%", md: "70%", lg: "50%" },
                    borderTop: "none",
                    boxShadow: "none",
                    mb: 1,
                    "& .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded": {
                        boxShadow: "2px 4px 9px rgba(0, 0, 0, 0.12)",
                        borderRadius: "5px",
                        minHeight: "48px",
                    },
                    "& .css-9l5vo-MuiCollapse-wrapperInner": {
                        background: "#fff",
                        mt: 1,
                        mb: 1,
                        borderRadius: "5px",
                    },
                    "& .css-1tk9jyj-MuiPaper-root-MuiAccordion-root .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
                        {
                            background: "#fff",
                            boxShadow: "2px 4px 9px rgba(0, 0, 0, 0.12)",
                        },
                    "& .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root ": {
                        background: "#fff",
                        borderRadius: "5px",
                    },
                    "& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded": {
                        margin: "12px 0px",
                    },
                }}
                expanded={expanded === panelId}
                onChange={handleChange(panelId)}>
                <AccordionSummary
                    expandIcon={
                        expanded === panelId ? (
                            <img src={drop} alt="drop" />
                        ) : (
                            <img src={drop} alt="drop" />
                        )
                    }
                    aria-controls={`${panelId}-content`}
                    id={`${panelId}-header`}>
                    <Typography
                        sx={{
                            // width: { xs: "59%", sm: "50%", md: '"55%"' },
                            flexShrink: 0,
                            color: "#101828",
                            lineHeight: 1.8,
                            fontSize: { xs: "12px", sm: "13px", md: "16px", lg: "16px" },
                            fontFamily: "InterRegular",
                            fontWeight: 700,
                            textAlign: "left",
                            display: "contents",
                        }}>
                        {summaryText}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        sx={{
                            color: "#000",
                            fontSize: { xs: "11px", sm: "14px", md: "16px", lg: "16px" },
                            lineHeight: 1.4,
                            // fontFamily: "InterRegular",
                            //   fontWeight: 400,
                            letterSpacing: "-0.04em",
                            mt: 2,
                            fontFamily:
                                detailText === "To create a chain launch, follow these steps :" ||
                                detailText ===
                                    "To start a blockchain node using node configuration files, follow these steps:"
                                    ? "InterMedium"
                                    : "InterRegular",
                        }}>
                        {detailText}
                    </Typography>
                    {step ? (
                        <Box sx={{ background: "#EAF0F7", p: " 20px 10px 30px", mt: 2 }}>
                            <Typography
                                sx={{
                                    color: "#000",
                                    fontSize: { xs: "11px", sm: "13px", md: "14px", lg: "14px" },
                                    lineHeight: 1.4,
                                    fontFamily: "InterRegular",
                                    fontWeight: 400,
                                    letterSpacing: "-0.04em",
                                    mb: 1,
                                    display: "flex",
                                    alignItems: "baseline",
                                    gap: "8px",
                                }}>
                                {step ? (
                                    <>
                                        {" "}
                                        <span
                                            style={{
                                                fontSize: {
                                                    xs: "12px",
                                                    sm: "14px",
                                                    md: "15px",
                                                    lg: "17px",
                                                },
                                            }}>
                                            1.
                                        </span>{" "}
                                        {step}
                                    </>
                                ) : (
                                    ""
                                )}
                            </Typography>
                            <Typography
                                sx={{
                                    color: "#000",
                                    fontSize: { xs: "11px", sm: "13px", md: "14px", lg: "14px" },
                                    lineHeight: 1.4,
                                    fontFamily: "InterRegular",
                                    fontWeight: 400,
                                    letterSpacing: "-0.04em",
                                    mb: 1,
                                    display: "flex",
                                    alignItems: "baseline",
                                    gap: "8px",
                                }}>
                                {step2 ? (
                                    <>
                                        {" "}
                                        <span
                                            style={{
                                                fontSize: {
                                                    xs: "12px",
                                                    sm: "14px",
                                                    md: "15px",
                                                    lg: "16px",
                                                },
                                            }}>
                                            2.
                                        </span>
                                        <span>
                                            {step2}{" "}
                                            {step2 ===
                                            "Set up the environment on your Linux machine. You can follow the tutorial provided at the following link for detailed instructions :" ? (
                                                <a
                                                    href="https://docs.creatachain.com/docs/Tutorials/Environment%20Setup"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style={{
                                                        color: " #013EB7",
                                                        fontFamily: "InterSemiBold",
                                                    }}>
                                                    Environment Setup
                                                </a>
                                            ) : (
                                                ""
                                            )}
                                        </span>
                                    </>
                                ) : (
                                    ""
                                )}
                            </Typography>
                            <Typography
                                sx={{
                                    color: "#000",
                                    fontSize: { xs: "11px", sm: "13px", md: "14px", lg: "14px" },
                                    lineHeight: 1.4,
                                    fontFamily: "InterRegular",
                                    fontWeight: 400,
                                    letterSpacing: "-0.04em",
                                    mb: 1,
                                    display: "flex",
                                    alignItems: "baseline",
                                    gap: "8px",
                                }}>
                                {step3 ? (
                                    <>
                                        <span
                                            style={{
                                                fontSize: {
                                                    xs: "12px",
                                                    sm: "14px",
                                                    md: "15px",
                                                    lg: "16px",
                                                },
                                            }}>
                                            3.
                                        </span>
                                        <span>
                                            {step3}{" "}
                                            {step3 ===
                                            "Once the environment setup is complete, proceed to configure the CreataChain build within the environment. You can find step-by-step instructions in the tutorial available at the following link :" ? (
                                                <a
                                                    href="https://docs.creatachain.com/docs/Tutorials/Build%20Setup"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style={{
                                                        color: " #013EB7",
                                                        fontFamily: "InterSemiBold",
                                                    }}>
                                                    Build Setup
                                                </a>
                                            ) : (
                                                ""
                                            )}
                                        </span>
                                    </>
                                ) : (
                                    ""
                                )}
                            </Typography>
                            <Typography
                                sx={{
                                    color: "#000",
                                    fontSize: { xs: "11px", sm: "13px", md: "14px", lg: "14px" },
                                    lineHeight: 1.4,
                                    fontFamily: "InterRegular",
                                    fontWeight: 400,
                                    letterSpacing: "-0.04em",
                                    mb: 1,
                                    display: "flex",
                                    alignItems: "baseline",
                                    gap: "8px",
                                }}>
                                {step4 ? (
                                    <>
                                        <span
                                            style={{
                                                fontSize: {
                                                    xs: "12px",
                                                    sm: "14px",
                                                    md: "15px",
                                                    lg: "16px",
                                                },
                                            }}>
                                            4.
                                        </span>
                                        {step4}
                                    </>
                                ) : (
                                    ""
                                )}
                            </Typography>
                            <Typography
                                sx={{
                                    color: "#000",
                                    fontSize: { xs: "11px", sm: "13px", md: "14px", lg: "14px" },
                                    lineHeight: 1.4,
                                    fontFamily: "InterRegular",
                                    fontWeight: 400,
                                    letterSpacing: "-0.04em",
                                    mb: 1,
                                    display: "flex",
                                    alignItems: "baseline",
                                    gap: "8px",
                                }}>
                                {step5 ? (
                                    <>
                                        <span
                                            style={{
                                                fontSize: {
                                                    xs: "12px",
                                                    sm: "14px",
                                                    md: "15px",
                                                    lg: "16px",
                                                },
                                            }}>
                                            5.
                                        </span>
                                        {step5}
                                    </>
                                ) : (
                                    ""
                                )}
                            </Typography>
                        </Box>
                    ) : (
                        ""
                    )}
                </AccordionDetails>
            </Accordion>
        </Box>
    );

    return (
        <Box>
            {renderAccordion(
                "panel1",
                "What is CreataChain Launch Platform?",
                "CreataChain Launch Platform simplifies the process of launching custom blockchain networks. With a comprehensive suite of tools and expert guidance, users can easily create and deploy their own personalized blockchain networks.",
            )}
            {renderAccordion(
                "panel2",
                "How to create new node?",
                "To create new node, follow these steps:",
                "Please enter the name for your network e.g. ( My-Network).",
                "Choose your provider and associated algorithm.",
                "Provide your desired chain ID e.g. (net-11).",
                "If you have saved  mnemonics, Enter the name of your account and provide your mnemonics. The remaining fields, such as chain supply and initial stake amount, will be set to default values. If you want to change them, you can do so, but remember that the initial stake amount cannot exceed the chain supply, and the chain supply cannot be less than 100,000. If you don't have saved mnemonics, you can auto-generate an account. ",
                "Node is created and The configuration file will be automatically downloaded after creating the node.",
            )}
            {renderAccordion(
                "panel3",
                "How to manage a node?",
                "The maximum node limit is 5. You have the option to access the detailed information of each node by clicking on Overview. Furthermore, in the event that the configuration file has been inadvertently deleted, you can easily download it again from node details",
            )}
            {renderAccordion(
                "panel4",
                "How to configure and run a chain using downloaded configuration file?",
                "To start a blockchain node using node configuration files, follow these steps:",
                "Visit the node overview page and download the node configuration files. These files contain essential settings and parameters for running a CreataChain node, including network connection details, account information, and other relevant configurations.",
                "Set up the environment on your Linux machine. You can follow the tutorial provided at the following link for detailed instructions :",
                "Once the environment setup is complete, proceed to configure the CreataChain build within the environment. You can find step-by-step instructions in the tutorial available at the following link :",
                "Unzip the downloaded node configuration file and replace the existing .creata folder with the contents of the unzipped file.",
                "By following these steps, you'll be able to start a blockchain node using the node configuration files more efficiently.",
                "https://docs.creatachain.com/docs/Tutorials/Environment%20Setup",
            )}
            {/* {renderAccordion(
        "panel5",
        "How to run a node?",
        "Validator Functions You can view, edit and unjail your validator by the following methods.View Validator Description​ creatad query staking validator YOUR_BECH32_VALOPER_ADDRESSCopy Edit Validator Description​ Node operators can also edit the information of their validator. This information can help public to choose their validator on the network and stake their coins to them. When editing the information regarding the validator, input on each flag should be properly provided:"
      )} */}
        </Box>
    );
};
export default ControlledAccordions;
