import * as actionTypes from "./actionTypes";

const initialState = {
    networkName: "",
    networkMission: "",
    networkRegion: "",
    environmentName: "",
    deployOption: "",
    provider: "",
    algorithm: "",
    envValue: "",
    nameofNode: "",
    membership: "",
    nodeofRegion: "",
    sizeOfNode: "",
    informationOfNode: [],
    addNetwork: null,
    addNetworkLoading: false,
    getNode: null,
    getNodeLoading: false,
    isChainLoading: false,
    downloadFile: null,
    getNewMnemonics: "",
    isChain: "",
};

const nodeReducer = (state = initialState, action) => {
    switch (action.type) {
        //   NETWORK
        case actionTypes.GET_NETWORK_SUMMERY: {
            return {
                ...state,
                networkName: action.payload.networkName,
                networkMission: action.payload.networkStatement,
            };
        }
        case actionTypes.GET_MNEMONICS: {
            return {
                ...state,
                getNewMnemonics: action.payload,
            };
        }
        case actionTypes.GET_NETWORK_REGION: {
            return {
                ...state,
                networkRegion: action.payload,
            };
        }

        case actionTypes.CHAIN_LOADING: {
            return {
                ...state,
                isChainLoading: action.payload,
            };
        }

        // ENVIRONMENT
        case actionTypes.GET_ENVIRONMENT_SUMMERY: {
            return {
                ...state,
                environmentName: action.payload.name,
                deployOption: action.payload.option,
            };
        }
        case actionTypes.GET_ENVIRONMENT_CONFIG: {
            return {
                ...state,
                provider: action.payload.Provider,
                algorithm: action.payload.Algorithm,
                envValue: action.payload.value,
            };
        }
        // NODE
        case actionTypes.GET_NODE_SUMMERY: {
            return {
                ...state,
                nameofNode: action.payload.newName,
                membership: action.payload.membership,
                nodeofRegion: action.payload.selectRegion,
            };
        }
        case actionTypes.GET_NODE_SETTING: {
            return {
                ...state,
                sizeOfNode: action.payload,
            };
        }

        case actionTypes.SEND_NODE_INFORMATION: {
            return {
                ...state,
                informationOfNode: [...state.informationOfNode, action.payload],
            };
        }

        case actionTypes.SET_ADD_NETWORK_LOADING:
            return { ...state, addNetworkLoading: action.payload };
        case actionTypes.SET_ADD_NETWORK: {
            return {
                ...state,
                addNetwork: action.payload,
            };
        }

        case actionTypes.IS_CHAIN: {
            return {
                ...state,
                isChain: action.payload,
            };
        }

        case actionTypes.SET_NODE_LOADING:
            return { ...state, getNodeLoading: action.payload };
        case actionTypes.SET_NODE: {
            return {
                ...state,
                getNode: action.payload,
            };
        }

        case actionTypes.DOWNLOAD_FILE: {
            return {
                ...state,
                downloadFile: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

export default nodeReducer;
