import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Card from "../../components/card/Card";
import OverViewContent from "../nodeOverview/OverViewContent";
import CopyToClipboard from "react-copy-to-clipboard";
import HorizontalLine from "../../components/divider/HorizontalLine";
import { Copyicon, noFound } from "../../assets/images";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getActiveStep } from "../../redux/test/actions";
import { getChainById, getDownload } from "../../redux/actions";
import DownloadingIcon from "@mui/icons-material/Downloading";
// import CircularProgress from '@mui/material/CircularProgress';

const FirstFlex = { flex: "0 0 30%" };
const SecondFlex = {
  flex: "0 0 70%",
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
};
const FlexBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  width: "100%",
};

const TopBorderStyle = {
  width: "100%",
  background: " #013EB7",
  borderRadius: "5px",
  height: "3px",
};
const CopyBox = {
  height: "14px",
  width: "14px",
};
const MainTypoStyle = {
  ...SecondFlex,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
  minHeight: "18px",
};
const TypoStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
  maxWidth: "230px",
};
const AppOverviewBoxStyle = {
  flex: { xs: "0 0 100%", md: "0 0 49%" },
  width: "100%",
  overflow: "hidden",
  backgroundColor: " #EAF0F7",
  m: "2rem 0 2.4rem 0",
  height: "100%",
  borderRadius: "0px 0px 5px 5px ",
};
const button = {
  borderRadius: "5px",
  maxWidth: "160px",
  width: "100%",
  ml: ".5rem",
};
const buttonWrapper = {
  display: "flex",
  // width: { xs: "100%", md: "70%" },
  justifyContent: "flex-end",
};
const UserTupoStyle = {
  ...SecondFlex,
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  minHeight: "18px",
};

function NodeAndAppOverview() {
  const location = useLocation();
  const { id } = useParams();

  // Using the state to show the copy and copied funcationality with given below code
  const [isCopied, setIsCopied] = useState(false);
  const [state, setState] = useState("");
  const [loading, setLoading] = useState(false);
  const { addNetwork, addNetworkLoading, getnode, isChainLoading } =
    useSelector((state) => state.node);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleBack = () => {
    navigate("/manage-node");
    dispatch(getActiveStep(0));
  };
  const handleBackToMain = () => {
    navigate("/");
    dispatch(getActiveStep(0));
  };
  // const handleCopy = () => {
  //   setIsCopied(true);
  //   setTimeout(() => {
  //     setIsCopied(false);
  //   }, 4000);
  // };
  const handleDownload = (data, name) => {
    dispatch(getDownload(data, name));
  };
  // useEffect(() => {
  //   setState(location.pathname);
  //   if (addNetwork === null) {
  //     navigate("/manage-node");
  //   }
  // }, []);
  // console.log(state,"statestatestatestate");

  // getChainById
  useEffect(() => {
    dispatch(getChainById(id));
  }, [id]);
  return (
    <React.Fragment>
      {addNetwork === null ? (
        <Card>
          {isChainLoading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "68vh",
                flexDirection: "column",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "68vh",
                flexDirection: "column",
              }}
            >
              <img src={noFound} alt="noFound" />
              <Typography>no data found</Typography>
            </Box>
          )}
        </Card>
      ) : (
        <Card>
          {/* Title of the page */}
          <Typography variant="h1" my={1.5}>
            Node overview
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: { xs: "column", md: "column", lg: "row" },
            }}
          >
            {/* Node information portion */}
            <Box sx={{ flex: { xs: "0 0 100%", md: "0 0 49%" } }}>
              <OverViewContent />
            </Box>
            <Box sx={{ flex: { xs: "0 0 0 ", md: "0 0 2%" } }} />

            <Box sx={AppOverviewBoxStyle}>
              <Box sx={TopBorderStyle} />{" "}
              <Box
                sx={{
                  padding: {
                    xs: "2rem 1rem",
                    sm: "2rem 1rem",
                    md: "2rem 1.5rem",
                  },
                  width: "100%",
                }}
              >
                <Typography variant="h3">Node Configuration</Typography>
                <Typography variant="subtitle2" mt={2.2} lineHeight="22px">
                  Please note that the node configuration file contains
                  essential settings and parameters required for the proper
                  configuration and operation of the node within the blockchain
                  network. Make sure to keep the file secure and follow any
                  additional instructions or guidelines provided by the
                  blockchain network or platform you are working with.
                </Typography>
                <Typography variant="subtitle2" mt={1} mb={1} lineHeight="22px">
                  Follow these instructions to configure and run your chain
                  using a downloaded configuration file.{" "}
                  <Link
                    to={{
                      pathname: "/help-center",
                      search: "?panel4",
                    }}
                    style={{ color: " #013EB7", fontFamily: "InterSemiBold" }}
                  >
                    Help Center
                  </Link>
                </Typography>
                <Typography variant="subtitle2" mt={1} mb={5} lineHeight="22px">
                  To download the node configuration file, click on the
                  following link
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Box sx={FlexBoxStyle}>
                    <Typography variant="h4" sx={FirstFlex}>
                      Download File:
                    </Typography>
                    <Box
                      sx={UserTupoStyle}
                      data-cy="configurationFile"
                      onClick={() =>
                        handleDownload(
                          addNetwork?.dirPath,
                          addNetwork?.nodeDetails?.app_state?.genutil
                            ?.gen_txs[0]?.body?.messages[0]?.description
                            ?.moniker
                        )
                      }
                    >
                      <Typography
                        variant="subtitle2"
                        mr={1.2}
                        sx={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <DownloadingIcon /> &nbsp;{" "}
                        {!addNetworkLoading ? (
                          `${addNetwork?.nodeDetails?.app_state?.genutil?.gen_txs[0]?.body?.messages[0]?.description?.moniker} configuration file`
                        ) : (
                          <CircularProgress size="1rem" />
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <HorizontalLine />
                  {/* <Box sx={FlexBoxStyle}>
                  <Typography variant="h4" sx={FirstFlex}>
                    JSON/RPC Websocket endpoint
                  </Typography>
                  <Box sx={MainTypoStyle}>
                    <Typography variant="subtitle2" mr={1.2} sx={TypoStyle}>
                      0xa9faa38e5be71f8a9ac165af66ae304609616094
                    </Typography>
                    <CopyToClipboard
                      text=" 0xa9faa38e5be71f8a9ac165af66ae304609616094"
                      // onCopy={handleCopy}
                    >
                      <Tooltip title={isCopied ? "Copied" : "Copy"}>
                        <span style={{ cursor: "pointer" }}>
                          <Box sx={CopyBox}>
                            <img
                              src={Copyicon}
                              alt=""
                              style={{
                                height: "auto",
                                width: "100%",
                              }}
                            />
                          </Box>
                        </span>
                      </Tooltip>
                    </CopyToClipboard>
                  </Box>
                </Box>
                <HorizontalLine />
                <Box sx={FlexBoxStyle}>
                  <Typography variant="h4" sx={FirstFlex}>
                    REST API Gateway
                  </Typography>
                  <Box sx={MainTypoStyle}>
                    <Typography variant="subtitle2" mr={1.2} sx={TypoStyle}>
                      0xa9faa38e5be71f8a9ac165af66ae304609616094
                    </Typography>
                    <CopyToClipboard
                      text=" 0xa9faa38e5be71f8a9ac165af66ae304609616094"
                      // onCopy={handleCopy}
                    >
                      <Tooltip title={isCopied ? "Copied" : "Copy"}>
                        <span style={{ cursor: "pointer" }}>
                          <Box sx={CopyBox}>
                            <img
                              src={Copyicon}
                              alt=""
                              style={{
                                height: "auto",
                                width: "100%",
                              }}
                            />
                          </Box>
                        </span>
                      </Tooltip>
                    </CopyToClipboard>
                  </Box>
                </Box> */}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={buttonWrapper}>
            <Button
              variant="outlined"
              data-cy="NodeAndAppOverview-Back"
              sx={button}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button
              variant="contained"
              data-cy="NodeAndAppOverview-Done"
              sx={button}
              onClick={handleBackToMain}
            >
              Done
            </Button>
          </Box>
        </Card>
      )}
    </React.Fragment>
  );
}

export default NodeAndAppOverview;
