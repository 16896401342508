import { Box, SvgIcon, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import whitebg from "../../assets/images/whitebg.webp";
import { ReactComponent as HalfLeftIcon } from "../../assets/images/halfleft.svg";
import { ReactComponent as HalfDownIcon } from "../../assets/images/halfdown.svg";
import { ReactComponent as BlueCircleIcon } from "../../assets/images/bluecircle.svg";
import { ReactComponent as LightBlueCircleIcon } from "../../assets/images/lightbluecircle.svg";
const CircularStyle1 = {
    fontSize: "2.63rem",
    position: "absolute",
    right: "-.6rem",
    top: "87%",
};
const CircularStyle2 = {
    fontSize: "8.88rem",
    position: "absolute",
    top: "-3rem",
    left: 0,
    right: 0,
    margin: "0 auto",
};
const CircularStyle3 = {
    fontSize: "2rem",
    position: "absolute",
    right: ".5%",
    top: "3%",
};
const CircularStyle4 = {
    fontSize: "1.5rem",
    position: "absolute",
    left: "10%",
    top: "30%",
};
const CircularStyle5 = {
    fontSize: "1.125rem",
    position: "absolute",
    left: "23%",
    top: "11%",
};
const CircularStyle6 = {
    fontSize: "1.125rem",
    position: "absolute",
    right: "7%",
    top: "50%",
};
// Styling for the main typography
const MainTypoStyle = { p: "5rem 0 1rem 0", textAlign: "center" };
const NotFound = () => {
    return (
        <React.Fragment>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    backgroundImage: `url(${whitebg})`,
                    position: "relative",
                }}>
                <SvgIcon inheritViewBox component={HalfLeftIcon} sx={CircularStyle1} />
                <SvgIcon inheritViewBox component={HalfDownIcon} sx={CircularStyle2} />
                <SvgIcon inheritViewBox component={BlueCircleIcon} sx={CircularStyle3} />
                <SvgIcon inheritViewBox component={LightBlueCircleIcon} sx={CircularStyle4} />
                <SvgIcon inheritViewBox component={BlueCircleIcon} sx={CircularStyle5} />
                <SvgIcon inheritViewBox component={LightBlueCircleIcon} sx={CircularStyle6} />
                <Box
                    sx={{
                        zIndex: "100",
                        maxWidth: "32rem",
                        width: "100%",
                    }}>
                    <Typography
                        variant="h1"
                        color="#000"
                        sx={{
                            fontSize: {
                                xs: "3rem",
                                md: "4rem",
                                lg: "7rem",
                            },
                            textAlign: "center",
                            fontStyle: "italic",
                        }}>
                        404
                    </Typography>
                    <Typography variant="h1" color="#000" sx={MainTypoStyle}>
                        Oops! This Page Could Not Be Found
                    </Typography>
                    <Typography
                        variant="h3"
                        color="#000"
                        sx={{ textAlign: "center", pb: "1rem", lineHeight: "20px" }}>
                        we are sorry, but the page you requested was not found
                    </Typography>
                    {/* "Don't have an account?" text and sign-up link */}
                    <Link
                        to="/"
                        style={{
                            fontStyle: "italic",
                            color: "#013EB7",
                            whiteSpace: "nowrap",
                            fontFamily:"InterMedium"
                        }}
                     >
                        <Typography textAlign="center">Go Back</Typography>
                    </Link>
                </Box>
            </Box>
        </React.Fragment>
    );
};

export default NotFound;
