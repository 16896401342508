/* eslint-disable no-sequences */
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { PasswordResetValidation } from "../../utils/schema";
import { AuthBackground } from "../../components/auth";
import { BlueLeftArrow } from "../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { getForget } from "../../redux/actions";

const MainBox = {
  zIndex: "100",
  maxWidth: "32rem",
  width: "100%",
};
const BackBoxStyle = {
  display: { xs: "flex", md: "none" },
  position: "absolute",
  left: "1.5rem",
  top: "1.5rem ",
  cursor: "pointer",
};

const ForgetPassword = () => {
  const Navigation = useNavigate();
  const dispatch = useDispatch();
  const { forgetLoading } = useSelector((state) => state.auth);
  const initialValues = {
    email: "",
  };
  const onSubmit = (values) => {
    dispatch(getForget(values));
  };
  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema: PasswordResetValidation,
  });

  return (
    <React.Fragment>
      <AuthBackground newHeight="100vh">
        <Box sx={MainBox}>
          <Box onClick={() => Navigation("/sign-in")} sx={BackBoxStyle} data-cy="forgetPassword-Left">
            {" "}
            <img src={BlueLeftArrow} alt=" Left Arrow" />
            <Typography
              variant="subtitle4"
              sx={{ fontSize: "20px", marginLeft: ".5rem", color: "#013EB7" }}
            >
              Back
            </Typography>
          </Box>
          <Typography
            variant="h1"
            color="#000"
            sx={{ p: "5rem 0 3rem 0", textAlign: "center" }}
          >
            Let's reset your password
          </Typography>
          <form
            onSubmit={formik.handleSubmit}
            style={{
              padding: "0 2rem",
            }}
          >
            {/* Email input field */}
            <Box sx={{ width: "100%", height: "82px", mt: 1 }}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                type="email"
                data-cy="input_email2"
                name="email"
                {...formik.getFieldProps("email")}
                error={Boolean(formik.touched.email && formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                sx={{ mt: 3 }}
              />
            </Box>
            {/* Disbale the button on basis of loading state and adding spinner */}
            <Button
              type="submit"
              disabled={forgetLoading ? true : false}
              sx={{ borderRadius: "5px", width: "100%", mt: 5.75 }}
              variant="contained"
              data-cy="Submit"
            >
              {!forgetLoading ? "Submit" : <CircularProgress size="1.7rem" />}
            </Button>
          </form>
          {/* "Already have your password?" text and sign-in link */}
          <Typography
            variant="subtitle1"
            sx={{ padding: "2rem", textAlign: "center" }}
          >
            Already have your password? {/* Show the underline code on hover */}
            <Link
              to="/sign-in"
              onMouseOver={(event) => (
                (event.target.style.color = "#032d82"),
                (event.target.style.textDecoration = "underline")
              )}
              onMouseOut={(event) => (
                (event.target.style.color = "#013EB7"),
                (event.target.style.textDecoration = "none")
              )}
              style={{
                fontStyle: "italic",
                color: "#013EB7",
                whiteSpace: "nowrap",
              }}
            >
              Sign in
            </Link>
          </Typography>{" "}
        </Box>
      </AuthBackground>
    </React.Fragment>
  );
};

export default ForgetPassword;
