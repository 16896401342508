import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Radio,
  Typography,
} from "@mui/material";
import SteppersUI from "../../../../components/newStepper/NewStepper";
import CheckIcon from "../../../../assets/images/checked.svg";
import UnCheckIcon from "../../../../assets/images/unchecked.svg";
import { useNavigate } from "react-router-dom";
import { palette } from "../../../../theme/palette";
import { getActiveStep, getButtonActive } from "../../../../redux/test/actions";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  getAddNetwork,
  getNodeSetting,
  sendNodeInfomation,
} from "../../../../redux/node/actions";

const verticalLine = {
  background:
    "radial-gradient(50% 50% at 50% 50%, rgba(1, 62, 183, 0.31) 0%, rgba(255, 255, 255, 0) 100%)",
  height: "400px",
  width: 2,
  display: { xs: "none", sm: "none", md: "block" },
};
const buttonBox = {
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  mt: 4,
};
const textWrapper = {
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
};
const button = {
  borderRadius: "5px",
  maxWidth: "7.2rem",
  width: "100%",
  ml: ".5rem",
};
// Data
const data = [
  {
    name: "Small",
    country: "(1GB memory, 0.5 vCPU)",
  },
  {
    name: "Medium",
    country: "(2GB memory, 0.5 vCPU)",
  },
  {
    name: "Large",
    country: "(4GB memory, 0.5 vCPU)",
  },
];
const NodeSetting = ({ setScreen }) => {
  const [loading, setLoading] = useState(false);
  const {
    networkName,
    networkMission,
    networkRegion,
    environmentName,
    deployOption,
    provider,
    algorithm,
    envValue,
    nameofNode,
    membership,
    nodeofRegion,
    addNetworkLoading,
  } = useSelector((state) => state.node);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notify = () =>
    toast.success("Node is created.", {
      style: {
        padding: "14px",
        color: palette.primary.main,
        animation: "none",
        transition: "none",
      },
      iconTheme: {
        primary: palette.primary.main,
        secondary: "#FFFAEE",
      },
    });
  // Go back to the previous screen
  const handleBack = () => {
    setScreen(1);
  };
  // Handle checkbox change
  const [checkedIndex, setCheckedIndex] = React.useState(0);

  const handleChange = (event, index) => {
    const value = event.currentTarget.getAttribute("data-value");
    const number = parseInt(value);
    setCheckedIndex(number);
  };
  let Size = data[checkedIndex].name;
  const dataObj = {
    networkName,
    networkMission,
    networkRegion,
    environmentName,
    deployOption,
    provider,
    algorithm,
    envValue,
    nameofNode,
    membership,
    nodeofRegion,
    sizeOfNode: Size,
  };

  // "validator": "localvalidator",
  //   "chainId": "local-testnet",
  //   "accountName" : "accountname"

  const apiData = {
    validator: networkName,
    chainId: envValue?.idvalue,
    accountName: envValue?.inputFields[0]?.address,
  };
  // function downloadFile(url) {
  //   const downloadLink = document.createElement("a");
  //   downloadLink.setAttribute("download", "file.zip");
  //   downloadLink.setAttribute("href", url);
  //   downloadLink.click();
  // }

  // Handle next button click
  const handleNext = () => {
    // setLoading(true);
    dispatch(sendNodeInfomation(dataObj));
    dispatch(getNodeSetting(Size));
    dispatch(getActiveStep(2));
    dispatch(getButtonActive(true));

    // notify();
    // setLoading(false);
    dispatch(getAddNetwork(apiData, navigate));
  };

  const dataBox = (i) => {
    return {
      background: checkedIndex === i ? "#fff" : "rgba(0, 0, 0, 0.03)",
      borderRadius: "5px",
      padding: "10px 10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
      border: checkedIndex === i ? "1px solid #013EB7" : "",
      mb: 1,
    };
  };
  return (
    <React.Fragment>
      <Box>
        <Box
          sx={{
            mt: "2.5rem",
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {/* Stepper component */}
          <Box sx={{ maxWidth: "600px ", width: "100%" }}>
            <SteppersUI />
          </Box>
        </Box>
        <Typography
          variant="h1"
          mb={3}
          sx={{ m: { xs: "1rem 0", sm: " 0 0rem 1rem" } }}
        >
          Node Setting
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "column", md: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "100%", md: "48%" } }}>
            <Typography variant="subtitle1" textAlign="left">
              Whether you're a pioneer helping make the initial technology
              choice, or part of an established business network ready to
              accelerate the next phase of business value - at Kaleido we have
              what you need:
            </Typography>
            <ul>
              <li>
                <Typography variant="subtitle1" textAlign="left">
                  PoC: Environments ready to run in minutes
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" textAlign="left">
                  Pilot: Secure decentralized transactions at scale
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" textAlign="left">
                  Production: Enterprise grade infra, key management, hybrid
                  networking, ISO Certification, and SLAs
                </Typography>
              </li>
            </ul>
          </Box>
          <Box sx={verticalLine} />
          <Box
            sx={{
              width: { xs: "100%", sm: "100%", md: "48%" },
              mt: { xs: 3, md: 0 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: "1rem",
              }}
            >
              <Typography variant="h3"> Configure Your Node</Typography>
              {/* <Typography variant="subtitle2" sx={{ fontStyle: "italic" }}>
                Step 3/3
              </Typography> */}
            </Box>
            <Typography variant="subtitle2" mb={3}>
              Configure the size of your node and whether or not it will be a
              signer.
            </Typography>
            {/* Maping the checkbuttons to select the specific  configration */}
            {data.map((option, i) => (
              <Box
                key={option.name}
                onClick={(e) => handleChange(e, i)}
                data-cy="nodeSetting"
                data-value={`${i}`}
                sx={dataBox(i)}
              >
                <Box sx={textWrapper}>
                  <Typography variant="h4">{option.name}</Typography>
                  <Typography
                    variant="subtitle2"
                    color={palette.primary.main}
                    mt={0.5}
                  >
                    {option.country}
                  </Typography>
                </Box>
                <Radio
                  icon={<img src={UnCheckIcon} alt="unchecked" />}
                  checkedIcon={<img src={CheckIcon} alt="checked" />}
                  checked={checkedIndex === i}
                  value={i}
                />
              </Box>
            ))}

            <Box sx={buttonBox}>
              {/* Back button */}
              <Button variant="text" data-cy="NodeSetting-Back" sx={button} onClick={handleBack}>
                Back
              </Button>
              {/* Next button */}
              <Button
                // This button will move to the next page
                onClick={handleNext}
                disabled={addNetworkLoading ? true : false}
                variant="contained"
                data-cy="NodeSetting-Finish"
                sx={button}
              >
                {!addNetworkLoading ? (
                  "Finish"
                ) : (
                  <CircularProgress size="1.7rem" />
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default NodeSetting;

// fetch('https://cors-anywhere.herokuapp.com/' + fileURL, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/pdf',
//     },
//   })
//   .then((response) => response.blob())
//   .then((blob) => {
//     // Create blob link to download
//     const url = window.URL.createObjectURL(
//       new Blob([blob]),
//     );
//     const link = document.createElement('a');
//     link.href = url;
//     link.setAttribute(
//       'download',
//       `FileName.pdf`,
//     );

//     // Append to html link element page
//     document.body.appendChild(link);

//     // Start download
//     link.click();

//     // Clean up and remove the link
//     link.parentNode.removeChild(link);
//   });
