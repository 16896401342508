import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
// import { logOutIcon, notifyIcon } from "../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { getDetail } from "../../redux/actions";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { drawerOpen, humburger, CreataLogo } from "../../assets/images";
import ScreenDialog from "./drawer";

const logoBox1 = {
  display: "flex",
  alignItems: "center",
};
const Header = () => {
  const Navigator = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [isOpen, setIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { detail } = useSelector((state) => state.auth);
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      dispatch(getDetail(token, Navigator));
    }
  }, [dispatch, token]);
  const capitalizeFirstLetter = (str) => {
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const handleDrawerOpen = () => {
    setIsOpen(true);
  };
  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  const wrapper = {
    display: "flex",
    justifyContent: {
      xs: "space-between",
      sm: "flex-end",
    },
    alignItems: { xs: "space-between", sm: "flex-end" },

    width: "100%",
    mt: 2,
    flexDirection: "column",
    // paddingRight: "20px",
  };
  return (
    <React.Fragment>
      <Box sx={{ ...wrapper, background: "transparent" }}>
        {/* Logo icon code */}

        {/* Notification and other buttons in code */}
        <Box
          sx={{
            flex: "0 0 50%",
            padding: "0 0 1rem 0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <IconButton onClick={handleDrawerOpen} data-cy="header">
              <img src={drawerOpen} alt="humburger" style={{ height: 12 }} />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <Link to="#">
                            <IconButton>
                                <img src={notifyIcon} alt="logout icon" />
                            </IconButton>
                        </Link>
                        <Link to="/sign-in" style={{ marginLeft: "1rem" }}>
                            <IconButton>
                                <img src={logOutIcon} alt="logout icon" />
                            </IconButton>
                        </Link> */}
            {/* <Box
                            sx={{
                                marginLeft: "2.7rem",
                                display: { xs: "block", sm: "none" },
                            }}
                            onClick={toggleDrawer}>
                            <img src={humburger} alt="logout icon" />
                        </Box> */}
            <Box
              sx={{
                background:
                  "linear-gradient(0deg, rgba(1, 62, 183, 0.14), rgba(1, 62, 183, 0.14))",
                borderRadius: "4px",
                border: "1px solid #013EB724",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "#000", p: "4px 10px" }}>
                Mainnet
              </Typography>
            </Box>
            <IconButton
              onClick={handleClick}
              data-cy="header-small"
              size="small"
              sx={{
                ml: 2,
              }}
              aria-controls={anchorEl ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={anchorEl ? "true" : undefined}
            >
              {getDetail.image ? (
                <Avatar sx={{ width: 40, height: 40 }}>
                  <img
                    src={getDetail.image}
                    alt="profile"
                    width="100%"
                    height="100%"
                  />
                </Avatar>
              ) : (
                <>
                  <Avatar sx={{ width: 32, height: 32, background: "#0575E6" }}>
                    {capitalizeFirstLetter(detail?.name?.charAt(0)) || "A"}
                  </Avatar>
                </>
              )}
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              data-cy="account-menu"
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {/* <Box
                sx={{
                  my: 1.5,
                  px: 2.5,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width:200
                }}
              >
                <Typography
                  variant="subtitle2"
                  color="#000000"
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: "95%",
                    fontFamily: 'InterMedium',
                    fontWeight: 500,
                    fontStyle: 'normal',
                    lineHeight: '26px',
                    letterSpacing: 'normal',
                    fontSize:"1rem !important"
                 
                  }}
                >
                  {detail?.name}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="rgba(0, 0, 0, 0.5)"
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: "95%",
                  }}
                >
                  {detail?.email}
                </Typography>
              </Box> */}

              {/* <MenuItem sx={{ m: 1 }} onClick={() => Navigator("/help-center")}>
                                Help center
                            </MenuItem> */}
              <Divider sx={{ borderStyle: "dashed" }} />
              <MenuItem onClick={() => Navigator("/settings")} sx={{ m: 1 }}>
                Settings
              </MenuItem>
              <Divider sx={{ borderStyle: "dashed" }} />
              <MenuItem
                sx={{ m: 1 }}
                onClick={() => {
                  localStorage.removeItem("token");
                  // Navigator("/sign-in");
                  Navigator("/sign-in", { replace: true });
                }}
                data-cy="Logout-menu"
              >
                Logout
              </MenuItem>
            </Menu>
            {/* <Box
                            sx={{
                                marginLeft: "2.7rem",
                                display: { xs: "none", sm: "block" },
                            }}></Box> */}
          </Box>
        </Box>
      </Box>
      <ScreenDialog open={isOpen} toggle={handleDrawerClose} />
      {/* This component is used for drawer (Mobile View) */}
      {/* <ScreenDialog open={state.open} toggleDrawer={toggleDrawer} /> */}
    </React.Fragment>
  );
};

export default Header;
