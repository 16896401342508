import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import {
  CheckIcon,
  Copyicon,
  ExpandMoreIcon,
  PlusICon,
  UnCheckIcon,
  Visibility,
  VisibilityOff,
  tick2,
} from "../../../../assets/images";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { color } from "@mui/system";
import { paste } from "@testing-library/user-event/dist/paste";
const bip39 = require("bip39");
const AccordianStyle = {
  // bgcolor: "#EAF0F7",
  border: "none",
  // padding: { xs: "1rem 0", sm: "0" },
  borderRadius: "5px",
};
const MapBoxStyle = {
  //   bgcolor: "#FFFFFF",
  //   padding: "1rem 1rem .1rem",
  //   borderRadius: "12px",
  mt: "1rem",
  minHeight: "480px",
};

const closeButton = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  height: 35,
  width: 35,
  mt: 1,
  cursor: "pointer",

  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04);",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    height: 35,
    width: 35,
  },
};
const BtnBoxStyle = {
  display: "flex",
  justifyContent: "flex-end",
  mt: "1.5rem",
};
const CopyBox = {
  height: "14px",
  width: "14px",
};

const Account = (props) => {
  const {
    formik,
    handleRemoveField,
    handleInputChange,
    // handleAddField,
    inputValue,
    // handleGenerateField,
    hasError,
    hasAddError,
    envValue,
    setScreen,
    selectedValue,
  } = props;
  const [isCopied, setIsCopied] = useState(false);
  const [pasted, setPasted] = React.useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleRadioChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handlePaste = () => {
    setPasted(true);
  };

  const handleChangePaste = (event) => {
    const { name, value } = event.target;
    if (pasted) {
      const formattedValue = value.match(/[a-zA-Z]+/g);
      const data = formattedValue?.toString();
      const formattedValue1 = data?.replace(/,/g, " ");
      formik.setFieldValue(name, formattedValue1);
      setPasted(false);
    } else {
      setPasted(false);
      formik.setFieldValue(name, value);
    }
  };

  useEffect(() => {
    if (!formik?.values?.inputFields?.[0]?.mnemonics) {
      setPasted(false);
    }
  }, [formik?.values?.inputFields?.[0]?.mnemonics]);

  const handleBackScreen = () => {
    setScreen(1);
  };
  const handleNextScreen = () => {
    if (selectedValue === "addAccount") {
      setScreen(4);
    } else {
      setScreen(3);
    }
  };
  const data = formik?.values?.inputFields?.[0]?.mnemonics;

  // const modifiedData = data;
  const modifiedData = data
    ?.split(" ")
    .map((word, index) => `${index + 1}:${word}`)
    .join(" ");

  //   const modifiedData = data?.replace(/@[\w.:]+$/, "");

  //  Show the copy and copied funcitionality
  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 4000);
  };

  return (
    <Box>
      <Box mt={-2.5}>
        {/* Loop through inputValue array */}
        {/* {inputValue?.map((field, 0) => ( */}
        <Box sx={MapBoxStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h1"
              sx={{ m: { xs: "1rem 0", sm: " .7rem 0rem 1.8rem" } }}
            >
              {/* {`Account ${0 + 1}`} */}
              {selectedValue === "addAccount"
                ? "Enter your Account Details"
                : "Generated Account Details"}
            </Typography>
            {/* remove icon */}
            {/* <Box
                onClick={() => handleRemoveField(0)}
                data-cy="Accordian"
                sx={closeButton}
              >
                <CloseIcon sx={{ color: "black" }} />
              </Box> */}
          </Box>
          {/* Address text field code */}
          <Box
            sx={{
              width: "100%",
              height: "82px",
              mt: 4.2,
            }}
          >
            <TextField
              value={formik?.values?.inputFields?.[0]?.accountName || ""}
              defaultValue={formik?.values?.inputFields?.[0]?.accountName || ""}
              label="Account Name"
              variant="outlined"
              fullWidth
              id="outlined-basic"
              name={`inputFields[${0}].accountName`}
              type="text"
              data-cy=" acoordian-input-accountName"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              InputLabelProps={{
                shrink: formik?.values?.inputFields?.[0]?.accountName
                  ? true
                  : false,
              }}
              error={Boolean(
                formik.touched?.inputFields?.[0]?.accountName &&
                  formik.errors?.inputFields?.[0]?.accountName
              )}
              helperText={
                formik.touched?.inputFields?.[0]?.accountName &&
                formik.errors?.inputFields?.[0]?.accountName
              }
              {...formik.getFieldProps(`inputFields[${0}].accountName`)}
              InputProps={{
                onChange: (event) => {
                  const { name, value } = event.target;
                  const formattedValue = value.replace(/\s/g, "");
                  formik.setFieldValue(name, formattedValue);
                },
              }}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              minHeight: "100px",
            }}
          >
            <TextField
              value={formik?.values?.inputFields?.[0]?.mnemonics || ""}
              defaultValue={formik?.values?.inputFields?.[0]?.mnemonics || ""}
              label="Mnemonics"
              variant="outlined"
              multiline
              fullWidth
              id="outlined-basic"
              name={`inputFields[${0}].mnemonics`}
              type="text"
              data-cy=" acoordian-input-mnemonics"
              // onChange={(event) => handleInputChange(0, event)}
              onPaste={handlePaste}
              InputProps={{
                onChange: (event) => handleChangePaste(event),
                endAdornment: (
                  <InputAdornment position="start">
                    {selectedValue !== "addAccount" && (
                      <CopyToClipboard
                        text={modifiedData}
                        onCopy={handleCopy}
                        disabled
                      >
                        {/* It will show the copy tooltop on hover and Copied on click */}
                        {/* <Tooltip title={isCopied ? "Copied" : "Copy"}> */}
                        <span style={{ cursor: "pointer" }}>
                          {/* <IconButton aria-label="delete"> */}
                          <Box>
                            {/* <img
                                  src={isCopied ?tick2:Copyicon}
                                  alt=""
                                  // style={{ height: "auto", width: "100%",marginBottom:"7px" }}
                                /> */}
                            {isCopied ? (
                              <DoneIcon
                                style={{
                                  color: "green",
                                  fontSize: "20px",
                                }}
                              />
                            ) : (
                              <ContentCopyIcon
                                style={{
                                  color: "#000",
                                  fontSize: "16px",
                                }}
                              />
                            )}
                          </Box>
                          {/* </IconButton> */}
                        </span>
                        {/* </Tooltip> */}
                      </CopyToClipboard>
                    )}
                  </InputAdornment>
                ),
              }}
              {...formik.getFieldProps(`inputFields[${0}].mnemonics`)}
              error={
                formik?.values?.inputFields?.[0]?.mnemonics
                  ? !hasError
                  : formik?.touched?.inputFields?.[0]?.mnemonics &&
                    formik?.errors?.inputFields?.[0]?.mnemonics
                  ? true
                  : null
              }
              helperText={
                formik?.values?.inputFields?.[0]?.mnemonics
                  ? !hasError
                    ? "Invalid Mnemonic"
                    : ""
                  : null
              }
              InputLabelProps={{
                shrink: formik?.values?.inputFields?.[0]?.mnemonics
                  ? true
                  : false,
              }}
            />
            <Box>
              {formik?.touched?.inputFields?.[0]?.mnemonics &&
              formik?.errors?.inputFields?.[0]?.mnemonics ? (
                <Typography
                  sx={{
                    color: "#e53935",
                    fontSize: "0.75rem",
                    ml: "14px",
                  }}
                >
                  {" "}
                  Mnemonics required
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "82px",
              mt: 1,
            }}
          >
            <TextField
              value={formik?.values?.inputFields?.[0]?.supplyChain || ""}
              defaultValue={formik?.values?.inputFields?.[0]?.supplyChain || ""}
              label="Chain Supply"
              variant="outlined"
              fullWidth
              id="outlined-basic"
              name={`inputFields[${0}].supplyChain`}
              type="number"
              data-cy=" acoordian-input-number"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => handleInputChange(0, event)}
              {...formik?.getFieldProps(`inputFields[${0}].supplyChain`)}
              error={Boolean(
                formik?.touched?.inputFields?.[0]?.supplyChain &&
                  formik?.errors?.inputFields?.[0]?.supplyChain
              )}
              helperText={
                formik.touched?.inputFields?.[0]?.supplyChain &&
                formik.errors?.inputFields?.[0]?.supplyChain
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Typography>CTA</Typography>{" "}
                  </InputAdornment>
                ),
                // onChange: (event) => {
                //   const { name, value } = event.target;
                //   const formattedValue = Math.max(1000000, Number(value));

                //   formik.setFieldValue(name, formattedValue);
                // },
              }}
              // error={
              //   formik.touched.inputFields &&
              //   formik.touched.inputFields[0] &&
              //   formik.touched.inputFields[0].balance &&
              //   Boolean(
              //     formik.errors.inputFields &&
              //       formik.errors.inputFields[0] &&
              //       formik.errors.inputFields[0].balance
              //   )
              // }
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "82px",
              mt: 1,
            }}
          >
            <TextField
              label="Initial Stake Amount"
              variant="outlined"
              value={formik?.values?.inputFields?.[0]?.amount || ""}
              defaultValue={formik?.values?.inputFields?.[0]?.amount || ""}
              fullWidth
              id="outlined-basic"
              name="amount"
              type="number"
              data-cy=" acoordian-input-Stake"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => handleInputChange(0, event)}
              {...formik?.getFieldProps(`inputFields[${0}].amount`)}
              error={Boolean(
                formik?.touched?.inputFields?.[0]?.amount &&
                  formik?.errors?.inputFields?.[0]?.amount
              )}
              helperText={
                formik.touched?.inputFields?.[0]?.amount &&
                formik.errors?.inputFields?.[0]?.amount
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Typography>CTA</Typography>{" "}
                  </InputAdornment>
                ),
                // onChange: (event) => {
                //   const { name, value } = event.target;
                //   const formattedValue = Math.max(100000, Number(value));

                //   formik.setFieldValue(name, formattedValue);
                // },
                // onChange: (event) => {
                //   const { name, value } = event.target;
                //   const minValue = 100000; // Minimum value constraint
                //   const maxValue =
                //     formik?.values?.inputFields?.[0]?.supplyChain - 1; // Maximum value constraint

                //   let formattedValue = Number(value);
                //   if (formattedValue < minValue) {
                //     formattedValue = minValue;
                //   } else if (formattedValue > maxValue) {
                //     formattedValue = maxValue;
                //   }

                //   formik.setFieldValue(name, formattedValue);
                // },
              }}
            />
          </Box>
          {selectedValue !== "addAccount" && (
            <Box>
              <FormControlLabel
                label="I have copied mnemonics."
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleRadioChange}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{
                      color: "#013eb7",
                      // "&.Mui-checked": {
                      //   color: "red",
                      // },
                    }}
                  />
                }
              />
            </Box>
          )}
          <Box sx={BtnBoxStyle}>
            <Button
              // onClick={handleBack}
              onClick={handleBackScreen}
              data-cy="Back4"
              sx={{
                borderRadius: "5px",
                maxWidth: "7.2rem",
                width: "100%",
              }}
              variant="text"
            >
              Back
            </Button>
            <Button
              onClick={handleNextScreen}
              data-cy="Finish"
              disabled={
                //   !formik?.values?.inputFields[0]?.mnemonics &&
                //   !formik?.values?.inputFields[0]?.accountName
                //     ? !hasError
                //     : null
                //     ? true
                //     : false

                !formik?.values?.inputFields[0]?.accountName ||
                !formik?.values?.inputFields[0]?.mnemonics ||
                !hasError ||
                formik?.errors?.inputFields?.[0]?.supplyChain ||
                formik?.errors?.inputFields?.[0]?.amount ||
                (selectedValue !== "addAccount" && !isChecked)
                  ? true
                  : false
              }
              sx={{
                borderRadius: "5px",
                maxWidth: "7.2rem",
                width: "100%",
                ml: ".5rem",
              }}
              variant="contained"
            >
              Next
            </Button>
          </Box>
        </Box>
        {/* ))} */}
        {/* Add new account button */}
      </Box>
    </Box>
  );
};

export default Account;
